export const businessModelData = [
    {
        "unique_id": 1,
        "sector": "Agriculture",
        "service": "Precision Agriculture",
        "user_type": "End-User",
        "label1_title": "Key Partners",
        "label1_content": "Internet service providers ISP\nTechnology and Data providers \nApplication providers (data analytics)\nFinancial providers (e.g. banks)\nGovernment and Regulatory bodies",
        "label2_title": "Key Activities",
        "label2_content": "Real-time crop monitoring\nReal-time pest detection\nAutomated area surveillance\nAutonomous Driving (Agricultural sector)",
        "label3_title": "Value Propositions",
        "label3_content": "Extend the service offerings of local agriculture and SMEs in the agriculture sector\nOptimize on-situ operations in the agricultural sector.\nReduce costs for agricultural practices (e.g. fertilisers).\nProtect biodiversity and the environment through optimised agricultural practices\nUpgrade network quality and speed for a positive impact in the agriculture sector\nReduce costs for agricultural practices",
        "label4_title": "Customer Relationships",
        "label4_content": "Personal assistance\nCo-creation (tailor made)",
        "label5_title": "Customer Segments",
        "label5_content": "Drone operators/users\nLocal operators\nPublic authorities\nAgricultural sector\nSMEs : Agriculture",
        "label6_title": "Channels",
        "label6_content": "Social media channels\nWebsite",
        "label7_title": "Key Resources",
        "label7_content": "Human resources (e.g. technicians,engineers, IT professionals)\nPhysical resources (e.g. drones, devices, cameras)",
        "label9_title": "Cost Structure",
        "label9_content": "Fixed costs for Infrastructure (Owned Drones) and maintenance\nOperational costs",
        "label10_title": "Revenue Streams",
        "label10_content": "Increased profitability via increased production\nIncreased profitability via cost reductions\nSelling Agricultural products (B2B/B2C)"
    },
    {
        "unique_id": 2,
        "sector": "Agriculture",
        "service": "Precision Agriculture",
        "user_type": "Internet Service Provider (ISP)",
        "label1_title": "Key Partners",
        "label1_content": "MNOs\nTechnology and Data providers \nNGOs\nAcademic Institutions\nSystem Integrators\nFinancial providers (e.g. banks)\nGovernment and Regulatory bodies\nDrone Manufacturers\nApplication developers (data analytics)",
        "label2_title": "Key Activities",
        "label2_content": "Local Manufacturing & Sourcing\nAlliances & Partnerships formation\nSales & Promotion",
        "label3_title": "Value Propositions",
        "label3_content": "Low cost, Low power & Low maintenance (3L)\nHigh QOS & Coverage\nEnvironment-friendly green-solution\nEnd-to-end IP platform\nCloud server for locllized_content\nHigh Data Rates",
        "label4_title": "Customer Relationships",
        "label4_content": "24/7 Customer Care Centre\nMicro Operators/VLEs\nRegional Sales Executives",
        "label5_title": "Customer Segments",
        "label5_content": "Local community\nSMEs\nPublic authorities\nLocal Retail Market",
        "label6_title": "Channels",
        "label6_content": "Micro Operators/ VLEs\nCo-branded outlets\nPartnership Telcos/ISPs\nDirect Sales\nWebsite\nSocial media channels",
        "label7_title": "Key Resources",
        "label7_content": "Physical resources (technologies, machineries)\nFinancial resources (Investors and Venture Funding)\nPassionate Management",
        "label9_title": "Cost Structure",
        "label9_content": "Manufacturing & Sourcing\nWi-Fi hotspot integration, funding, deployment, operation & maintenance\nEmployees & office\nR&D & Training\nSales & Marketing",
        "label10_title": "Revenue Streams",
        "label10_content": "Subscription fee\nContent/ Application services revenue share\nGovt. Anchor usage payment receipts\nMobile Data offload revenues\nNetwork slice rent (technology, software, mobile and broadband network providers)"
    },
    {
        "unique_id": 3,
        "sector": "Agriculture",
        "service": "Precision Agriculture",
        "user_type": "Public Authority",
        "label1_title": "Partnerships/Suppliers/Collaborators",
        "label1_content": "State, Municipality, Federal Government, Ministries, European Union, Universities, Development Agencies, Private sector (e.g. MNOs, ISPs), Financial Institutions.",
        "label2_title": "Other Stakeholders",
        "label2_content": "Society, NGOs, Chamber of Tradesmen, Agricultural Organisations, and Civil Aviation.",
        "label3_title": "Resources",
        "label3_content": "Financial\nHuman\nMaterial\nEquipment\nIT\nLegislation",
        "label4_title": "Capabilities",
        "label4_content": "Systemic, strategic and entrepreneurial vision\nMultidisciplinary knowledge\nAnalytical and forecast capacity\nAbility to think, develop studies and plans",
        "label5_title": "Proccesses",
        "label5_content": "Strategic Process Management\nElaboration of development plans\nLocal and regional potential mapping\nIdentification of economic potential\nManagement of environmental impacts\nElaboration of policies,plans and programs\nAgreements Management\nPartnerships management and cooperation agreements",
        "label6_title": "Products",
        "label6_content": "Laws and regulatory frameworks for: Internet connectivity\nLaws and regulatory frameworks for: Drones operations (state, regional and sectoral levels)",
        "label7_title": "Customers",
        "label7_content": "Society\nMunicipalities\nCompanies from all the industries (especially agriculture)\nUniversities\nThird sector",
        "label9_title": "Outcomes",
        "label9_content": "Universal and democratic access to digital connectivity\nIncrease of per capita income\nIncrease of the State PID\nJob creation\nHigher HDI\nEconomic and societal strengthening of municipalities and strategic regions of the state\nSustainable Agriculture\nEnhance citizens� digital literacy\nReduction of social inequality\nReduction of desertification",
        "label10_title": "Public Value",
        "label10_content": "Provide universal access to High-Speed Internet services by means of trustable and credible service, generating a fair and prosperous state that guarantees the well-being of the population as well as the financial sustainability of SMEs."
    },
    {
        "unique_id": 4,
        "sector": "Agriculture",
        "service": "Drones Operation",
        "user_type": "End-User",
        "label1_title": "Key Partners",
        "label1_content": "Internet service providers ISP\nTechnology and Data providers \nApplication providers (data analytics)\nFinancial providers (e.g. banks)\nGovernment and Regulatory bodies",
        "label2_title": "Key Activities",
        "label2_content": "Develop, test & validate drone based solutions in the agricultural sector having all the necessary radio & edge technologies\nReal-time pest detection\nAutomated area surveillance\nAutonomous Driving (Agricultural sector)\nReal-time crop monitoring",
        "label3_title": "Value Propositions",
        "label3_content": "Extend the service offerings of local agriculture and SMEs in the agriculture sector\nOptimize on-situ operations in the agricultural sector.\nReduce costs for agricultural practices (e.g. fertilisers).\nProtect biodiversity and the environment through optimised agricultural practices\nUpgrade network quality and speed for a positive impact in the agriculture sector\nReduce costs for agricultural practices\nFamiliarize drone users with technologies or test their solutions in a sandbox environment",
        "label4_title": "Customer Relationships",
        "label4_content": "Personal assistance\nCo-creation (tailor made)",
        "label5_title": "Customer Segments",
        "label5_content": "Drone operators/users\nLocal operators\nPublic authorities\nAgricultural sector\nSMEs : Agriculture",
        "label6_title": "Channels",
        "label6_content": "Social media channels\nWebsite",
        "label7_title": "Key Resources",
        "label7_content": "Human resources (e.g. technicians,engineers, IT professionals)\nPhysical resources (e.g. drones, devices, cameras)",
        "label9_title": "Cost Structure",
        "label9_content": "Fixed costs for Infrastructure (Owned Drones) and maintenance\nOperational costs",
        "label10_title": "Revenue Streams",
        "label10_content": "Increased profitability via increased production\nIncreased profitability via cost reductions\nSelling Agricultural products (B2B/B2C)\nMonetise the use of drones and drone center for testing/evaluating services & solutions.  (Pay per hour/montly/per lesson)\nDrone rental"
    },
    {
        "unique_id": 5,
        "sector": "Agriculture",
        "service": "Drones Operation",
        "user_type": "Internet Service Provider (ISP)",
        "label1_title": "Key Partners",
        "label1_content": "MNOs\nTechnology and Data providers \nNGOs\nAcademic Institutions\nSystem Integrators\nFinancial providers (e.g. banks)\nGovernment and Regulatory bodies\nDrone Manufacturers\nApplication developers (data analytics)",
        "label2_title": "Key Activities",
        "label2_content": "Local Manufacturing & Sourcing\nAlliances & Partnerships formation\nSales & Promotion",
        "label3_title": "Value Propositions",
        "label3_content": "Low cost, Low power & Low maintenance (3L)\nHigh QOS & Coverage\nEnvironment-friendly green-solution\nEnd-to-end IP platform\nCloud server for locllized_content\nHigh Data Rates",
        "label4_title": "Customer Relationships",
        "label4_content": "24/7 Customer Care Centre\nMicro Operators/VLEs\nRegional Sales Executives",
        "label5_title": "Customer Segments",
        "label5_content": "Local community\nSMEs\nPublic authorities\nLocal Retail Market",
        "label6_title": "Channels",
        "label6_content": "Micro Operators/ VLEs\nCo-branded outlets\nPartnership Telcos/ISPs\nDirect Sales\nWebsite\nSocial media channels",
        "label7_title": "Key Resources",
        "label7_content": "Physical resources (technologies, machineries)\nFinancial resources (Investors and Venture Funding)\nPassionate Management",
        "label9_title": "Cost Structure",
        "label9_content": "Manufacturing & Sourcing\nWi-Fi hotspot integration, funding, deployment, operation & maintenance\nEmployees & office\nR&D & Training\nSales & Marketing",
        "label10_title": "Revenue Streams",
        "label10_content": "Subscription fee\nContent/ Application services revenue share\nGovt. Anchor usage payment receipts\nMobile Data offload revenues\nNetwork slice rent (technology, software, mobile and broadband network providers)"
    },
    {
        "unique_id": 6,
        "sector": "Agriculture",
        "service": "Drones Operation",
        "user_type": "Public Authority",
        "label1_title": "Partnerships/Suppliers/Collaborators",
        "label1_content": "State, Municipality, Federal Government, Ministries, European Union, Universities, Development Agencies, Private sector (e.g. MNOs, ISPs), Financial Institutions.",
        "label2_title": "Other Stakeholders",
        "label2_content": "Society, NGOs, Chamber of Tradesmen, Agricultural Organisations, and Civil Aviation.",
        "label3_title": "Resources",
        "label3_content": "Financial\nHuman\nMaterial\nEquipment\nIT\nLegislation",
        "label4_title": "Capabilities",
        "label4_content": "Systemic, strategic and entrepreneurial vision\nMultidisciplinary knowledge\nAnalytical and forecast capacity\nAbility to think, develop studies and plans",
        "label5_title": "Proccesses",
        "label5_content": "Strategic Process Management\nElaboration of development plans\nLocal and regional potential mapping\nIdentification of economic potential\nManagement of environmental impacts\nElaboration of policies,plans and programs\nAgreements Management\nPartnerships management and cooperation agreements",
        "label6_title": "Products",
        "label6_content": "Laws and regulatory frameworks for: Internet connectivity\nLaws and regulatory frameworks for: Drones operations (state, regional and sectoral levels)",
        "label7_title": "Customers",
        "label7_content": "Society\nMunicipalities\nCompanies from all the industries (especially agriculture)\nUniversities\nThird sector",
        "label9_title": "Outcomes",
        "label9_content": "Universal and democratic access to digital connectivity\nIncrease of per capita income\nIncrease of the State PID\nJob creation\nHigher HDI\nEconomic and societal strengthening of municipalities and strategic regions of the state\nSustainable Agriculture\nEnhance citizens� digital literacy\nReduction of social inequality\nReduction of desertification",
        "label10_title": "Public Value",
        "label10_content": "Provide universal access to High-Speed Internet services by means of trustable and credible service, generating a fair and prosperous state that guarantees the well-being of the population as well as the financial sustainability of SMEs."
    },
    {
        "unique_id": 7,
        "sector": "Agriculture",
        "service": "Livestock Health",
        "user_type": "End-User",
        "label1_title": "Key Partners",
        "label1_content": "Internet service providers ISP\nTechnology Providers\nApplication providers (data analytics)\nFinancial providers (e.g. banks)\nGovernment and Regulatory bodies",
        "label2_title": "Key Activities",
        "label2_content": "Provide health and wellbeing guidance & interventions for Livestock health",
        "label3_title": "Value Propositions",
        "label3_content": "Enhance well fare of livestock\nLivestock Record Keeping",
        "label4_title": "Customer Relationships",
        "label4_content": "Personal assistance\nCo-creation (tailor made)",
        "label5_title": "Customer Segments",
        "label5_content": "Research & Academia\nSMEs (e.g. processors, retailers)",
        "label6_title": "Channels",
        "label6_content": "Social media channels\nWebsite",
        "label7_title": "Key Resources",
        "label7_content": "Physical resources (cameras, microphones)\nHuman resources (software developers, technical support)",
        "label9_title": "Cost Structure",
        "label9_content": "Fixed costs for Infrastructure  and maintenance\nOperational costs",
        "label10_title": "Revenue Streams",
        "label10_content": "Selling farming products (B2B/B2C)\nIncreased profitability via increased production\nIncreased profitability via cost reductions"
    },
    {
        "unique_id": 8,
        "sector": "Agriculture",
        "service": "Livestock Health",
        "user_type": "Internet Service Provider (ISP)",
        "label1_title": "Key Partners",
        "label1_content": "MNOs\nTechnology and Data providers \nNGOs\nAcademic Institutions\nSystem Integrators\nFinancial providers (e.g. banks)\nGovernment and Regulatory bodies",
        "label2_title": "Key Activities",
        "label2_content": "Local Manufacturing & Sourcing\nAlliances & Partnerships formation\nSales & Promotion",
        "label3_title": "Value Propositions",
        "label3_content": "Low cost, Low power & Low maintenance (3L)\nHigh QOS & Coverage\nEnvironment-friendly green-solution\nEnd-to-end IP platform\nCloud server for locllized_content\nHigh Data Rates",
        "label4_title": "Customer Relationships",
        "label4_content": "24/7 Customer Care Centre\nMicro Operators/VLEs\nRegional Sales Executives",
        "label5_title": "Customer Segments",
        "label5_content": "Local community\nSMEs: Farms\nPublic authorities\nResearch & Academia",
        "label6_title": "Channels",
        "label6_content": "Micro Operators/ VLEs\nCo-branded outlets\nPartnership Telcos/ISPs\nDirect Sales\nWebsite\nSocial media channels",
        "label7_title": "Key Resources",
        "label7_content": "Physical resources (technologies, machineries)\nFinancial resources (Investors and Venture Funding)\nPassionate Management",
        "label9_title": "Cost Structure",
        "label9_content": "Manufacturing & Sourcing\nWi-Fi hotspot integration, funding, deployment, operation & maintenance\nEmployees & office\nR&D & Training\nSales & Marketing",
        "label10_title": "Revenue Streams",
        "label10_content": "Subscription fee\nNetwork slice rent (technology, software, mobile and broadband network providers)\nGovt. Anchor usage payment receipts\nLocal municipality or MNOs (Mobile network Operators) funding"
    },
    {
        "unique_id": 9,
        "sector": "Agriculture",
        "service": "Livestock Health",
        "user_type": "Public Authority",
        "label1_title": "Partnerships/Suppliers/Collaborators",
        "label1_content": "State, Municipality, Federal Government, Ministries, European Union, Universities, Development Agencies, Private sector (e.g. MNOs, ISPs), Financial Institutions.",
        "label2_title": "Other Stakeholders",
        "label2_content": "Society, NGOs, Chamber of Tradesmen, Veterinary Organisations",
        "label3_title": "Resources",
        "label3_content": "Financial\nHuman\nMaterial\nEquipment\nIT\nLegislation",
        "label4_title": "Capabilities",
        "label4_content": "Systemic, strategic and entrepreneurial vision\nMultidisciplinary knowledge\nAnalytical and forecast capacity\nAbility to think, develop studies and plans",
        "label5_title": "Proccesses",
        "label5_content": "Strategic Process Management\nElaboration of development plans\nLocal and regional potential mapping\nIdentification of economic potential\nManagement of environmental impacts\nElaboration of policies,plans and programs\nAgreements Management\nPartnerships management and cooperation agreements",
        "label6_title": "Products",
        "label6_content": "Laws and regulatory frameworks for: Internet connectivity\nLaws and regulatory frameworks for: Veterinary Livestock consulting (state, regional and sectoral levels)",
        "label7_title": "Customers",
        "label7_content": "Society\nMunicipalities\nUniversities\nThird sector\nSMEs:Agriculture",
        "label9_title": "Outcomes",
        "label9_content": "Universal and democratic access to digital connectivity\nIncrease of per capita income\nIncrease of the State PID\nJob creation\nHigher HDI\nEconomic and societal strengthening of municipalities and strategic regions of the state\nEnhance citizens� digital literacy\nReduction of social inequality\nReduction of desertification\nSustainable farming\nProtection of animals",
        "label10_title": "Public Value",
        "label10_content": "Provide universal access to High-Speed Internet services by means of trustable and credible service, generating a fair and prosperous state that guarantees the well-being of the population as well as the financial sustainability of SMEs."
    },
    {
        "unique_id": 10,
        "sector": "Agriculture",
        "service": "Farm Management",
        "user_type": "End-User",
        "label1_title": "Key Partners",
        "label1_content": "Internet service providers ISP\nTechnology Providers\nApplication providers (data analytics)\nFinancial providers (e.g. banks)\nGovernment and Regulatory bodies",
        "label2_title": "Key Activities",
        "label2_content": "Devise a service for the livestock farming industry that can be tailored and adapted to diverse farming ecosystems.\nTimely reception of data from multirotor drone equipped with high-end camera using 5G network.",
        "label3_title": "Value Propositions",
        "label3_content": "Farmer Time efficiency\nReduce stress for the farmer\nImpact on productivity and welfare of the livestock",
        "label4_title": "Customer Relationships",
        "label4_content": "Personal assistance\nCo-creation (tailor made)",
        "label5_title": "Customer Segments",
        "label5_content": "Local community \nResearch & Academia\nSMEs: Farms",
        "label6_title": "Channels",
        "label6_content": "Social media channels\nWebsite",
        "label7_title": "Key Resources",
        "label7_content": "Physical resources (cameras, IoT sensors)\nHuman resources (farmer, scientists, technicians)",
        "label9_title": "Cost Structure",
        "label9_content": "Fixed costs for Infrastructure (Owned.Rent Drones) and maintenance\nOperational costs",
        "label10_title": "Revenue Streams",
        "label10_content": "Selling farming products (B2B/B2C)\nIncreased profitability via increased production\nIncreased profitability via cost reductions"
    },
    {
        "unique_id": 11,
        "sector": "Agriculture",
        "service": "Farm Management",
        "user_type": "Internet Service Provider (ISP)",
        "label1_title": "Key Partners",
        "label1_content": "MNOs\nTechnology and Data providers \nGovernment and Regulatory bodies\nAcademic Institutions\nSystem Integrators\nFinancial providers (e.g. banks)",
        "label2_title": "Key Activities",
        "label2_content": "Local Manufacturing & Sourcing\nAlliances & Partnerships formation\nSales & Promotion",
        "label3_title": "Value Propositions",
        "label3_content": "Low cost, Low power & Low maintenance (3L)\nHigh QOS & Coverage\nEnvironment-friendly green-solution\nEnd-to-end IP platform\nCloud server for locllized_content\nHigh Data Rates",
        "label4_title": "Customer Relationships",
        "label4_content": "24/7 Customer Care Centre\nMicro Operators/VLEs\nRegional Sales Executives",
        "label5_title": "Customer Segments",
        "label5_content": "Farmers\nSMEs",
        "label6_title": "Channels",
        "label6_content": "Micro Operators/ VLEs\nCo-branded outlets\nPartnership Telcos/ISPs\nDirect Sales\nWebsite\nSocial media channels",
        "label7_title": "Key Resources",
        "label7_content": "Physical resources (cameras, IoT sensors)\nFinancial resources (farmers, scientists, technicians)",
        "label9_title": "Cost Structure",
        "label9_content": "Manufacturing & Sourcing\nWi-Fi hotspot integration, funding, deployment, operation & maintenance\nEmployees & office\nR&D & Training\nSales & Marketing",
        "label10_title": "Revenue Streams",
        "label10_content": "The hardware could be purchased once, and pay for the software (SaaS subscription)\nRent both hardware and software and pay monthly/yearly etc. (Haas-SaaS)"
    },
    {
        "unique_id": 12,
        "sector": "Agriculture",
        "service": "Farm Management",
        "user_type": "Public Authority",
        "label1_title": "Partnerships/Suppliers/Collaborators",
        "label1_content": "State, Municipality, Federal Government, Ministries, European Union, Universities, Development Agencies, Private sector (e.g. MNOs, ISPs), Financial Institutions.",
        "label2_title": "Other Stakeholders",
        "label2_content": "Society, NGOs, Chamber of Tradesmen, Veterinary Organisations",
        "label3_title": "Resources",
        "label3_content": "Financial\nHuman\nMaterial\nEquipment\nIT\nLegislation",
        "label4_title": "Capabilities",
        "label4_content": "Systemic, strategic and entrepreneurial vision\nMultidisciplinary knowledge\nAnalytical and forecast capacity\nAbility to think, develop studies and plans",
        "label5_title": "Proccesses",
        "label5_content": "Strategic Process Management\nElaboration of development plans\nLocal and regional potential mapping\nIdentification of economic potential\nManagement of environmental impacts\nElaboration of policies,plans and programs\nAgreements Management\nPartnerships management and cooperation agreements",
        "label6_title": "Products",
        "label6_content": "Laws and regulatory frameworks for: Internet connectivity\nLaws and regulatory frameworks for: Drones operations (state, regional and sectoral levels)",
        "label7_title": "Customers",
        "label7_content": "Society\nMunicipalities\nUniversities\nSMEs:Agriculture\nSMEs: Farming\nThird sector",
        "label9_title": "Outcomes",
        "label9_content": "Universal and democratic access to digital connectivity\nIncrease of per capita income\nIncrease of the State PID\nJob creation\nHigher HDI\nEconomic and societal strengthening of municipalities and strategic regions of the state\nSustainable farming\nProtection of animals\nReduction of social inequality\nReduction of desertification\nEnhance citizens� health and well-being\nEnhance citizens� digital literacy",
        "label10_title": "Public Value",
        "label10_content": "Provide universal access to High-Speed Internet services by means of trustable and credible service, generating a fair and prosperous state that guarantees the well-being of the population as well as the financial sustainability of SMEs and biodiversity protection."
    },
    {
        "unique_id": 13,
        "sector": "Agriculture",
        "service": "Remote Farming",
        "user_type": "End-User",
        "label1_title": "Key Partners",
        "label1_content": "Internet service providers ISP\nTechnology and Data providers \nApplication providers (data analytics)\nFinancial providers (e.g. banks)\nGovernment and Regulatory bodies",
        "label2_title": "Key Activities",
        "label2_content": "Real-time crop monitoring\nReal-time pest detection\nAutomated area surveillance\nAutonomous Driving (Agricultural sector)",
        "label3_title": "Value Propositions",
        "label3_content": "Extend the service offerings of local agriculture and SMEs in the agriculture sector\nOptimize on-situ operations in the agricultural sector.\nReduce costs for agricultural practices",
        "label4_title": "Customer Relationships",
        "label4_content": "Personal assistance\nCo-creation (tailor made)",
        "label5_title": "Customer Segments",
        "label5_content": "Drone operators/users\nLocal operators\nPublic authorities\nAgricultural sector\nSMEs: Agriculture",
        "label6_title": "Channels",
        "label6_content": "Social media channels\nWebsite",
        "label7_title": "Key Resources",
        "label7_content": "Human resources (e.g. technicians,engineers, IT professionals)\nPhysical resources (e.g. drones, devices, cameras)",
        "label9_title": "Cost Structure",
        "label9_content": "Fixed costs for Infrastructure (Owned Drones) and maintenance\nOperational costs",
        "label10_title": "Revenue Streams",
        "label10_content": "Increased profitability via increased production\nIncreased profitability via cost reductions\nSelling Agricultural products (B2B/B2C)"
    },
    {
        "unique_id": 14,
        "sector": "Agriculture",
        "service": "Remote Farming",
        "user_type": "Internet Service Provider (ISP)",
        "label1_title": "Key Partners",
        "label1_content": "MNOs\nTechnology and Data providers \nNGOs\nAcademic Institutions\nSystem Integrators\nFinancial providers (e.g. banks)\nGovernment and Regulatory bodies\nDrone Manufacturers\nApplication developers (data analytics)",
        "label2_title": "Key Activities",
        "label2_content": "Local Manufacturing & Sourcing\nAlliances & Partnerships formation\nSales & Promotion",
        "label3_title": "Value Propositions",
        "label3_content": "Low cost, Low power & Low maintenance (3L)\nHigh QOS & Coverage\nEnvironment-friendly green-solution\nEnd-to-end IP platform\nCloud server for locllized_content\nHigh Data Rates",
        "label4_title": "Customer Relationships",
        "label4_content": "24/7 Customer Care Centre\nMicro Operators/VLEs\nRegional Sales Executives",
        "label5_title": "Customer Segments",
        "label5_content": "Local community\nSMEs\nPublic authorities\nLocal Retail Market",
        "label6_title": "Channels",
        "label6_content": "Micro Operators/ VLEs\nCo-branded outlets\nPartnership Telcos/ISPs\nDirect Sales\nWebsite\nSocial media channels",
        "label7_title": "Key Resources",
        "label7_content": "Physical resources (technologies, machineries)\nFinancial resources (Investors and Venture Funding)\nPassionate Management",
        "label9_title": "Cost Structure",
        "label9_content": "Manufacturing & Sourcing\nWi-Fi hotspot integration, funding, deployment, operation & maintenance\nEmployees & office\nR&D & Training\nSales & Marketing",
        "label10_title": "Revenue Streams",
        "label10_content": "Subscription fee\nContent/ Application services revenue share\nGovt. Anchor usage payment receipts\nMobile Data offload revenues\nNetwork slice rent (technology, software, mobile and broadband network providers)"
    },
    {
        "unique_id": 15,
        "sector": "Agriculture",
        "service": "Remote Farming",
        "user_type": "Public Authority",
        "label1_title": "Partnerships/Suppliers/Collaborators",
        "label1_content": "State, Municipality, Federal Government, Ministries, European Union, Universities, Development Agencies, Private sector (e.g. MNOs, ISPs), Financial Institutions.",
        "label2_title": "Other Stakeholders",
        "label2_content": "Society, NGOs, Chamber of Tradesmen and Agricultural Organisations",
        "label3_title": "Resources",
        "label3_content": "Financial\nHuman\nMaterial\nEquipment\nIT\nLegislation",
        "label4_title": "Capabilities",
        "label4_content": "Systemic, strategic and entrepreneurial vision\nMultidisciplinary knowledge\nAnalytical and forecast capacity\nAbility to think, develop studies and plans",
        "label5_title": "Proccesses",
        "label5_content": "Strategic Process Management\nElaboration of development plans\nLocal and regional potential mapping\nIdentification of economic potential\nManagement of environmental impacts\nElaboration of policies,plans and programs\nAgreements Management\nPartnerships management and cooperation agreements",
        "label6_title": "Products",
        "label6_content": "Laws and regulatory frameworks for: Internet connectivity\nLaws and regulatory frameworks for: Drones operations (state, regional and sectoral levels)",
        "label7_title": "Customers",
        "label7_content": "Society\nMunicipalities\nCompanies from all the industries (especially agriculture)\nUniversities\nThird sector",
        "label9_title": "Outcomes",
        "label9_content": "Universal and democratic access to digital connectivity\nIncrease of per capita income\nIncrease of the State PID\nJob creation\nHigher HDI\nEconomic and societal strengthening of municipalities and strategic regions of the state\nSustainable Agriculture\nEnhance citizens� digital literacy\nReduction of social inequality\nReduction of desertification",
        "label10_title": "Public Value",
        "label10_content": "Provide universal access to High-Speed Internet services by means of trustable and credible service, generating a fair and prosperous state that guarantees the well-being of the population as well as the financial sustainability of SMEs."
    },
    {
        "unique_id": 16,
        "sector": "Agriculture",
        "service": "Smart Farming",
        "user_type": "End-User",
        "label1_title": "Key Partners",
        "label1_content": "Internet service providers ISP\nTechnology and Data providers \nApplication providers (data analytics)\nFinancial providers (e.g. banks)\nGovernment and Regulatory bodies",
        "label2_title": "Key Activities",
        "label2_content": "Real-time Farm monitoring\nReal-time livestock detection\nAutomated area surveillance\nReal-time crop monitoring\nReal-time pest detection\nAutonomous Driving (Agricultural sector)",
        "label3_title": "Value Propositions",
        "label3_content": "Extend the service offerings of local agriculture and SMEs in the agriculture sector\nOptimize on-situ operations in the agricultural sector.\nReduce costs for agricultural practices (e.g. fertilisers).\nProtect biodiversity and the environment through optimised agricultural practices\nUpgrade network quality and speed for a positive impact in the agriculture sector\nLivestock Risk Management/Early Problem Detection",
        "label4_title": "Customer Relationships",
        "label4_content": "Personal assistance\nCo-creation (tailor made)",
        "label5_title": "Customer Segments",
        "label5_content": "Drone operators/users\nLocal operators\nPublic authorities\nAgricultural sector\nSMEs: Agriculture\nVeterunary sector",
        "label6_title": "Channels",
        "label6_content": "Social media channels\nWebsite",
        "label7_title": "Key Resources",
        "label7_content": "Human resources (e.g. technicians,engineers, IT professionals)\nPhysical resources (e.g. drones, devices, cameras)",
        "label9_title": "Cost Structure",
        "label9_content": "Fixed costs for Infrastructure (Owned Drones) and maintenance\nOperational costs",
        "label10_title": "Revenue Streams",
        "label10_content": "Increased profitability via increased production\nIncreased profitability via cost reductions\nSelling Agricultural products (B2B/B2C)"
    },
    {
        "unique_id": 17,
        "sector": "Agriculture",
        "service": "Smart Farming",
        "user_type": "Internet Service Provider (ISP)",
        "label1_title": "Key Partners",
        "label1_content": "MNOs\nTechnology and Data providers \nNGOs\nAcademic Institutions\nSystem Integrators\nFinancial providers (e.g. banks)\nGovernment and Regulatory bodies\nDrone Manufacturers\nApplication developers (data analytics)",
        "label2_title": "Key Activities",
        "label2_content": "Local Manufacturing & Sourcing\nAlliances & Partnerships formation\nSales & Promotion",
        "label3_title": "Value Propositions",
        "label3_content": "Low cost, Low power & Low maintenance (3L)\nHigh QOS & Coverage\nEnvironment-friendly green-solution\nEnd-to-end IP platform\nCloud server for locllized_content\nHigh Data Rates",
        "label4_title": "Customer Relationships",
        "label4_content": "24/7 Customer Care Centre\nMicro Operators/VLEs\nRegional Sales Executives",
        "label5_title": "Customer Segments",
        "label5_content": "Local community\nSMEs\nPublic authorities\nLocal Retail Market",
        "label6_title": "Channels",
        "label6_content": "Micro Operators/ VLEs\nCo-branded outlets\nPartnership Telcos/ISPs\nDirect Sales\nWebsite\nSocial media channels",
        "label7_title": "Key Resources",
        "label7_content": "Physical resources (technologies, machineries)\nFinancial resources (Investors and Venture Funding)\nPassionate Management",
        "label9_title": "Cost Structure",
        "label9_content": "Manufacturing & Sourcing\nWi-Fi hotspot integration, funding, deployment, operation & maintenance\nEmployees & office\nR&D & Training\nSales & Marketing",
        "label10_title": "Revenue Streams",
        "label10_content": "Subscription fee\nContent/ Application services revenue share\nGovt. Anchor usage payment receipts\nMobile Data offload revenues\nNetwork slice rent (technology, software, mobile and broadband network providers)"
    },
    {
        "unique_id": 18,
        "sector": "Agriculture",
        "service": "Smart Farming",
        "user_type": "Public Authority",
        "label1_title": "Partnerships/Suppliers/Collaborators",
        "label1_content": "State, Municipality, Federal Government, Ministries, European Union, Universities, Development Agencies, Private sector (e.g. MNOs, ISPs), Financial Institutions.",
        "label2_title": "Other Stakeholders",
        "label2_content": "Society, NGOs, Chamber of Tradesmen, Agricultural Organisations, and Civil Aviation.",
        "label3_title": "Resources",
        "label3_content": "Financial\nHuman\nMaterial\nEquipment\nIT\nLegislation",
        "label4_title": "Capabilities",
        "label4_content": "Systemic, strategic and entrepreneurial vision\nMultidisciplinary knowledge\nAnalytical and forecast capacity\nAbility to think, develop studies and plans",
        "label5_title": "Proccesses",
        "label5_content": "Strategic Process Management\nElaboration of development plans\nLocal and regional potential mapping\nIdentification of economic potential\nManagement of environmental impacts\nElaboration of policies,plans and programs\nAgreements Management\nPartnerships management and cooperation agreements",
        "label6_title": "Products",
        "label6_content": "Laws and regulatory frameworks for: Internet connectivity\nLaws and regulatory frameworks for: Drones operations (state, regional and sectoral levels)\nLaws and regulatory frameworks for: Veterinary operations (state, regional and sectoral levels)",
        "label7_title": "Customers",
        "label7_content": "Society\nMunicipalities\nSustainable Agriculture\nUniversities\nThird sector",
        "label9_title": "Outcomes",
        "label9_content": "Universal and democratic access to digital connectivity\nIncrease of per capita income\nIncrease of the State PID\nJob creation\nHigher HDI\nEconomic and societal strengthening of municipalities and strategic regions of the state\nSustainable Agriculture\nEnhance citizens� digital literacy\nReduction of social inequality\nReduction of desertification",
        "label10_title": "Public Value",
        "label10_content": "Provide universal access to High-Speed Internet services by means of trustable and credible service, generating a fair and prosperous state that guarantees the well-being of the population as well as the financial sustainability of SMEs."
    },
    {
        "unique_id": 19,
        "sector": "Agriculture",
        "service": "Broadband Connectivity (Access)",
        "user_type": "End-User",
        "label1_title": "Key Partners",
        "label1_content": "Internet service providers ISP\nTechnology and Data providers \nApplication providers (data analytics)\nFinancial providers (e.g. banks)\nGovernment and Regulatory bodies",
        "label2_title": "Key Activities",
        "label2_content": "Negotiations and Agreements: Establish contracts with Network Operetors, ISPs and other stakeholders.\nInstall and maintain connectivity infrastructure such as cell towers, real estate and fiber-optic networks in order to meet the connectivity needs of local stakeholders.",
        "label3_title": "Value Propositions",
        "label3_content": "Expand market reach and create new opportunities for local businesses, like agriculture, tourism, eHealth and other SMEs in the community, and individuals.\nHelp optimise operations & reduce costs.",
        "label4_title": "Customer Relationships",
        "label4_content": "Personal assistance.\nCo-creation (tailor made).",
        "label5_title": "Customer Segments",
        "label5_content": "Local businesses\nPublic authorities\nCitizens",
        "label6_title": "Channels",
        "label6_content": "Direct Sales: Engage with local SMEs and citizens.\nPartnerships: Collaborate with government agencies, NGOs, and existing infrastructure providers.",
        "label7_title": "Key Resources",
        "label7_content": "Human resources (e.g. technicians,engineers, IT professionals).\nPhysical resources (e.g. cell towers, real estate and fiber-optic networks etc).",
        "label9_title": "Cost Structure",
        "label9_content": "Infrastructure Costs: Towers, cables, and maintenance.\nOperational Costs: Staff salaries, energy, and ongoing maintenance.\nLegal and Regulatory Compliance Costs.",
        "label10_title": "Revenue Streams",
        "label10_content": "Increased profitability via increased production.\nIncreased profitability via cost reductions.\nSelling of products & services (B2B/B2C)."
    },
    {
        "unique_id": 20,
        "sector": "Agriculture",
        "service": "Broadband Connectivity (Access)",
        "user_type": "Internet Service Provider (ISP)",
        "label1_title": "Key Partners",
        "label1_content": "MNOs\nTechnology and Data providers \nNGOs\nAcademic Institutions\nSystem Integrators\nFinancial providers (e.g. banks)\nGovernment and Regulatory bodies\nDrone Manufacturers\nApplication developers (data analytics)",
        "label2_title": "Key Activities",
        "label2_content": "Local Manufacturing & Sourcing\nAlliances & Partnerships formation\nSales & Promotion",
        "label3_title": "Value Propositions",
        "label3_content": "Low cost, Low power & Low maintenance (3L)\nHigh QOS & Coverage\nEnvironment-friendly green-solution\nEnd-to-end IP platform\nCloud server for locllized_content\nHigh Data Rates",
        "label4_title": "Customer Relationships",
        "label4_content": "24/7 Customer Care Centre\nMicro Operators/VLEs\nRegional Sales Executives",
        "label5_title": "Customer Segments",
        "label5_content": "Internet Service Providers (ISPs)\nLocal Communities\nSMEs\nPublic authorities\nNGOs\nResearch & Academia\nCommunity Retail Market",
        "label6_title": "Channels",
        "label6_content": "Micro Operators/ VLEs\nCo-branded outlets\nPartnership Telcos/ISPs\nDirect Sales\nWebsite\nSocial media channels",
        "label7_title": "Key Resources",
        "label7_content": "Physical resources (technologies, machineries)\nFinancial resources (Investors and Venture Funding)\nPassionate Management",
        "label9_title": "Cost Structure",
        "label9_content": "Manufacturing & Sourcing\nWi-Fi hotspot integration, funding, deployment, operation & maintenance\nEmployees & office\nR&D & Training\nSales & Marketing",
        "label10_title": "Revenue Streams",
        "label10_content": "Haas. Rent the set of hardware including deployment of the network (The client would be billed monthly, based on the amount of sensor systems deployed).\nIaas. The client should use his own hardware or purchase a device from us. Charge the client based on the amount of data to be transmitted."
    },
    {
        "unique_id": 21,
        "sector": "Agriculture",
        "service": "Broadband Connectivity (Access)",
        "user_type": "Public Authority",
        "label1_title": "Partnerships/Suppliers/Collaborators",
        "label1_content": "State, Municipality, Federal Government, Ministries, European Union, Universities, Development Agencies, Private sector (e.g. MNOs, ISPs), Financial Institutions.",
        "label2_title": "Other Stakeholders",
        "label2_content": "Society, NGOs and Chamber of Tradesmen",
        "label3_title": "Resources",
        "label3_content": "Financial\nHuman\nMaterial\nEquipment\nIT\nLegislation",
        "label4_title": "Capabilities",
        "label4_content": "Systemic, strategic and entrepreneurial vision\nMultidisciplinary knowledge\nAnalytical and forecast capacity\nAbility to think, develop studies and plans",
        "label5_title": "Proccesses",
        "label5_content": "Strategic Process Management.\nElaboration of development plans.\nLocal and regional potential mapping.\nIdentification of economic potential.\nManagement of environmental impacts.\nElaboration of policies,plans and programs.\nAgreements Management.\nPartnerships management and cooperation agreements.",
        "label6_title": "Products",
        "label6_content": "Laws and regulatory frameworks for: Internet connectivity.",
        "label7_title": "Customers",
        "label7_content": "Society\nMunicipalities\nInternet Service Providers (ISPs)\nUniversities\nThird sector",
        "label9_title": "Outcomes",
        "label9_content": "Universal and democratic access to digital connectivity.\nIncrease of per capita income.\nIncrease of the State PID.\nJob creation.\nHigher HDI.\nEconomic and societal strengthening of municipalities and strategic regions of the state.\nEnhance citizens� digital literacy.\nReduction of social inequality.\nReduction of desertification.",
        "label10_title": "Public Value",
        "label10_content": "Provide universal access to High-Speed Internet services by means of trustable and credible service, generating a fair and prosperous state that guarantees the well-being of the population as well as the financial sustainability of SMEs."
    },
    {
        "unique_id": 22,
        "sector": "Aquaculture",
        "service": "Remote Farming",
        "user_type": "End-User",
        "label1_title": "Key Partners",
        "label1_content": "Internet service providers ISP\nTechnology and Data providers \nApplication providers (data analytics)\nFinancial providers (e.g. banks)\nGovernment and Regulatory bodies",
        "label2_title": "Key Activities",
        "label2_content": "Real-time crop monitoring\nReal-time pest detection\nAutomated area surveillance\nAutonomous Driving (Agricultural sector)",
        "label3_title": "Value Propositions",
        "label3_content": "Extend the service offerings of local agriculture and SMEs in the agriculture sector\nOptimize on-situ operations in the agricultural sector.\nReduce costs for agricultural practices (e.g. fertilisers).\nProtect biodiversity and the environment through optimised agricultural practices\nUpgrade network quality and speed for a positive impact in the agriculture sector\nReduce costs for agricultural practices",
        "label4_title": "Customer Relationships",
        "label4_content": "Personal assistance\nCo-creation (tailor made)",
        "label5_title": "Customer Segments",
        "label5_content": "Drone operators/users\nLocal operators\nPublic authorities\nAgricultural sector\nSMEs : Agriculture",
        "label6_title": "Channels",
        "label6_content": "Social media channels\nWebsite",
        "label7_title": "Key Resources",
        "label7_content": "Human resources (e.g. technicians,engineers, IT professionals)\nPhysical resources (e.g. drones, devices, cameras)",
        "label9_title": "Cost Structure",
        "label9_content": "Fixed costs for Infrastructure (Owned Drones) and maintenance\nOperational costs",
        "label10_title": "Revenue Streams",
        "label10_content": "Increased profitability via increased production\nIncreased profitability via cost reductions\nSelling Agricultural products (B2B/B2C)"
    },
    {
        "unique_id": 23,
        "sector": "Aquaculture",
        "service": "Remote Farming",
        "user_type": "Internet Service Provider (ISP)",
        "label1_title": "Key Partners",
        "label1_content": "MNOs\nTechnology and Data providers \nNGOs\nAcademic Institutions\nSystem Integrators\nFinancial providers (e.g. banks)\nGovernment and Regulatory bodies",
        "label2_title": "Key Activities",
        "label2_content": "Local Manufacturing & Sourcing\nAlliances & Partnerships formation\nSales & Promotion",
        "label3_title": "Value Propositions",
        "label3_content": "Low cost, Low power & Low maintenance (3L)\nHigh QOS & Coverage\nEnvironment-friendly green-solution\nEnd-to-end IP platform\nCloud server for locllized_content\nHigh Data Rates",
        "label4_title": "Customer Relationships",
        "label4_content": "24/7 Customer Care Centre\nMicro Operators/VLEs\nRegional Sales Executives",
        "label5_title": "Customer Segments",
        "label5_content": "Local community\nSMEs\nPublic authorities\nLocal Retail Market",
        "label6_title": "Channels",
        "label6_content": "Micro Operators/ VLEs\nCo-branded outlets\nPartnership Telcos/ISPs\nDirect Sales\nWebsite\nSocial media channels",
        "label7_title": "Key Resources",
        "label7_content": "Physical resources (technologies, machineries)\nFinancial resources (Investors and Venture Funding)\nPassionate Management",
        "label9_title": "Cost Structure",
        "label9_content": "Manufacturing & Sourcing\nWi-Fi hotspot integration, funding, deployment, operation & maintenance\nEmployees & office\nR&D & Training\nSales & Marketing",
        "label10_title": "Revenue Streams",
        "label10_content": "Subscription fee\nContent/ Application services revenue share\nGovt. Anchor usage payment receipts\nMobile Data offload revenues\nNetwork slice rent (technology, software, mobile and broadband network providers)"
    },
    {
        "unique_id": 24,
        "sector": "Aquaculture",
        "service": "Remote Farming",
        "user_type": "Public Authority",
        "label1_title": "Partnerships/Suppliers/Collaborators",
        "label1_content": "State, Municipality, Federal Government, Ministries, European Union, Universities, Development Agencies, Private sector (e.g. MNOs, ISPs), Financial Institutions.",
        "label2_title": "Other Stakeholders",
        "label2_content": "Society, NGOs, Chamber of Tradesmen, Agricultural Organisations, and Civil Aviation.",
        "label3_title": "Resources",
        "label3_content": "Financial\nHuman\nMaterial\nEquipment\nIT\nLegislation",
        "label4_title": "Capabilities",
        "label4_content": "Systemic, strategic and entrepreneurial vision\nMultidisciplinary knowledge\nAnalytical and forecast capacity\nAbility to think, develop studies and plans",
        "label5_title": "Proccesses",
        "label5_content": "Strategic Process Management\nElaboration of development plans\nLocal and regional potential mapping\nIdentification of economic potential\nManagement of environmental impacts\nElaboration of policies,plans and programs\nAgreements Management\nPartnerships management and cooperation agreements",
        "label6_title": "Products",
        "label6_content": "Laws and regulatory frameworks for: Internet connectivity\nLaws and regulatory frameworks for: Drones operations (state, regional and sectoral levels)",
        "label7_title": "Customers",
        "label7_content": "Society\nMunicipalities\nCompanies from all the industries (especially agriculture)\nUniversities\nThird sector",
        "label9_title": "Outcomes",
        "label9_content": "Universal and democratic access to digital connectivity\nIncrease of per capita income\nIncrease of the State PID\nJob creation\nHigher HDI\nEconomic and societal strengthening of municipalities and strategic regions of the state\nSustainable Agriculture\nEnhance citizens� digital literacy\nReduction of social inequality\nReduction of desertification",
        "label10_title": "Public Value",
        "label10_content": "Provide universal access to High-Speed Internet services by means of trustable and credible service, generating a fair and prosperous state that guarantees the well-being of the population as well as the financial sustainability of SMEs."
    },
    {
        "unique_id": 25,
        "sector": "Aquaculture",
        "service": "Water Quality Monitoring",
        "user_type": "End-User",
        "label1_title": "Key Partners",
        "label1_content": "Internet service providers ISP\nTechnology and Data providers \nApplication providers (data analytics)\nFinancial providers (e.g. banks)\nGovernment and Regulatory bodies",
        "label2_title": "Key Activities",
        "label2_content": "Periodic data transmission of the water quality parameters in the environment",
        "label3_title": "Value Propositions",
        "label3_content": "Human resource and operations optimization and cost reduction reduce the overall impact of operations on the environment",
        "label4_title": "Customer Relationships",
        "label4_content": "Personal assistance\nCo-creation (tailor made)",
        "label5_title": "Customer Segments",
        "label5_content": "SMEs\nLocal community\nEnvironmental agencies\nEnvironmental agencies",
        "label6_title": "Channels",
        "label6_content": "Social media channels\nWebsite",
        "label7_title": "Key Resources",
        "label7_content": "Human resources (farmer, cloud/software/hardware/API engineers)\nPhysical resources (water quality sensors, LoRaWaN transmitter/ gateway, edge devices)",
        "label9_title": "Cost Structure",
        "label9_content": "Fixed costs for Infrastructure and maintenance\nOperational costs",
        "label10_title": "Revenue Streams",
        "label10_content": "Selling farming products (B2B/B2C)\nIncreased profitability via increased production\nIncreased profitability via cost reductions"
    },
    {
        "unique_id": 26,
        "sector": "Aquaculture",
        "service": "Water Quality Monitoring",
        "user_type": "Internet Service Provider (ISP)",
        "label1_title": "Key Partners",
        "label1_content": "MNOs\nTechnology and Data providers \nNGOs\nAcademic Institutions\nSystem Integrators\nFinancial providers (e.g. banks)\nGovernment and Regulatory bodies",
        "label2_title": "Key Activities",
        "label2_content": "Local Manufacturing & Sourcing\nAlliances & Partnerships formation\nSales & Promotion",
        "label3_title": "Value Propositions",
        "label3_content": "Low cost, Low power & Low maintenance (3L)\nHigh QOS & Coverage\nEnvironment-friendly green-solution\nEnd-to-end IP platform\nCloud server for locllized_content\nHigh Data Rates",
        "label4_title": "Customer Relationships",
        "label4_content": "24/7 Customer Care Centre\nMicro Operators/VLEs\nRegional Sales Executives",
        "label5_title": "Customer Segments",
        "label5_content": "Public authorities\nResearch & Academia\nEnvironmental engineers",
        "label6_title": "Channels",
        "label6_content": "Micro Operators/ VLEs\nCo-branded outlets\nPartnership Telcos/ISPs\nDirect Sales\nWebsite\nSocial media channels",
        "label7_title": "Key Resources",
        "label7_content": "Physical resources (technologies, machineries)\nFinancial resources (Investors and Venture Funding)\nPassionate Management",
        "label9_title": "Cost Structure",
        "label9_content": "Manufacturing & Sourcing\nLoRaWAN integration, funding, deployment, operation & maintenance\nEmployees & office\nR&D & Training\nSales & Marketing",
        "label10_title": "Revenue Streams",
        "label10_content": "Haas. Rent the set of hardware including deployment of the network (The client would be billed monthly, based on the amount of sensor systems deployed).\nIaas. The client should use his own hardware or purchase a device from us. Charge the client based on the amount of data to be transmitted."
    },
    {
        "unique_id": 27,
        "sector": "Aquaculture",
        "service": "Water Quality Monitoring",
        "user_type": "Public Authority",
        "label1_title": "Partnerships/Suppliers/Collaborators",
        "label1_content": "State, Municipality, Federal Government, Ministries, European Union, Universities, Development Agencies, Private sector (e.g. MNOs, ISPs), Financial Institutions.",
        "label2_title": "Other Stakeholders",
        "label2_content": "Society, NGOs, Chamber of Tradesmen,Environmental Organisations",
        "label3_title": "Resources",
        "label3_content": "Financial\nHuman\nMaterial\nEquipment\nIT\nLegislation",
        "label4_title": "Capabilities",
        "label4_content": "Systemic, strategic and entrepreneurial vision\nMultidisciplinary knowledge\nAnalytical and forecast capacity\nAbility to think, develop studies and plans",
        "label5_title": "Proccesses",
        "label5_content": "Strategic Process Management\nElaboration of development plans\nLocal and regional potential mapping\nIdentification of economic potential\nManagement of environmental impacts\nElaboration of policies,plans and programs\nAgreements Management\nPartnerships management and cooperation agreements",
        "label6_title": "Products",
        "label6_content": "Laws and regulatory frameworks for: Internet connectivity\nLaws and regulatory frameworks for: Satellite operations (state, regional and sectoral levels)",
        "label7_title": "Customers",
        "label7_content": "Society\nMunicipalities\nCompanies from all the industries \nUniversities\nThird sector",
        "label9_title": "Outcomes",
        "label9_content": "Universal and democratic access to digital connectivity\nIncrease of per capita income\nIncrease of the State PID\nJob creation\nHigher HDI\nEconomic and societal strengthening of municipalities and strategic regions of the state\nProtection of water\nEnhance citizens� digital literacy\nReduction of social inequality\nReduction of desertification",
        "label10_title": "Public Value",
        "label10_content": "Provide universal access to High-Speed Internet services by means of trustable and credible service, generating a fair and prosperous state that guarantees the well-being of the population as well as the financial sustainability of SMEs."
    },
    {
        "unique_id": 28,
        "sector": "Aquaculture",
        "service": "Broadband Connectivity (Access)",
        "user_type": "End-User",
        "label1_title": "Key Partners",
        "label1_content": "Internet service providers ISP\nTechnology and Data providers \nApplication providers (data analytics)\nFinancial providers (e.g. banks)\nGovernment and Regulatory bodies",
        "label2_title": "Key Activities",
        "label2_content": "Negotiations and Agreements: Establish contracts with Network Operetors, ISPs and other stakeholders.\nInstall and maintain connectivity infrastructure such as cell towers, real estate and fiber-optic networks in order to meet the connectivity needs of local stakeholders.",
        "label3_title": "Value Propositions",
        "label3_content": "Expand market reach and create new opportunities for local businesses, like agriculture, tourism, eHealth and other SMEs in the community, and individuals.\nHelp optimise operations & reduce costs.",
        "label4_title": "Customer Relationships",
        "label4_content": "Personal assistance.\nCo-creation (tailor made).",
        "label5_title": "Customer Segments",
        "label5_content": "Local businesses\nPublic authorities\nCitizens",
        "label6_title": "Channels",
        "label6_content": "Direct Sales: Engage with local SMEs and citizens.\nPartnerships: Collaborate with government agencies, NGOs, and existing infrastructure providers.",
        "label7_title": "Key Resources",
        "label7_content": "Human resources (e.g. technicians,engineers, IT professionals).\nPhysical resources (e.g. cell towers, real estate and fiber-optic networks etc).",
        "label9_title": "Cost Structure",
        "label9_content": "Infrastructure Costs: Towers, cables, and maintenance.\nOperational Costs: Staff salaries, energy, and ongoing maintenance.\nLegal and Regulatory Compliance Costs.",
        "label10_title": "Revenue Streams",
        "label10_content": "Increased profitability via increased production.\nIncreased profitability via cost reductions.\nSelling of products & services (B2B/B2C)."
    },
    {
        "unique_id": 29,
        "sector": "Aquaculture",
        "service": "Broadband Connectivity (Access)",
        "user_type": "Internet Service Provider (ISP)",
        "label1_title": "Key Partners",
        "label1_content": "MNOs\nTechnology and Data providers \nNGOs\nAcademic Institutions\nSystem Integrators\nFinancial providers (e.g. banks)\nGovernment and Regulatory bodies\nDrone Manufacturers\nApplication developers (data analytics)",
        "label2_title": "Key Activities",
        "label2_content": "Local Manufacturing & Sourcing\nAlliances & Partnerships formation\nSales & Promotion",
        "label3_title": "Value Propositions",
        "label3_content": "Low cost, Low power & Low maintenance (3L)\nHigh QOS & Coverage\nEnvironment-friendly green-solution\nEnd-to-end IP platform\nCloud server for locllized_content\nHigh Data Rates",
        "label4_title": "Customer Relationships",
        "label4_content": "24/7 Customer Care Centre\nMicro Operators/VLEs\nRegional Sales Executives",
        "label5_title": "Customer Segments",
        "label5_content": "Internet Service Providers (ISPs)\nLocal Communities\nSMEs\nPublic authorities\nNGOs\nResearch & Academia\nCommunity Retail Market",
        "label6_title": "Channels",
        "label6_content": "Micro Operators/ VLEs\nCo-branded outlets\nPartnership Telcos/ISPs\nDirect Sales\nWebsite\nSocial media channels",
        "label7_title": "Key Resources",
        "label7_content": "Physical resources (technologies, machineries)\nFinancial resources (Investors and Venture Funding)\nPassionate Management",
        "label9_title": "Cost Structure",
        "label9_content": "Manufacturing & Sourcing\nWi-Fi hotspot integration, funding, deployment, operation & maintenance\nEmployees & office\nR&D & Training\nSales & Marketing",
        "label10_title": "Revenue Streams",
        "label10_content": "Haas. Rent the set of hardware including deployment of the network (The client would be billed monthly, based on the amount of sensor systems deployed).\nIaas. The client should use his own hardware or purchase a device from us. Charge the client based on the amount of data to be transmitted."
    },
    {
        "unique_id": 30,
        "sector": "Aquaculture",
        "service": "Broadband Connectivity (Access)",
        "user_type": "Public Authority",
        "label1_title": "Partnerships/Suppliers/Collaborators",
        "label1_content": "State, Municipality, Federal Government, Ministries, European Union, Universities, Development Agencies, Private sector (e.g. MNOs, ISPs), Financial Institutions.",
        "label2_title": "Other Stakeholders",
        "label2_content": "Society, NGOs and Chamber of Tradesmen",
        "label3_title": "Resources",
        "label3_content": "Financial\nHuman\nMaterial\nEquipment\nIT\nLegislation",
        "label4_title": "Capabilities",
        "label4_content": "Systemic, strategic and entrepreneurial vision\nMultidisciplinary knowledge\nAnalytical and forecast capacity\nAbility to think, develop studies and plans",
        "label5_title": "Proccesses",
        "label5_content": "Strategic Process Management.\nElaboration of development plans.\nLocal and regional potential mapping.\nIdentification of economic potential.\nManagement of environmental impacts.\nElaboration of policies,plans and programs.\nAgreements Management.\nPartnerships management and cooperation agreements.",
        "label6_title": "Products",
        "label6_content": "Laws and regulatory frameworks for: Internet connectivity.",
        "label7_title": "Customers",
        "label7_content": "Society\nMunicipalities\nInternet Service Providers (ISPs)\nUniversities\nThird sector",
        "label9_title": "Outcomes",
        "label9_content": "Universal and democratic access to digital connectivity.\nIncrease of per capita income.\nIncrease of the State PID.\nJob creation.\nHigher HDI.\nEconomic and societal strengthening of municipalities and strategic regions of the state.\nEnhance citizens� digital literacy.\nReduction of social inequality.\nReduction of desertification.",
        "label10_title": "Public Value",
        "label10_content": "Provide universal access to High-Speed Internet services by means of trustable and credible service, generating a fair and prosperous state that guarantees the well-being of the population as well as the financial sustainability of SMEs."
    },
    {
        "unique_id": 31,
        "sector": "Education",
        "service": "Distance Learning",
        "user_type": "End-User",
        "label1_title": "Key Partners",
        "label1_content": "Internet service providers ISP\nTechnology Providers\nApplication providers (data analytics)\nFinancial providers (e.g. banks)\nGovernment and Regulatory bodies",
        "label2_title": "Key Activities",
        "label2_content": "Provision of various services and information to citizens, businesses, and other stakeholders\nAccess to learning material\nAcess to e-learning platforms",
        "label3_title": "Value Propositions",
        "label3_content": "Accesibility\nSyncronous and asychronous education\nEnhanced communication\nQ&A in real time\n24/7 Availability\nCost-effectiveness\nCustomisation\nGlobal Reach\nContinuous learning",
        "label4_title": "Customer Relationships",
        "label4_content": "Personal assistance\nCo-creation (tailor made)",
        "label5_title": "Customer Segments",
        "label5_content": "Public Authorities \nSMEs \nCitizens",
        "label6_title": "Channels",
        "label6_content": "Social media channels\nWebsite",
        "label7_title": "Key Resources",
        "label7_content": "Physical resources (cameras, microphones))\nHuman resources (software developers, technical support)",
        "label9_title": "Cost Structure",
        "label9_content": "Fixed costs for Infrastructure (Owned PC/Laptop/Tablet) and maintenance\nOperational costs",
        "label10_title": "Revenue Streams",
        "label10_content": "Reduced cost of travel expenses\nReduced time of travel \nIncreased Productivity"
    },
    {
        "unique_id": 32,
        "sector": "Education",
        "service": "Distance Learning",
        "user_type": "Internet Service Provider (ISP)",
        "label1_title": "Key Partners",
        "label1_content": "MNOs\nTechnology and Data providers \nNGOs\nAcademic Institutions\nSystem Integrators\nFinancial providers (e.g. banks)\nGovernment and Regulatory bodies",
        "label2_title": "Key Activities",
        "label2_content": "Local Manufacturing & Sourcing\nAlliances & Partnerships formation\nSales & Promotion",
        "label3_title": "Value Propositions",
        "label3_content": "Low cost, Low power & Low maintenance (3L)\nHigh QOS & Coverage\nEnvironment-friendly green-solution\nEnd-to-end IP platform\nCloud server for locllized_content\nHigh Data Rates",
        "label4_title": "Customer Relationships",
        "label4_content": "24/7 Customer Care Centre\nMicro Operators/VLEs\nRegional Sales Executives",
        "label5_title": "Customer Segments",
        "label5_content": "Local community\nSMEs\nPublic authorities",
        "label6_title": "Channels",
        "label6_content": "Micro Operators/ VLEs\nCo-branded outlets\nPartnership Telcos/ISPs\nDirect Sales\nWebsite\nSocial media channels",
        "label7_title": "Key Resources",
        "label7_content": "Physical resources (technologies, machineries)\nFinancial resources (Investors and Venture Funding)\nPassionate Management",
        "label9_title": "Cost Structure",
        "label9_content": "Manufacturing & Sourcing\nWi-Fi hotspot integration, funding, deployment, operation & maintenance\nEmployees & office\nR&D & Training\nSales & Marketing",
        "label10_title": "Revenue Streams",
        "label10_content": ""
    },
    {
        "unique_id": 33,
        "sector": "Education",
        "service": "Distance Learning",
        "user_type": "Public Authority",
        "label1_title": "Partnerships/Suppliers/Collaborators",
        "label1_content": "State, Municipality, Federal Government, Ministries, European Union, Universities, Development Agencies, Private sector (e.g. MNOs, ISPs), Financial Institutions.",
        "label2_title": "Other Stakeholders",
        "label2_content": "Society, NGOs, Chamber of Tradesmen",
        "label3_title": "Resources",
        "label3_content": "Financial\nHuman\nMaterial\nEquipment\nIT\nLegislation",
        "label4_title": "Capabilities",
        "label4_content": "Systemic, strategic and entrepreneurial vision\nMultidisciplinary knowledge\nAnalytical and forecast capacity\nAbility to think, develop studies and plans",
        "label5_title": "Proccesses",
        "label5_content": "Strategic Process Management\nElaboration of development plans\nLocal and regional potential mapping\nIdentification of economic potential\nManagement of environmental impacts\nElaboration of policies,plans and programs\nAgreements Management\nPartnerships management and cooperation agreements",
        "label6_title": "Products",
        "label6_content": "Laws and regulatory frameworks for: Internet connectivity\nLaws and regulatory frameworks for: Universities, Institutes etc (state, regional and sectoral levels)",
        "label7_title": "Customers",
        "label7_content": "Society\nMunicipalities\nBusinesses\nUniversities\nThird sector",
        "label9_title": "Outcomes",
        "label9_content": "Universal and democratic access to digital connectivity\nIncrease of per capita income\nIncrease of the State PID\nJob creation\nHigher HDI\nEconomic and societal strengthening of municipalities and strategic regions of the state\nSocial inclusiveness\nEconomic Sustainability\nEnhance citizens� digital literacy\nReduction of social inequality\nReduction of desertification\nEnhance citizens� health and well-being\nEnhance citizens� continuous learning",
        "label10_title": "Public Value",
        "label10_content": "Provide universal access to High-Speed Internet services by means of trustable and credible service, generating a fair and prosperous state that guarantees the well-being of the population as well as the financial sustainability of SMEs."
    },
    {
        "unique_id": 34,
        "sector": "Education",
        "service": "High Data Rate Services",
        "user_type": "End-User",
        "label1_title": "Key Partners",
        "label1_content": "Internet service providers ISP\nTechnology Providers\nApplication providers (data analytics)\nFinancial providers (e.g. banks)\nGovernment and Regulatory bodies",
        "label2_title": "Key Activities",
        "label2_content": "Provision of various services and information to citizens, businesses, and other stakeholders\n\"Access to e-learning platforms (\nsynchronous and asynchronous distance learning)",
        "label3_title": "Value Propositions",
        "label3_content": "Accesibility\nSyncronous and asychronous education\nEnhanced communication\nQ&A in real time\n24/7 Availability\nCost-effectiveness\nCustomisation\nGlobal Reach\nContinuous learning",
        "label4_title": "Customer Relationships",
        "label4_content": "Personal assistance\nCo-creation (tailor made)",
        "label5_title": "Customer Segments",
        "label5_content": "Public Authorities \nSMEs \nCitizens",
        "label6_title": "Channels",
        "label6_content": "Social media channels\nWebsite",
        "label7_title": "Key Resources",
        "label7_content": "Physical resources (cameras, microphones etc.)\nHuman resources (software developers, technical support)",
        "label9_title": "Cost Structure",
        "label9_content": "Fixed costs for Infrastructure (Owned PCs/Laptops/Tablets) and maintenance\nOperational costs",
        "label10_title": "Revenue Streams",
        "label10_content": "Reduced cost of travel expenses (for rural citizen & educators)\nReduced time of travel (for rural citizen & educators)\nIncreased Productivity for businesses\ne-Learning platform revenues for businesses"
    },
    {
        "unique_id": 35,
        "sector": "Education",
        "service": "High Data Rate Services",
        "user_type": "Internet Service Provider (ISP)",
        "label1_title": "Key Partners",
        "label1_content": "MNOs\nTechnology and Data providers \nNGOs\nAcademic Institutions\nSystem Integrators\nFinancial providers (e.g. banks)\nGovernment and Regulatory bodies",
        "label2_title": "Key Activities",
        "label2_content": "Local Manufacturing & Sourcing\nAlliances & Partnerships formation\nSales & Promotion",
        "label3_title": "Value Propositions",
        "label3_content": "Low cost, Low power & Low maintenance (3L)\nHigh QOS & Coverage\nEnvironment-friendly green-solution\nEnd-to-end IP platform\nCloud server for locllized_content\nHigh Data Rates",
        "label4_title": "Customer Relationships",
        "label4_content": "24/7 Customer Care Centre\nMicro Operators/VLEs\nRegional Sales Executives",
        "label5_title": "Customer Segments",
        "label5_content": "Local community\nSMEs\nPublic authorities",
        "label6_title": "Channels",
        "label6_content": "Micro Operators/ VLEs\nCo-branded outlets\nPartnership Telcos/ISPs\nDirect Sales\nWebsite\nSocial media channels",
        "label7_title": "Key Resources",
        "label7_content": "Physical resources (technologies, machineries)\nFinancial resources (Investors and Venture Funding)\nPassionate Management",
        "label9_title": "Cost Structure",
        "label9_content": "Manufacturing & Sourcing\nWi-Fi hotspot integration, funding, deployment, operation & maintenance\nEmployees & office\nR&D & Training\nSales & Marketing",
        "label10_title": "Revenue Streams",
        "label10_content": "Subscription fee\nNetwork slice rent (technology, software, mobile and broadband network providers)\nGovt. Anchor usage payment receipts\nLocal municipality or MNOs (Mobile network Operators) funding"
    },
    {
        "unique_id": 36,
        "sector": "Education",
        "service": "High Data Rate Services",
        "user_type": "Public Authority",
        "label1_title": "Partnerships/Suppliers/Collaborators",
        "label1_content": "State, Municipality, Federal Government, Ministries, European Union, Universities, Public & Private education sector, Development Agencies, Private sector (e.g. MNOs, ISPs), Financial Institutions.",
        "label2_title": "Other Stakeholders",
        "label2_content": "Society, NGOs, Chamber of Tradesmen",
        "label3_title": "Resources",
        "label3_content": "Financial\nHuman\nMaterial\nEquipment\nIT\nLegislation",
        "label4_title": "Capabilities",
        "label4_content": "Systemic, strategic and entrepreneurial vision\nMultidisciplinary knowledge\nAnalytical and forecast capacity\nAbility to think, develop studies and plans",
        "label5_title": "Proccesses",
        "label5_content": "Strategic Process Management\nElaboration of development plans\nLocal and regional potential mapping\nIdentification of economic potential\nManagement of environmental impacts\nElaboration of policies,plans and programs\nAgreements Management\nPartnerships management and cooperation agreements",
        "label6_title": "Products",
        "label6_content": "Laws and regulatory frameworks for: Internet connectivity\nLaws and regulatory frameworks for: Universities, Institutes etc (state, regional and sectoral levels)",
        "label7_title": "Customers",
        "label7_content": "Society\nMunicipalities\nPublic & Private education sector\nUniversities\nThird sector",
        "label9_title": "Outcomes",
        "label9_content": "Universal and democratic access to digital connectivity\nIncrease of per capita income\nIncrease of the State PID\nJob creation\nHigher HDI\nEconomic and societal strengthening of municipalities and strategic regions of the state\nSocial inclusiveness\nEconomic Sustainability\nEnhance citizens� digital literacy\nReduction of social inequality\nReduction of desertification\nEnhance citizens� health and well-being\nEnhance citizens� continuous learning",
        "label10_title": "Public Value",
        "label10_content": "Provide universal access to High-Speed Internet services by means of trustable and credible service, generating a fair and prosperous state that guarantees the well-being of the population as well as the financial sustainability of SMEs."
    },
    {
        "unique_id": 37,
        "sector": "Education",
        "service": "Broadband Connectivity (Access)",
        "user_type": "End-User",
        "label1_title": "Key Partners",
        "label1_content": "Internet service providers ISP\nTechnology and Data providers \nApplication providers (data analytics)\nFinancial providers (e.g. banks)\nGovernment and Regulatory bodies",
        "label2_title": "Key Activities",
        "label2_content": "Negotiations and Agreements: Establish contracts with Network Operetors, ISPs and other stakeholders.\nInstall and maintain connectivity infrastructure such as cell towers, real estate and fiber-optic networks in order to meet the connectivity needs of local stakeholders.",
        "label3_title": "Value Propositions",
        "label3_content": "Expand market reach and create new opportunities for local businesses, like agriculture, tourism, eHealth and other SMEs in the community, and individuals.\nHelp optimise operations & reduce costs.",
        "label4_title": "Customer Relationships",
        "label4_content": "Personal assistance.\nCo-creation (tailor made).",
        "label5_title": "Customer Segments",
        "label5_content": "Local businesses\nPublic authorities\nCitizens",
        "label6_title": "Channels",
        "label6_content": "Direct Sales: Engage with local SMEs and citizens.\nPartnerships: Collaborate with government agencies, NGOs, and existing infrastructure providers.",
        "label7_title": "Key Resources",
        "label7_content": "Human resources (e.g. technicians,engineers, IT professionals).\nPhysical resources (e.g. cell towers, real estate and fiber-optic networks etc).",
        "label9_title": "Cost Structure",
        "label9_content": "Infrastructure Costs: Towers, cables, and maintenance.\nOperational Costs: Staff salaries, energy, and ongoing maintenance.\nLegal and Regulatory Compliance Costs.",
        "label10_title": "Revenue Streams",
        "label10_content": "Increased profitability via increased production.\nIncreased profitability via cost reductions.\nSelling of products & services (B2B/B2C)."
    },
    {
        "unique_id": 38,
        "sector": "Education",
        "service": "Broadband Connectivity (Access)",
        "user_type": "Internet Service Provider (ISP)",
        "label1_title": "Key Partners",
        "label1_content": "MNOs\nTechnology and Data providers \nNGOs\nAcademic Institutions\nSystem Integrators\nFinancial providers (e.g. banks)\nGovernment and Regulatory bodies\nDrone Manufacturers\nApplication developers (data analytics)",
        "label2_title": "Key Activities",
        "label2_content": "Local Manufacturing & Sourcing\nAlliances & Partnerships formation\nSales & Promotion",
        "label3_title": "Value Propositions",
        "label3_content": "Low cost, Low power & Low maintenance (3L)\nHigh QOS & Coverage\nEnvironment-friendly green-solution\nEnd-to-end IP platform\nCloud server for locllized_content\nHigh Data Rates",
        "label4_title": "Customer Relationships",
        "label4_content": "24/7 Customer Care Centre\nMicro Operators/VLEs\nRegional Sales Executives",
        "label5_title": "Customer Segments",
        "label5_content": "Internet Service Providers (ISPs)\nLocal Communities\nSMEs\nPublic authorities\nNGOs\nResearch & Academia\nCommunity Retail Market",
        "label6_title": "Channels",
        "label6_content": "Micro Operators/ VLEs\nCo-branded outlets\nPartnership Telcos/ISPs\nDirect Sales\nWebsite\nSocial media channels",
        "label7_title": "Key Resources",
        "label7_content": "Physical resources (technologies, machineries)\nFinancial resources (Investors and Venture Funding)\nPassionate Management",
        "label9_title": "Cost Structure",
        "label9_content": "Manufacturing & Sourcing\nWi-Fi hotspot integration, funding, deployment, operation & maintenance\nEmployees & office\nR&D & Training\nSales & Marketing",
        "label10_title": "Revenue Streams",
        "label10_content": "Haas. Rent the set of hardware including deployment of the network (The client would be billed monthly, based on the amount of sensor systems deployed).\nIaas. The client should use his own hardware or purchase a device from us. Charge the client based on the amount of data to be transmitted."
    },
    {
        "unique_id": 39,
        "sector": "Education",
        "service": "Broadband Connectivity (Access)",
        "user_type": "Public Authority",
        "label1_title": "Partnerships/Suppliers/Collaborators",
        "label1_content": "State, Municipality, Federal Government, Ministries, European Union, Universities, Development Agencies, Private sector (e.g. MNOs, ISPs), Financial Institutions.",
        "label2_title": "Other Stakeholders",
        "label2_content": "Society, NGOs and Chamber of Tradesmen",
        "label3_title": "Resources",
        "label3_content": "Financial\nHuman\nMaterial\nEquipment\nIT\nLegislation",
        "label4_title": "Capabilities",
        "label4_content": "Systemic, strategic and entrepreneurial vision\nMultidisciplinary knowledge\nAnalytical and forecast capacity\nAbility to think, develop studies and plans",
        "label5_title": "Proccesses",
        "label5_content": "Strategic Process Management.\nElaboration of development plans.\nLocal and regional potential mapping.\nIdentification of economic potential.\nManagement of environmental impacts.\nElaboration of policies,plans and programs.\nAgreements Management.\nPartnerships management and cooperation agreements.",
        "label6_title": "Products",
        "label6_content": "Laws and regulatory frameworks for: Internet connectivity.",
        "label7_title": "Customers",
        "label7_content": "Society\nMunicipalities\nInternet Service Providers (ISPs)\nUniversities\nThird sector",
        "label9_title": "Outcomes",
        "label9_content": "Universal and democratic access to digital connectivity.\nIncrease of per capita income.\nIncrease of the State PID.\nJob creation.\nHigher HDI.\nEconomic and societal strengthening of municipalities and strategic regions of the state.\nEnhance citizens� digital literacy.\nReduction of social inequality.\nReduction of desertification.",
        "label10_title": "Public Value",
        "label10_content": "Provide universal access to High-Speed Internet services by means of trustable and credible service, generating a fair and prosperous state that guarantees the well-being of the population as well as the financial sustainability of SMEs."
    },
    {
        "unique_id": 40,
        "sector": "eGovernance",
        "service": "Government eServices",
        "user_type": "End-User",
        "label1_title": "Key Partners",
        "label1_content": "Internet service providers ISP\nTechnology Providers\nApplication providers (data analytics)\nFinancial providers (e.g. banks)\nGovernment and Regulatory bodies",
        "label2_title": "Key Activities",
        "label2_content": "Provision of various services and information to citizens, businesses, and other stakeholders\nenhanced government transparency, efficiency, and accessibility",
        "label3_title": "Value Propositions",
        "label3_content": "Reduced cost, time and effort for citizens, businesses and relevant stakeholders\nIncrease e-literacy and digital skills\nAccess to learning material\nAcess to digital platforms\nEnhanced communication",
        "label4_title": "Customer Relationships",
        "label4_content": "Personal assistance\nCo-creation (tailor made)",
        "label5_title": "Customer Segments",
        "label5_content": "Public Authorities \nSMEs \ncitizens",
        "label6_title": "Channels",
        "label6_content": "Social media channels\nWebsite",
        "label7_title": "Key Resources",
        "label7_content": "Physical resources (cameras, microphones))\nHuman resources (software developers, technical support)",
        "label9_title": "Cost Structure",
        "label9_content": "Fixed costs for Infrastructure (Owned/Rent Robots) and maintenance\nOperational costs",
        "label10_title": "Revenue Streams",
        "label10_content": "Reduced cost of travel expenses\nReduced time of travel to local Authorities\nIncreased Productivity"
    },
    {
        "unique_id": 41,
        "sector": "eGovernance",
        "service": "Government eServices",
        "user_type": "Internet Service Provider (ISP)",
        "label1_title": "Key Partners",
        "label1_content": "MNOs\nTechnology and Data providers \nNGOs\nAcademic Institutions\nSystem Integrators\nFinancial providers (e.g. banks)\nGovernment and Regulatory bodies",
        "label2_title": "Key Activities",
        "label2_content": "Local Manufacturing & Sourcing\nAlliances & Partnerships formation\nSales & Promotion",
        "label3_title": "Value Propositions",
        "label3_content": "Low cost, Low power & Low maintenance (3L)\nHigh QOS & Coverage\nEnvironment-friendly green-solution\nEnd-to-end IP platform\nCloud server for locllized_content\nHigh Data Rates",
        "label4_title": "Customer Relationships",
        "label4_content": "24/7 Customer Care Centre\nMicro Operators/VLEs\nRegional Sales Executives",
        "label5_title": "Customer Segments",
        "label5_content": "Local community\nSMEs\nPublic authorities\nLocal Retail Market",
        "label6_title": "Channels",
        "label6_content": "Micro Operators/ VLEs\nCo-branded outlets\nPartnership Telcos/ISPs\nDirect Sales\nWebsite\nSocial media channels",
        "label7_title": "Key Resources",
        "label7_content": "Physical resources (technologies, machineries)\nFinancial resources (Investors and Venture Funding)\nPassionate Management",
        "label9_title": "Cost Structure",
        "label9_content": "Manufacturing & Sourcing\nWi-Fi hotspot integration, funding, deployment, operation & maintenance\nEmployees & office\nR&D & Training\nSales & Marketing",
        "label10_title": "Revenue Streams",
        "label10_content": ""
    },
    {
        "unique_id": 42,
        "sector": "eGovernance",
        "service": "Government eServices",
        "user_type": "Public Authority",
        "label1_title": "Partnerships/Suppliers/Collaborators",
        "label1_content": "State, Municipality, Federal Government, Ministries, European Union, Universities, Development Agencies, Private sector (e.g. MNOs, ISPs), Financial Institutions.",
        "label2_title": "Other Stakeholders",
        "label2_content": "Society, NGOs, Chamber of Tradesmen",
        "label3_title": "Resources",
        "label3_content": "Financial\nHuman\nMaterial\nEquipment\nIT\nLegislation",
        "label4_title": "Capabilities",
        "label4_content": "Systemic, strategic and entrepreneurial vision\nMultidisciplinary knowledge\nAnalytical and forecast capacity\nAbility to think, develop studies and plans",
        "label5_title": "Proccesses",
        "label5_content": "Strategic Process Management\nElaboration of development plans\nLocal and regional potential mapping\nIdentification of economic potential\nManagement of environmental impacts\nElaboration of policies,plans and programs\nAgreements Management\nPartnerships management and cooperation agreements",
        "label6_title": "Products",
        "label6_content": "Laws and regulatory frameworks for: Internet connectivity\nLaws and regulatory frameworks for: Public Authorities documents/communication/transactions etc (state, regional and sectoral levels)",
        "label7_title": "Customers",
        "label7_content": "Society\nMunicipalities\nBusinesses\nUniversities\nThird sector",
        "label9_title": "Outcomes",
        "label9_content": "Universal and democratic access to digital connectivity\nIncrease of per capita income\nIncrease of the State PID\nJob creation\nHigher HDI\nEconomic and societal strengthening of municipalities and strategic regions of the state\nSocial inclusiveness\nEconomic Sustainability\nEnhance citizens� digital literacy\nReduction of social inequality\nReduction of desertification\nEnhance citizens� health and well-being",
        "label10_title": "Public Value",
        "label10_content": "Provide universal access to High-Speed Internet services by means of trustable and credible service, generating a fair and prosperous state that guarantees the well-being of the population as well as the financial sustainability of SMEs."
    },
    {
        "unique_id": 43,
        "sector": "eGovernance",
        "service": "Land Use / Land Cover",
        "user_type": "End-User",
        "label1_title": "Key Partners",
        "label1_content": "Internet service providers ISP\nTechnology Providers\nApplication providers (data analytics)\nFinancial providers (e.g. banks)\nGovernment and Regulatory bodies",
        "label2_title": "Key Activities",
        "label2_content": "Provision of various services and information to citizens, businesses, NGOs, and other stakeholders\nEnhanced government transparency, efficiency, and accessibility",
        "label3_title": "Value Propositions",
        "label3_content": "Reduced cost, time and effort for citizens, businesses and relevant stakeholders\nIncrease e-literacy and digital skills\nAccess to learning material\nAccess to digital platforms\nEnhanced communication",
        "label4_title": "Customer Relationships",
        "label4_content": "Personal assistance\nCo-creation (tailor made)",
        "label5_title": "Customer Segments",
        "label5_content": "Public Authorities \nSMEs \nCitizens",
        "label6_title": "Channels",
        "label6_content": "Social media channels\nWebsite",
        "label7_title": "Key Resources",
        "label7_content": "Physical resources (cameras, microphones etc.)\nHuman resources (software developers, technical support)",
        "label9_title": "Cost Structure",
        "label9_content": "Fixed costs for Infrastructure (Owned PCs/Laptops/Tablets) and maintenance\nOperational costs",
        "label10_title": "Revenue Streams",
        "label10_content": "Reduced time of travel to local authorities\nIncreased productivity"
    },
    {
        "unique_id": 44,
        "sector": "eGovernance",
        "service": "Land Use / Land Cover",
        "user_type": "Internet Service Provider (ISP)",
        "label1_title": "Key Partners",
        "label1_content": "MNOs\nTechnology and Data providers \nNGOs\nAcademic Institutions\nSystem Integrators\nFinancial providers (e.g. banks)\nGovernment and Regulatory bodies",
        "label2_title": "Key Activities",
        "label2_content": "Local Manufacturing & Sourcing\nAlliances & Partnerships formation\nSales & Promotion",
        "label3_title": "Value Propositions",
        "label3_content": "Low cost, Low power & Low maintenance (3L)\nHigh QOS & Coverage\nEnvironment-friendly green-solution\nEnd-to-end IP platform\nCloud server for locllized_content\nHigh Data Rates",
        "label4_title": "Customer Relationships",
        "label4_content": "24/7 Customer Care Centre\nMicro Operators/VLEs\nRegional Sales Executives",
        "label5_title": "Customer Segments",
        "label5_content": "Local community\nSMEs\nPublic authorities\nLocal Retail Market",
        "label6_title": "Channels",
        "label6_content": "Micro Operators/ VLEs\nCo-branded outlets\nPartnership Telcos/ISPs\nDirect Sales\nWebsite\nSocial media channels",
        "label7_title": "Key Resources",
        "label7_content": "Physical resources (technologies, machineries)\nFinancial resources (Investors and Venture Funding)\nPassionate Management",
        "label9_title": "Cost Structure",
        "label9_content": "Manufacturing & Sourcing\nWi-Fi hotspot integration, funding, deployment, operation & maintenance\nEmployees & office\nR&D & Training\nSales & Marketing",
        "label10_title": "Revenue Streams",
        "label10_content": "Subscription fee\nNetwork slice rent (technology, software, mobile and broadband network providers)\nGovt. Anchor usage payment receipts\nLocal municipality or MNOs (Mobile network Operators) funding"
    },
    {
        "unique_id": 45,
        "sector": "eGovernance",
        "service": "Land Use / Land Cover",
        "user_type": "Public Authority",
        "label1_title": "Partnerships/Suppliers/Collaborators",
        "label1_content": "State, Municipality, Federal Government, Ministries, European Union, Universities, Development Agencies, Private sector (e.g. MNOs, ISPs), Financial Institutions.",
        "label2_title": "Other Stakeholders",
        "label2_content": "Society, NGOs, Chamber of Tradesmen",
        "label3_title": "Resources",
        "label3_content": "Financial\nHuman\nMaterial\nEquipment\nIT\nLegislation",
        "label4_title": "Capabilities",
        "label4_content": "Systemic, strategic and entrepreneurial vision\nMultidisciplinary knowledge\nAnalytical and forecast capacity\nAbility to think, develop studies and plans",
        "label5_title": "Proccesses",
        "label5_content": "Strategic Process Management\nElaboration of development plans\nLocal and regional potential mapping\nIdentification of economic potential\nManagement of environmental impacts\nElaboration of policies,plans and programs\nAgreements Management\nPartnerships management and cooperation agreements",
        "label6_title": "Products",
        "label6_content": "Laws and regulatory frameworks for: Internet connectivity\nLaws and regulatory frameworks for: Public Authorities Documents/communication/transactions etc (state, regional and sectoral levels)",
        "label7_title": "Customers",
        "label7_content": "Society\nMunicipalities\nBusinesses\nUniversities\nThird sector",
        "label9_title": "Outcomes",
        "label9_content": "Universal and democratic access to digital connectivity\nIncrease of per capita income\nIncrease of the State PID\nJob creation\nHigher HDI\nEconomic and societal strengthening of municipalities and strategic regions of the state\nSocial inclusiveness\nEconomic Sustainability\nEnhance citizens� digital literacy\nReduction of social inequality\nReduction of desertification\nEnhance citizens and well-being",
        "label10_title": "Public Value",
        "label10_content": "Provide universal access to High-Speed Internet services by means of trustable and credible service, generating a fair and prosperous state that guarantees the well-being of the population as well as the financial sustainability of SMEs."
    },
    {
        "unique_id": 46,
        "sector": "eGovernance",
        "service": "Broadband Connectivity (Access)",
        "user_type": "End-User",
        "label1_title": "Key Partners",
        "label1_content": "Internet service providers ISP\nTechnology and Data providers \nApplication providers (data analytics)\nFinancial providers (e.g. banks)\nGovernment and Regulatory bodies",
        "label2_title": "Key Activities",
        "label2_content": "Negotiations and Agreements: Establish contracts with Network Operetors, ISPs and other stakeholders.\nInstall and maintain connectivity infrastructure such as cell towers, real estate and fiber-optic networks in order to meet the connectivity needs of local stakeholders.",
        "label3_title": "Value Propositions",
        "label3_content": "Expand market reach and create new opportunities for local businesses, like agriculture, tourism, eHealth and other SMEs in the community, and individuals.\nHelp optimise operations & reduce costs.",
        "label4_title": "Customer Relationships",
        "label4_content": "Personal assistance.\nCo-creation (tailor made).",
        "label5_title": "Customer Segments",
        "label5_content": "Local businesses\nPublic authorities\nCitizens",
        "label6_title": "Channels",
        "label6_content": "Direct Sales: Engage with local SMEs and citizens.\nPartnerships: Collaborate with government agencies, NGOs, and existing infrastructure providers.",
        "label7_title": "Key Resources",
        "label7_content": "Human resources (e.g. technicians,engineers, IT professionals).\nPhysical resources (e.g. cell towers, real estate and fiber-optic networks etc).",
        "label9_title": "Cost Structure",
        "label9_content": "Infrastructure Costs: Towers, cables, and maintenance.\nOperational Costs: Staff salaries, energy, and ongoing maintenance.\nLegal and Regulatory Compliance Costs.",
        "label10_title": "Revenue Streams",
        "label10_content": "Increased profitability via increased production.\nIncreased profitability via cost reductions.\nSelling of products & services (B2B/B2C)."
    },
    {
        "unique_id": 47,
        "sector": "eGovernance",
        "service": "Broadband Connectivity (Access)",
        "user_type": "Internet Service Provider (ISP)",
        "label1_title": "Key Partners",
        "label1_content": "MNOs\nTechnology and Data providers \nNGOs\nAcademic Institutions\nSystem Integrators\nFinancial providers (e.g. banks)\nGovernment and Regulatory bodies\nDrone Manufacturers\nApplication developers (data analytics)",
        "label2_title": "Key Activities",
        "label2_content": "Local Manufacturing & Sourcing\nAlliances & Partnerships formation\nSales & Promotion",
        "label3_title": "Value Propositions",
        "label3_content": "Low cost, Low power & Low maintenance (3L)\nHigh QOS & Coverage\nEnvironment-friendly green-solution\nEnd-to-end IP platform\nCloud server for locllized_content\nHigh Data Rates",
        "label4_title": "Customer Relationships",
        "label4_content": "24/7 Customer Care Centre\nMicro Operators/VLEs\nRegional Sales Executives",
        "label5_title": "Customer Segments",
        "label5_content": "Internet Service Providers (ISPs)\nLocal Communities\nSMEs\nPublic authorities\nNGOs\nResearch & Academia\nCommunity Retail Market",
        "label6_title": "Channels",
        "label6_content": "Micro Operators/ VLEs\nCo-branded outlets\nPartnership Telcos/ISPs\nDirect Sales\nWebsite\nSocial media channels",
        "label7_title": "Key Resources",
        "label7_content": "Physical resources (technologies, machineries)\nFinancial resources (Investors and Venture Funding)\nPassionate Management",
        "label9_title": "Cost Structure",
        "label9_content": "Manufacturing & Sourcing\nWi-Fi hotspot integration, funding, deployment, operation & maintenance\nEmployees & office\nR&D & Training\nSales & Marketing",
        "label10_title": "Revenue Streams",
        "label10_content": "Haas. Rent the set of hardware including deployment of the network (The client would be billed monthly, based on the amount of sensor systems deployed).\nIaas. The client should use his own hardware or purchase a device from us. Charge the client based on the amount of data to be transmitted."
    },
    {
        "unique_id": 48,
        "sector": "eGovernance",
        "service": "Broadband Connectivity (Access)",
        "user_type": "Public Authority",
        "label1_title": "Partnerships/Suppliers/Collaborators",
        "label1_content": "State, Municipality, Federal Government, Ministries, European Union, Universities, Development Agencies, Private sector (e.g. MNOs, ISPs), Financial Institutions.",
        "label2_title": "Other Stakeholders",
        "label2_content": "Society, NGOs and Chamber of Tradesmen",
        "label3_title": "Resources",
        "label3_content": "Financial\nHuman\nMaterial\nEquipment\nIT\nLegislation",
        "label4_title": "Capabilities",
        "label4_content": "Systemic, strategic and entrepreneurial vision\nMultidisciplinary knowledge\nAnalytical and forecast capacity\nAbility to think, develop studies and plans",
        "label5_title": "Proccesses",
        "label5_content": "Strategic Process Management.\nElaboration of development plans.\nLocal and regional potential mapping.\nIdentification of economic potential.\nManagement of environmental impacts.\nElaboration of policies,plans and programs.\nAgreements Management.\nPartnerships management and cooperation agreements.",
        "label6_title": "Products",
        "label6_content": "Laws and regulatory frameworks for: Internet connectivity.",
        "label7_title": "Customers",
        "label7_content": "Society\nMunicipalities\nInternet Service Providers (ISPs)\nUniversities\nThird sector",
        "label9_title": "Outcomes",
        "label9_content": "Universal and democratic access to digital connectivity.\nIncrease of per capita income.\nIncrease of the State PID.\nJob creation.\nHigher HDI.\nEconomic and societal strengthening of municipalities and strategic regions of the state.\nEnhance citizens� digital literacy.\nReduction of social inequality.\nReduction of desertification.",
        "label10_title": "Public Value",
        "label10_content": "Provide universal access to High-Speed Internet services by means of trustable and credible service, generating a fair and prosperous state that guarantees the well-being of the population as well as the financial sustainability of SMEs."
    },
    {
        "unique_id": 49,
        "sector": "eHealth",
        "service": "Health Monitoring",
        "user_type": "End-User",
        "label1_title": "Key Partners",
        "label1_content": "Internet service providers ISP\nTechnology Providers\nApplication providers (data analytics)\nFinancial providers (e.g. banks)\nGovernment and Regulatory bodies",
        "label2_title": "Key Activities",
        "label2_content": "Provide health and wellbeing guidance & interventions for people living in rural areas",
        "label3_title": "Value Propositions",
        "label3_content": "Enhance well-being of elderly people\nSupport the social interaction of people",
        "label4_title": "Customer Relationships",
        "label4_content": "Personal assistance\nCo-creation (tailor made)",
        "label5_title": "Customer Segments",
        "label5_content": "Local community with focus on elderly people\nSMEs (elderly houses)\nPublic Authorities",
        "label6_title": "Channels",
        "label6_content": "Social media channels\nWebsite",
        "label7_title": "Key Resources",
        "label7_content": "Physical resources (cameras, microphones))\nIntellectual Resource (e.g. personal information, GDPR))\nHuman resources (software developers, technical support)",
        "label9_title": "Cost Structure",
        "label9_content": "Fixed costs for Infrastructure (Owned/Rent Robots) and maintenance\nOperational costs",
        "label10_title": "Revenue Streams",
        "label10_content": "Monetize the added value of robot connection in elderly homes"
    },
    {
        "unique_id": 50,
        "sector": "eHealth",
        "service": "Health Monitoring",
        "user_type": "Internet Service Provider (ISP)",
        "label1_title": "Key Partners",
        "label1_content": "MNOs\nTechnology and Data providers \nNGOs\nAcademic Institutions\nSystem Integrators\nFinancial providers (e.g. banks)\nGovernment and Regulatory bodies",
        "label2_title": "Key Activities",
        "label2_content": "Local Manufacturing & Sourcing\nAlliances & Partnerships formation\nSales & Promotion",
        "label3_title": "Value Propositions",
        "label3_content": "Low cost, Low power & Low maintenance (3L)\nHigh QOS & Coverage\nEnvironment-friendly green-solution\nEnd-to-end IP platform\nCloud server for locllized_content\nHigh Data Rates",
        "label4_title": "Customer Relationships",
        "label4_content": "24/7 Customer Care Centre\nMicro Operators/VLEs\nRegional Sales Executives",
        "label5_title": "Customer Segments",
        "label5_content": "Local community\nSMEs: elderly housew\nPublic authorities",
        "label6_title": "Channels",
        "label6_content": "Micro Operators/ VLEs\nCo-branded outlets\nPartnership Telcos/ISPs\nDirect Sales\nWebsite\nSocial media channels",
        "label7_title": "Key Resources",
        "label7_content": "Physical resources (technologies, machineries)\nFinancial resources (Investors and Venture Funding)\nPassionate Management",
        "label9_title": "Cost Structure",
        "label9_content": "Manufacturing & Sourcing\nWi-Fi hotspot integration, funding, deployment, operation & maintenance\nEmployees & office\nR&D & Training\nSales & Marketing",
        "label10_title": "Revenue Streams",
        "label10_content": "Subscription fee\nNetwork slice rent (technology, software, mobile and broadband network providers)\nGovt. Anchor usage payment receipts\nLocal municipality or MNOs (Mobile network Operators) funding"
    },
    {
        "unique_id": 51,
        "sector": "eHealth",
        "service": "Health Monitoring",
        "user_type": "Public Authority",
        "label1_title": "Partnerships/Suppliers/Collaborators",
        "label1_content": "State, Municipality, Federal Government, Ministries, European Union, Universities, Development Agencies, Private sector (e.g. MNOs, ISPs), Financial Institutions.",
        "label2_title": "Other Stakeholders",
        "label2_content": "Society, NGOs, Chamber of Tradesmen, Medical Organisations, public elderly house",
        "label3_title": "Resources",
        "label3_content": "Financial\nHuman\nMaterial\nEquipment\nIT\nLegislation",
        "label4_title": "Capabilities",
        "label4_content": "Systemic, strategic and entrepreneurial vision\nMultidisciplinary knowledge\nAnalytical and forecast capacity\nAbility to think, develop studies and plans",
        "label5_title": "Proccesses",
        "label5_content": "Strategic Process Management\nElaboration of development plans\nLocal and regional potential mapping\nIdentification of economic potential\nManagement of environmental impacts\nElaboration of policies,plans and programs\nAgreements Management\nPartnerships management and cooperation agreements",
        "label6_title": "Products",
        "label6_content": "Laws and regulatory frameworks for: Internet connectivity\nLaws and regulatory frameworks for: Medical consulting (state, regional and sectoral levels)",
        "label7_title": "Customers",
        "label7_content": "Society\nMunicipalities\nelderly houses\nUniversities\nThird sector",
        "label9_title": "Outcomes",
        "label9_content": "Universal and democratic access to digital connectivity\nIncrease of per capita income\nIncrease of the State PID\nJob creation\nHigher HDI\nEconomic and societal strengthening of municipalities and strategic regions of the state\nEnhance citizens� health and well-being\nEnhance citizens� digital literacy\nReduction of social inequality\nReduction of desertification",
        "label10_title": "Public Value",
        "label10_content": "Provide universal access to High-Speed Internet services by means of trustable and credible service, generating a fair and prosperous state that guarantees the well-being of the population as well as the financial sustainability of SMEs."
    },
    {
        "unique_id": 52,
        "sector": "eHealth",
        "service": "Broadband Connectivity (Access)",
        "user_type": "End-User",
        "label1_title": "Key Partners",
        "label1_content": "Internet service providers ISP\nTechnology and Data providers \nApplication providers (data analytics)\nFinancial providers (e.g. banks)\nGovernment and Regulatory bodies",
        "label2_title": "Key Activities",
        "label2_content": "Negotiations and Agreements: Establish contracts with Network Operetors, ISPs and other stakeholders.\nInstall and maintain connectivity infrastructure such as cell towers, real estate and fiber-optic networks in order to meet the connectivity needs of local stakeholders.",
        "label3_title": "Value Propositions",
        "label3_content": "Expand market reach and create new opportunities for local businesses, like agriculture, tourism, eHealth and other SMEs in the community, and individuals.\nHelp optimise operations & reduce costs.",
        "label4_title": "Customer Relationships",
        "label4_content": "Personal assistance.\nCo-creation (tailor made).",
        "label5_title": "Customer Segments",
        "label5_content": "Local businesses\nPublic authorities\nCitizens",
        "label6_title": "Channels",
        "label6_content": "Direct Sales: Engage with local SMEs and citizens.\nPartnerships: Collaborate with government agencies, NGOs, and existing infrastructure providers.",
        "label7_title": "Key Resources",
        "label7_content": "Human resources (e.g. technicians,engineers, IT professionals).\nPhysical resources (e.g. cell towers, real estate and fiber-optic networks etc).",
        "label9_title": "Cost Structure",
        "label9_content": "Infrastructure Costs: Towers, cables, and maintenance.\nOperational Costs: Staff salaries, energy, and ongoing maintenance.\nLegal and Regulatory Compliance Costs.",
        "label10_title": "Revenue Streams",
        "label10_content": "Increased profitability via increased production.\nIncreased profitability via cost reductions.\nSelling of products & services (B2B/B2C)."
    },
    {
        "unique_id": 53,
        "sector": "eHealth",
        "service": "Broadband Connectivity (Access)",
        "user_type": "Internet Service Provider (ISP)",
        "label1_title": "Key Partners",
        "label1_content": "MNOs\nTechnology and Data providers \nNGOs\nAcademic Institutions\nSystem Integrators\nFinancial providers (e.g. banks)\nGovernment and Regulatory bodies\nDrone Manufacturers\nApplication developers (data analytics)",
        "label2_title": "Key Activities",
        "label2_content": "Local Manufacturing & Sourcing\nAlliances & Partnerships formation\nSales & Promotion",
        "label3_title": "Value Propositions",
        "label3_content": "Low cost, Low power & Low maintenance (3L)\nHigh QOS & Coverage\nEnvironment-friendly green-solution\nEnd-to-end IP platform\nCloud server for locllized_content\nHigh Data Rates",
        "label4_title": "Customer Relationships",
        "label4_content": "24/7 Customer Care Centre\nMicro Operators/VLEs\nRegional Sales Executives",
        "label5_title": "Customer Segments",
        "label5_content": "Internet Service Providers (ISPs)\nLocal Communities\nSMEs\nPublic authorities\nNGOs\nResearch & Academia\nCommunity Retail Market",
        "label6_title": "Channels",
        "label6_content": "Micro Operators/ VLEs\nCo-branded outlets\nPartnership Telcos/ISPs\nDirect Sales\nWebsite\nSocial media channels",
        "label7_title": "Key Resources",
        "label7_content": "Physical resources (technologies, machineries)\nFinancial resources (Investors and Venture Funding)\nPassionate Management",
        "label9_title": "Cost Structure",
        "label9_content": "Manufacturing & Sourcing\nWi-Fi hotspot integration, funding, deployment, operation & maintenance\nEmployees & office\nR&D & Training\nSales & Marketing",
        "label10_title": "Revenue Streams",
        "label10_content": "Haas. Rent the set of hardware including deployment of the network (The client would be billed monthly, based on the amount of sensor systems deployed).\nIaas. The client should use his own hardware or purchase a device from us. Charge the client based on the amount of data to be transmitted."
    },
    {
        "unique_id": 54,
        "sector": "eHealth",
        "service": "Broadband Connectivity (Access)",
        "user_type": "Public Authority",
        "label1_title": "Partnerships/Suppliers/Collaborators",
        "label1_content": "State, Municipality, Federal Government, Ministries, European Union, Universities, Development Agencies, Private sector (e.g. MNOs, ISPs), Financial Institutions.",
        "label2_title": "Other Stakeholders",
        "label2_content": "Society, NGOs and Chamber of Tradesmen",
        "label3_title": "Resources",
        "label3_content": "Financial\nHuman\nMaterial\nEquipment\nIT\nLegislation",
        "label4_title": "Capabilities",
        "label4_content": "Systemic, strategic and entrepreneurial vision\nMultidisciplinary knowledge\nAnalytical and forecast capacity\nAbility to think, develop studies and plans",
        "label5_title": "Proccesses",
        "label5_content": "Strategic Process Management.\nElaboration of development plans.\nLocal and regional potential mapping.\nIdentification of economic potential.\nManagement of environmental impacts.\nElaboration of policies,plans and programs.\nAgreements Management.\nPartnerships management and cooperation agreements.",
        "label6_title": "Products",
        "label6_content": "Laws and regulatory frameworks for: Internet connectivity.",
        "label7_title": "Customers",
        "label7_content": "Society\nMunicipalities\nInternet Service Providers (ISPs)\nUniversities\nThird sector",
        "label9_title": "Outcomes",
        "label9_content": "Universal and democratic access to digital connectivity.\nIncrease of per capita income.\nIncrease of the State PID.\nJob creation.\nHigher HDI.\nEconomic and societal strengthening of municipalities and strategic regions of the state.\nEnhance citizens� digital literacy.\nReduction of social inequality.\nReduction of desertification.",
        "label10_title": "Public Value",
        "label10_content": "Provide universal access to High-Speed Internet services by means of trustable and credible service, generating a fair and prosperous state that guarantees the well-being of the population as well as the financial sustainability of SMEs."
    },
    {
        "unique_id": 55,
        "sector": "Environmental monitoring",
        "service": "Water Quality Monitoring",
        "user_type": "End-User",
        "label1_title": "Key Partners",
        "label1_content": "Internet service providers ISP\nTechnology and Data providers \nApplication providers (data analytics)\nFinancial providers (e.g. banks)\nGovernment and Regulatory bodies",
        "label2_title": "Key Activities",
        "label2_content": "Periodic data transmission of the water quality parameters in the environment",
        "label3_title": "Value Propositions",
        "label3_content": "Human resource and operations optimization and cost reduction reduce the overall impact of operations on the environment",
        "label4_title": "Customer Relationships",
        "label4_content": "Personal assistance\nCo-creation (tailor made)",
        "label5_title": "Customer Segments",
        "label5_content": "SMEs\nLocal community\nEnvironmental agencies\nEnvironmental agencies",
        "label6_title": "Channels",
        "label6_content": "Social media channels\nWebsite",
        "label7_title": "Key Resources",
        "label7_content": "Human resources (farmer, cloud/software/hardware/API engineers)\nPhysical resources (water quality sensors, LoRaWaN transmitter/ gateway, edge devices)",
        "label9_title": "Cost Structure",
        "label9_content": "Fixed costs for Infrastructure and maintenance\nOperational costs",
        "label10_title": "Revenue Streams",
        "label10_content": "Selling environmental monitoring services of any kind (B2B/B2Goverment)\nBetter quality services due to better connectivity"
    },
    {
        "unique_id": 56,
        "sector": "Environmental monitoring",
        "service": "Water Quality Monitoring",
        "user_type": "Internet Service Provider (ISP)",
        "label1_title": "Key Partners",
        "label1_content": "MNOs\nTechnology and Data providers \nNGOs\nAcademic Institutions\nSystem Integrators\nFinancial providers (e.g. banks)\nGovernment and Regulatory bodies",
        "label2_title": "Key Activities",
        "label2_content": "Local Manufacturing & Sourcing\nAlliances & Partnerships formation\nSales & Promotion",
        "label3_title": "Value Propositions",
        "label3_content": "Low cost, Low power & Low maintenance (3L)\nHigh QOS & Coverage\nEnvironment-friendly green-solution\nEnd-to-end IP platform\nCloud server for locllized_content\nHigh Data Rates",
        "label4_title": "Customer Relationships",
        "label4_content": "24/7 Customer Care Centre\nMicro Operators/VLEs\nRegional Sales Executives",
        "label5_title": "Customer Segments",
        "label5_content": "Public authorities\nResearch & Academia\nEnvironmental engineers",
        "label6_title": "Channels",
        "label6_content": "Micro Operators/ VLEs\nCo-branded outlets\nPartnership Telcos/ISPs\nDirect Sales\nWebsite\nSocial media channels",
        "label7_title": "Key Resources",
        "label7_content": "Physical resources (technologies, machineries)\nFinancial resources (Investors and Venture Funding)\nPassionate Management",
        "label9_title": "Cost Structure",
        "label9_content": "Manufacturing & Sourcing\nLoRaWAN integration, funding, deployment, operation & maintenance\nEmployees & office\nR&D & Training\nSales & Marketing",
        "label10_title": "Revenue Streams",
        "label10_content": "Haas. Rent the set of hardware including deployment of the network (The client would be billed monthly, based on the amount of sensor systems deployed).\nIaas. The client should use his own hardware or purchase a device from us. Charge the client based on the amount of data to be transmitted."
    },
    {
        "unique_id": 57,
        "sector": "Environmental monitoring",
        "service": "Water Quality Monitoring",
        "user_type": "Public Authority",
        "label1_title": "Partnerships/Suppliers/Collaborators",
        "label1_content": "State, Municipality, Federal Government, Ministries, European Union, Universities, Development Agencies, Private sector (e.g. MNOs, ISPs), Financial Institutions.",
        "label2_title": "Other Stakeholders",
        "label2_content": "Society, NGOs, Chamber of Tradesmen,Environmental Organisations",
        "label3_title": "Resources",
        "label3_content": "Financial\nHuman\nMaterial\nEquipment\nIT\nLegislation",
        "label4_title": "Capabilities",
        "label4_content": "Systemic, strategic and entrepreneurial vision\nMultidisciplinary knowledge\nAnalytical and forecast capacity\nAbility to think, develop studies and plans",
        "label5_title": "Proccesses",
        "label5_content": "Strategic Process Management\nElaboration of development plans\nLocal and regional potential mapping\nIdentification of economic potential\nManagement of environmental impacts\nElaboration of policies,plans and programs\nAgreements Management\nPartnerships management and cooperation agreements",
        "label6_title": "Products",
        "label6_content": "Laws and regulatory frameworks for: Internet connectivity\nLaws and regulatory frameworks for: Satellite operations (state, regional and sectoral levels)",
        "label7_title": "Customers",
        "label7_content": "Society\nMunicipalities\nCompanies from all the industries \nUniversities\nThird sector",
        "label9_title": "Outcomes",
        "label9_content": "Universal and democratic access to digital connectivity\nIncrease of per capita income\nIncrease of the State PID\nJob creation\nHigher HDI\nEconomic and societal strengthening of municipalities and strategic regions of the state\nProtection of water\nEnhance citizens� digital literacy\nReduction of social inequality\nReduction of desertification",
        "label10_title": "Public Value",
        "label10_content": "Provide universal access to High-Speed Internet services by means of trustable and credible service, generating a fair and prosperous state that guarantees the well-being of the population as well as the financial sustainability of SMEs."
    },
    {
        "unique_id": 58,
        "sector": "Environmental monitoring",
        "service": "Land Use / Land Cover",
        "user_type": "End-User",
        "label1_title": "Key Partners",
        "label1_content": "Internet service providers ISP\nTechnology and Data providers \nApplication providers (data analytics)\nFinancial providers (e.g. banks)\nGovernment and Regulatory bodies",
        "label2_title": "Key Activities",
        "label2_content": "Periodic data transmission of the air quality, water quality, noise and biodiversity parameters in the environment",
        "label3_title": "Value Propositions",
        "label3_content": "Human resource and operations optimization and cost reduction reduces the overall impact of operations on the environment",
        "label4_title": "Customer Relationships",
        "label4_content": "Personal assistance\nCo-creation (tailor made)",
        "label5_title": "Customer Segments",
        "label5_content": "SMEs\nLocal community\nEnvironmental agencies\nPublic Authorities",
        "label6_title": "Channels",
        "label6_content": "Social media channels\nWebsite",
        "label7_title": "Key Resources",
        "label7_content": "Human resources (farmer, cloud/software/hardware/API engineers)\nPhysical resources (water quality sensors, LoRaWaN transmitter/ gateway, edge devices)",
        "label9_title": "Cost Structure",
        "label9_content": "Fixed costs for Infrastructure and maintenance\nOperational costs",
        "label10_title": "Revenue Streams",
        "label10_content": "Selling environmental monitoring services of any kind (B2B/B2Goverment)\nBetter quality services due to better connectivity"
    },
    {
        "unique_id": 59,
        "sector": "Environmental monitoring",
        "service": "Land Use / Land Cover",
        "user_type": "Internet Service Provider (ISP)",
        "label1_title": "Key Partners",
        "label1_content": "MNOs\nTechnology and Data providers \nNGOs\nAcademic Institutions\nSystem Integrators\nFinancial providers (e.g. banks)\nGovernment and Regulatory bodies",
        "label2_title": "Key Activities",
        "label2_content": "Local Manufacturing & Sourcing\nAlliances & Partnerships formation\nSales & Promotion",
        "label3_title": "Value Propositions",
        "label3_content": "Low cost, Low power & Low maintenance (3L)\nHigh QOS & Coverage\nEnvironment-friendly green-solution\nEnd-to-end IP platform\nCloud server for locllized_content\nHigh Data Rates",
        "label4_title": "Customer Relationships",
        "label4_content": "24/7 Customer Care Centre\nMicro Operators/VLEs\nRegional Sales Executives",
        "label5_title": "Customer Segments",
        "label5_content": "Public authorities\nResearch & Academia\nEnvironmental engineers",
        "label6_title": "Channels",
        "label6_content": "Micro Operators/ VLEs\nCo-branded outlets\nPartnership Telcos/ISPs\nDirect Sales\nWebsite\nSocial media channels",
        "label7_title": "Key Resources",
        "label7_content": "Physical resources (technologies, machineries)\nFinancial resources (Investors and Venture Funding)\nPassionate Management",
        "label9_title": "Cost Structure",
        "label9_content": "Manufacturing & Sourcing\nLoRaWAN integration, funding, deployment, operation & maintenance\nEmployees & office\nR&D & Training\nSales & Marketing",
        "label10_title": "Revenue Streams",
        "label10_content": "Haas. Rent the set of hardware including deployment of the network (The client would be billed monthly, based on the amount of sensor systems deployed).\nIaas. The client should use his own hardware or purchase a device from us. Charge the client based on the amount of data to be transmitted."
    },
    {
        "unique_id": 60,
        "sector": "Environmental monitoring",
        "service": "Land Use / Land Cover",
        "user_type": "Public Authority",
        "label1_title": "Partnerships/Suppliers/Collaborators",
        "label1_content": "State, Municipality, Federal Government, Ministries, European Union, Universities, Development Agencies, Private sector (e.g. MNOs, ISPs), Financial Institutions.",
        "label2_title": "Other Stakeholders",
        "label2_content": "Society, NGOs, Chamber of Tradesmen,Environmental Organisations",
        "label3_title": "Resources",
        "label3_content": "Financial\nHuman\nMaterial\nEquipment\nIT\nLegislation",
        "label4_title": "Capabilities",
        "label4_content": "Systemic, strategic and entrepreneurial vision\nMultidisciplinary knowledge\nAnalytical and forecast capacity\nAbility to think, develop studies and plans",
        "label5_title": "Proccesses",
        "label5_content": "Strategic Process Management\nElaboration of development plans\nLocal and regional potential mapping\nIdentification of economic potential\nManagement of environmental impacts\nElaboration of policies,plans and programs\nAgreements Management\nPartnerships management and cooperation agreements",
        "label6_title": "Products",
        "label6_content": "Laws and regulatory frameworks for: Internet connectivity\nLaws and regulatory frameworks for: Satellite operations (state, regional and sectoral levels)",
        "label7_title": "Customers",
        "label7_content": "Society\nMunicipalities\nCompanies from all the industries \nUniversities\nThird sector",
        "label9_title": "Outcomes",
        "label9_content": "Universal and democratic access to digital connectivity\nIncrease of per capita income\nIncrease of the State PID\nJob creation\nHigher HDI\nEconomic and societal strengthening of municipalities and strategic regions of the state\nProtection of water\nEnhance citizens� digital literacy\nReduction of social inequality\nReduction of desertification",
        "label10_title": "Public Value",
        "label10_content": "Provide universal access to High-Speed Internet services by means of trustable and credible service, generating a fair and prosperous state that guarantees the well-being of the population as well as the financial sustainability of SMEs."
    },
    {
        "unique_id": 61,
        "sector": "Environmental monitoring",
        "service": "Broadband Connectivity (Access)",
        "user_type": "End-User",
        "label1_title": "Key Partners",
        "label1_content": "Internet service providers ISP\nTechnology and Data providers \nApplication providers (data analytics)\nFinancial providers (e.g. banks)\nGovernment and Regulatory bodies",
        "label2_title": "Key Activities",
        "label2_content": "Negotiations and Agreements: Establish contracts with Network Operetors, ISPs and other stakeholders.\nInstall and maintain connectivity infrastructure such as cell towers, real estate and fiber-optic networks in order to meet the connectivity needs of local stakeholders.",
        "label3_title": "Value Propositions",
        "label3_content": "Expand market reach and create new opportunities for local businesses, like agriculture, tourism, eHealth and other SMEs in the community, and individuals.\nHelp optimise operations & reduce costs.",
        "label4_title": "Customer Relationships",
        "label4_content": "Personal assistance.\nCo-creation (tailor made).",
        "label5_title": "Customer Segments",
        "label5_content": "Local businesses\nPublic authorities\nCitizens",
        "label6_title": "Channels",
        "label6_content": "Direct Sales: Engage with local SMEs and citizens.\nPartnerships: Collaborate with government agencies, NGOs, and existing infrastructure providers.",
        "label7_title": "Key Resources",
        "label7_content": "Human resources (e.g. technicians,engineers, IT professionals).\nPhysical resources (e.g. cell towers, real estate and fiber-optic networks etc).",
        "label9_title": "Cost Structure",
        "label9_content": "Infrastructure Costs: Towers, cables, and maintenance.\nOperational Costs: Staff salaries, energy, and ongoing maintenance.\nLegal and Regulatory Compliance Costs.",
        "label10_title": "Revenue Streams",
        "label10_content": "Increased profitability via increased production.\nIncreased profitability via cost reductions.\nSelling of products & services (B2B/B2C)."
    },
    {
        "unique_id": 62,
        "sector": "Environmental monitoring",
        "service": "Broadband Connectivity (Access)",
        "user_type": "Internet Service Provider (ISP)",
        "label1_title": "Key Partners",
        "label1_content": "MNOs\nTechnology and Data providers \nNGOs\nAcademic Institutions\nSystem Integrators\nFinancial providers (e.g. banks)\nGovernment and Regulatory bodies\nDrone Manufacturers\nApplication developers (data analytics)",
        "label2_title": "Key Activities",
        "label2_content": "Local Manufacturing & Sourcing\nAlliances & Partnerships formation\nSales & Promotion",
        "label3_title": "Value Propositions",
        "label3_content": "Low cost, Low power & Low maintenance (3L)\nHigh QOS & Coverage\nEnvironment-friendly green-solution\nEnd-to-end IP platform\nCloud server for locllized_content\nHigh Data Rates",
        "label4_title": "Customer Relationships",
        "label4_content": "24/7 Customer Care Centre\nMicro Operators/VLEs\nRegional Sales Executives",
        "label5_title": "Customer Segments",
        "label5_content": "Internet Service Providers (ISPs)\nLocal Communities\nSMEs\nPublic authorities\nNGOs\nResearch & Academia\nCommunity Retail Market",
        "label6_title": "Channels",
        "label6_content": "Micro Operators/ VLEs\nCo-branded outlets\nPartnership Telcos/ISPs\nDirect Sales\nWebsite\nSocial media channels",
        "label7_title": "Key Resources",
        "label7_content": "Physical resources (technologies, machineries)\nFinancial resources (Investors and Venture Funding)\nPassionate Management",
        "label9_title": "Cost Structure",
        "label9_content": "Manufacturing & Sourcing\nWi-Fi hotspot integration, funding, deployment, operation & maintenance\nEmployees & office\nR&D & Training\nSales & Marketing",
        "label10_title": "Revenue Streams",
        "label10_content": "Haas. Rent the set of hardware including deployment of the network (The client would be billed monthly, based on the amount of sensor systems deployed).\nIaas. The client should use his own hardware or purchase a device from us. Charge the client based on the amount of data to be transmitted."
    },
    {
        "unique_id": 63,
        "sector": "Environmental monitoring",
        "service": "Broadband Connectivity (Access)",
        "user_type": "Public Authority",
        "label1_title": "Partnerships/Suppliers/Collaborators",
        "label1_content": "State, Municipality, Federal Government, Ministries, European Union, Universities, Development Agencies, Private sector (e.g. MNOs, ISPs), Financial Institutions.",
        "label2_title": "Other Stakeholders",
        "label2_content": "Society, NGOs and Chamber of Tradesmen",
        "label3_title": "Resources",
        "label3_content": "Financial\nHuman\nMaterial\nEquipment\nIT\nLegislation",
        "label4_title": "Capabilities",
        "label4_content": "Systemic, strategic and entrepreneurial vision\nMultidisciplinary knowledge\nAnalytical and forecast capacity\nAbility to think, develop studies and plans",
        "label5_title": "Proccesses",
        "label5_content": "Strategic Process Management.\nElaboration of development plans.\nLocal and regional potential mapping.\nIdentification of economic potential.\nManagement of environmental impacts.\nElaboration of policies,plans and programs.\nAgreements Management.\nPartnerships management and cooperation agreements.",
        "label6_title": "Products",
        "label6_content": "Laws and regulatory frameworks for: Internet connectivity.",
        "label7_title": "Customers",
        "label7_content": "Society\nMunicipalities\nInternet Service Providers (ISPs)\nUniversities\nThird sector",
        "label9_title": "Outcomes",
        "label9_content": "Universal and democratic access to digital connectivity.\nIncrease of per capita income.\nIncrease of the State PID.\nJob creation.\nHigher HDI.\nEconomic and societal strengthening of municipalities and strategic regions of the state.\nEnhance citizens� digital literacy.\nReduction of social inequality.\nReduction of desertification.",
        "label10_title": "Public Value",
        "label10_content": "Provide universal access to High-Speed Internet services by means of trustable and credible service, generating a fair and prosperous state that guarantees the well-being of the population as well as the financial sustainability of SMEs."
    },
    {
        "unique_id": 64,
        "sector": "Forestry",
        "service": "Forest Management",
        "user_type": "End-User",
        "label1_title": "Key Partners",
        "label1_content": "Internet service providers ISP\nTechnology Providers\nApplication providers (data analytics)\nFinancial providers (e.g. banks)\nGovernment and Regulatory bodies",
        "label2_title": "Key Activities",
        "label2_content": "Timely reception of data from multirotor drone equipped with high-end hyperspectral camera using 5G network.",
        "label3_title": "Value Propositions",
        "label3_content": "Monitor and investigate the fire risk of a certain forest\nIdentify and evaluate the scope of illegal logging\nDistinguish a certain reason for the tree damage�\nMonitor forest health\nMovement of wildlife animals\nPrevent losses and damages\nProactivity in related levels",
        "label4_title": "Customer Relationships",
        "label4_content": "Personal assistance\nCo-creation (tailor made)",
        "label5_title": "Customer Segments",
        "label5_content": "Local community \nPublic Authorities\nEnvironmental Agencies",
        "label6_title": "Channels",
        "label6_content": "Social media channels\nWebsite",
        "label7_title": "Key Resources",
        "label7_content": "Human resources (drone operators, cloud/software/hardware/API engineers)\nPhysical resources (e.g. drones, devices, hardware, hyperspectral cameras)",
        "label9_title": "Cost Structure",
        "label9_content": "Fixed costs for Infrastructure (Owned.Rent Drones) and maintenance\nOperational costs",
        "label10_title": "Revenue Streams",
        "label10_content": "Increased profitability via increased production\nIncreased profitability via cost reductions"
    },
    {
        "unique_id": 65,
        "sector": "Forestry",
        "service": "Forest Management",
        "user_type": "Internet Service Provider (ISP)",
        "label1_title": "Key Partners",
        "label1_content": "MNOs\nTechnology and Data providers \nNGOs\nAcademic Institutions\nSystem Integrators\nFinancial providers (e.g. banks)\nGovernment and Regulatory bodies",
        "label2_title": "Key Activities",
        "label2_content": "Local Manufacturing & Sourcing\nAlliances & Partnerships formation\nSales & Promotion",
        "label3_title": "Value Propositions",
        "label3_content": "Low cost, Low power & Low maintenance (3L)\nHigh QOS & Coverage\nEnvironment-friendly green-solution\nEnd-to-end IP platform\nCloud server for locllized_content\nHigh Data Rates",
        "label4_title": "Customer Relationships",
        "label4_content": "24/7 Customer Care Centre\nMicro Operators/VLEs\nRegional Sales Executives",
        "label5_title": "Customer Segments",
        "label5_content": "Environmental agencies\nSMEs\nPublic authorities (responsible for environmental and forest management)\nEnvironmental NGOs\nRural communities\nPrivate forest owners\nFire prevention specialists\nResearch & Academia",
        "label6_title": "Channels",
        "label6_content": "Micro Operators/ VLEs\nCo-branded outlets\nPartnership Telcos/ISPs\nDirect Sales\nWebsite\nSocial media channels",
        "label7_title": "Key Resources",
        "label7_content": "Physical resources (technologies, machineries)\nFinancial resources (Investors and Venture Funding)\nPassionate Management",
        "label9_title": "Cost Structure",
        "label9_content": "Manufacturing & Sourcing\nWi-Fi hotspot integration, funding, deployment, operation & maintenance\nEmployees & office\nR&D & Training\nSales & Marketing",
        "label10_title": "Revenue Streams",
        "label10_content": "Product-as-a-Service: The charge is based on the area the farmer wants to cover (use of drone )\nNetwork slice rent (technology, software, mobile and broadband network providers)\nInfrastructure-as-a-Service: The charge is based on the data amount to be processed, plus a subscription fee to access the information system which provides the tools for analysis and decision-making"
    },
    {
        "unique_id": 66,
        "sector": "Forestry",
        "service": "Forest Management",
        "user_type": "Public Authority",
        "label1_title": "Partnerships/Suppliers/Collaborators",
        "label1_content": "State, Municipality, Federal Government, Ministries, European Union, Universities, Development Agencies, Private sector (e.g. MNOs, ISPs), Financial Institutions.",
        "label2_title": "Other Stakeholders",
        "label2_content": "Society, NGOs, Chamber of Tradesmen, Fire Departments, Civil Aviation, Forestry Organisations, Agricultural Organisations, Environmental Organisations",
        "label3_title": "Resources",
        "label3_content": "Financial\nHuman\nMaterial\nEquipment\nIT\nLegislation",
        "label4_title": "Capabilities",
        "label4_content": "Systemic, strategic and entrepreneurial vision\nMultidisciplinary knowledge\nAnalytical and forecast capacity\nAbility to think, develop studies and plans",
        "label5_title": "Proccesses",
        "label5_content": "Strategic Process Management\nElaboration of development plans\nLocal and regional potential mapping\nIdentification of economic potential\nManagement of environmental impacts\nElaboration of policies,plans and programs\nAgreements Management\nPartnerships management and cooperation agreements",
        "label6_title": "Products",
        "label6_content": "Laws and regulatory frameworks for: Internet connectivity\nLaws and regulatory frameworks for: Drones operations (state, regional and sectoral levels)",
        "label7_title": "Customers",
        "label7_content": "Society\nMunicipalities\nUniversities\nSMEs:Agriculture\nSMEs: Tourism\nThird sector",
        "label9_title": "Outcomes",
        "label9_content": "Universal and democratic access to digital connectivity\nIncrease of per capita income\nIncrease of the State PID\nJob creation\nHigher HDI\nEconomic and societal strengthening of municipalities and strategic regions of the state\nSustainable Agriculture\nProtection and rational use of soil and water\nProtection of forests\nReduction of social inequality\nReduction of desertification\nEnhance citizens� health and well-being\nEnhance citizens� digital literacy",
        "label10_title": "Public Value",
        "label10_content": "Provide universal access to High-Speed Internet services by means of trustable and credible service, generating a fair and prosperous state that guarantees the well-being of the population as well as the financial sustainability of SMEs."
    },
    {
        "unique_id": 67,
        "sector": "Forestry",
        "service": "Broadband Connectivity (Access)",
        "user_type": "End-User",
        "label1_title": "Key Partners",
        "label1_content": "Internet service providers ISP\nTechnology and Data providers \nApplication providers (data analytics)\nFinancial providers (e.g. banks)\nGovernment and Regulatory bodies",
        "label2_title": "Key Activities",
        "label2_content": "Negotiations and Agreements: Establish contracts with Network Operetors, ISPs and other stakeholders.\nInstall and maintain connectivity infrastructure such as cell towers, real estate and fiber-optic networks in order to meet the connectivity needs of local stakeholders.",
        "label3_title": "Value Propositions",
        "label3_content": "Expand market reach and create new opportunities for local businesses, like agriculture, tourism, eHealth and other SMEs in the community, and individuals.\nHelp optimise operations & reduce costs.",
        "label4_title": "Customer Relationships",
        "label4_content": "Personal assistance.\nCo-creation (tailor made).",
        "label5_title": "Customer Segments",
        "label5_content": "Local businesses\nPublic authorities\nCitizens",
        "label6_title": "Channels",
        "label6_content": "Direct Sales: Engage with local SMEs and citizens.\nPartnerships: Collaborate with government agencies, NGOs, and existing infrastructure providers.",
        "label7_title": "Key Resources",
        "label7_content": "Human resources (e.g. technicians,engineers, IT professionals).\nPhysical resources (e.g. cell towers, real estate and fiber-optic networks etc).",
        "label9_title": "Cost Structure",
        "label9_content": "Infrastructure Costs: Towers, cables, and maintenance.\nOperational Costs: Staff salaries, energy, and ongoing maintenance.\nLegal and Regulatory Compliance Costs.",
        "label10_title": "Revenue Streams",
        "label10_content": "Increased profitability via increased production.\nIncreased profitability via cost reductions.\nSelling of products & services (B2B/B2C)."
    },
    {
        "unique_id": 68,
        "sector": "Forestry",
        "service": "Broadband Connectivity (Access)",
        "user_type": "Internet Service Provider (ISP)",
        "label1_title": "Key Partners",
        "label1_content": "MNOs\nTechnology and Data providers \nNGOs\nAcademic Institutions\nSystem Integrators\nFinancial providers (e.g. banks)\nGovernment and Regulatory bodies\nDrone Manufacturers\nApplication developers (data analytics)",
        "label2_title": "Key Activities",
        "label2_content": "Local Manufacturing & Sourcing\nAlliances & Partnerships formation\nSales & Promotion",
        "label3_title": "Value Propositions",
        "label3_content": "Low cost, Low power & Low maintenance (3L)\nHigh QOS & Coverage\nEnvironment-friendly green-solution\nEnd-to-end IP platform\nCloud server for locllized_content\nHigh Data Rates",
        "label4_title": "Customer Relationships",
        "label4_content": "24/7 Customer Care Centre\nMicro Operators/VLEs\nRegional Sales Executives",
        "label5_title": "Customer Segments",
        "label5_content": "Internet Service Providers (ISPs)\nLocal Communities\nSMEs\nPublic authorities\nNGOs\nResearch & Academia\nCommunity Retail Market",
        "label6_title": "Channels",
        "label6_content": "Micro Operators/ VLEs\nCo-branded outlets\nPartnership Telcos/ISPs\nDirect Sales\nWebsite\nSocial media channels",
        "label7_title": "Key Resources",
        "label7_content": "Physical resources (technologies, machineries)\nFinancial resources (Investors and Venture Funding)\nPassionate Management",
        "label9_title": "Cost Structure",
        "label9_content": "Manufacturing & Sourcing\nWi-Fi hotspot integration, funding, deployment, operation & maintenance\nEmployees & office\nR&D & Training\nSales & Marketing",
        "label10_title": "Revenue Streams",
        "label10_content": "Haas. Rent the set of hardware including deployment of the network (The client would be billed monthly, based on the amount of sensor systems deployed).\nIaas. The client should use his own hardware or purchase a device from us. Charge the client based on the amount of data to be transmitted."
    },
    {
        "unique_id": 69,
        "sector": "Forestry",
        "service": "Broadband Connectivity (Access)",
        "user_type": "Public Authority",
        "label1_title": "Partnerships/Suppliers/Collaborators",
        "label1_content": "State, Municipality, Federal Government, Ministries, European Union, Universities, Development Agencies, Private sector (e.g. MNOs, ISPs), Financial Institutions.",
        "label2_title": "Other Stakeholders",
        "label2_content": "Society, NGOs and Chamber of Tradesmen",
        "label3_title": "Resources",
        "label3_content": "Financial\nHuman\nMaterial\nEquipment\nIT\nLegislation",
        "label4_title": "Capabilities",
        "label4_content": "Systemic, strategic and entrepreneurial vision\nMultidisciplinary knowledge\nAnalytical and forecast capacity\nAbility to think, develop studies and plans",
        "label5_title": "Proccesses",
        "label5_content": "Strategic Process Management.\nElaboration of development plans.\nLocal and regional potential mapping.\nIdentification of economic potential.\nManagement of environmental impacts.\nElaboration of policies,plans and programs.\nAgreements Management.\nPartnerships management and cooperation agreements.",
        "label6_title": "Products",
        "label6_content": "Laws and regulatory frameworks for: Internet connectivity.",
        "label7_title": "Customers",
        "label7_content": "Society\nMunicipalities\nInternet Service Providers (ISPs)\nUniversities\nThird sector",
        "label9_title": "Outcomes",
        "label9_content": "Universal and democratic access to digital connectivity.\nIncrease of per capita income.\nIncrease of the State PID.\nJob creation.\nHigher HDI.\nEconomic and societal strengthening of municipalities and strategic regions of the state.\nEnhance citizens� digital literacy.\nReduction of social inequality.\nReduction of desertification.",
        "label10_title": "Public Value",
        "label10_content": "Provide universal access to High-Speed Internet services by means of trustable and credible service, generating a fair and prosperous state that guarantees the well-being of the population as well as the financial sustainability of SMEs."
    },
    {
        "unique_id": 70,
        "sector": "Tourism",
        "service": "Leisure",
        "user_type": "End-User",
        "label1_title": "Key Partners",
        "label1_content": "Internet service providers ISP\nTechnology and Data providers \nApplication providers (data analytics)\nFinancial providers (e.g. banks)\nGovernment and Regulatory bodies",
        "label2_title": "Key Activities",
        "label2_content": "AR/VR for tourism sector",
        "label3_title": "Value Propositions",
        "label3_content": "Extend the service offerings of local agriculture and SMEs in the  tourism sector using AR/VR",
        "label4_title": "Customer Relationships",
        "label4_content": "Personal assistance\nCo-creation (tailor made)",
        "label5_title": "Customer Segments",
        "label5_content": "Public authorities\nSMEs : Tourism\nLocal education institutions",
        "label6_title": "Channels",
        "label6_content": "Social media channels\nWebsite",
        "label7_title": "Key Resources",
        "label7_content": "Human resources (e.g. technicians,engineers, IT professionals)\nPhysical resources (e.g. drones, devices, cameras)",
        "label9_title": "Cost Structure",
        "label9_content": "Fixed costs for Infrastructure (Owned Drones) and maintenance\nOperational costs",
        "label10_title": "Revenue Streams",
        "label10_content": "Monetise the use of drones and drone centres for testing/evaluating services/solutions.(Pay per hour/ Monthly/lesson)\nDrone rental\nIncreased profitability via increased production\nIncreased profitability via cost reductions\nSelling Agricultural products (B2B/B2C)\nSelling Touristic services (B2C)"
    },
    {
        "unique_id": 71,
        "sector": "Tourism",
        "service": "Leisure",
        "user_type": "Internet Service Provider (ISP)",
        "label1_title": "Key Partners",
        "label1_content": "MNOs\nTechnology and Data providers \nNGOs\nAcademic Institutions\nSystem Integrators\nFinancial providers (e.g. banks)\nGovernment and Regulatory bodies\nDrone Manufacturers\nApplication developers (data analytics)",
        "label2_title": "Key Activities",
        "label2_content": "Local Manufacturing & Sourcing\nAlliances & Partnerships formation\nSales & Promotion",
        "label3_title": "Value Propositions",
        "label3_content": "Low cost, Low power & Low maintenance (3L)\nHigh QOS & Coverage\nEnvironment-friendly green-solution\nEnd-to-end IP platform\nCloud server for locllized_content\nHigh Data Rates",
        "label4_title": "Customer Relationships",
        "label4_content": "24/7 Customer Care Centre\nMicro Operators/VLEs\nRegional Sales Executives",
        "label5_title": "Customer Segments",
        "label5_content": "Local community\nSMEs\nPublic authorities\nLocal Retail Market",
        "label6_title": "Channels",
        "label6_content": "Micro Operators/ VLEs\nCo-branded outlets\nPartnership Telcos/ISPs\nDirect Sales\nWebsite\nSocial media channels",
        "label7_title": "Key Resources",
        "label7_content": "Physical resources (technologies, machineries)\nFinancial resources (Investors and Venture Funding)\nPassionate Management",
        "label9_title": "Cost Structure",
        "label9_content": "Manufacturing & Sourcing\nWi-Fi hotspot integration, funding, deployment, operation & maintenance\nEmployees & office\nR&D & Training\nSales & Marketing",
        "label10_title": "Revenue Streams",
        "label10_content": "Subscription fee\nContent/ Application services revenue share\nGovt. Anchor usage payment receipts\nMobile Data offload revenues\nNetwork slice rent (technology, software, mobile and broadband network providers)"
    },
    {
        "unique_id": 72,
        "sector": "Tourism",
        "service": "Leisure",
        "user_type": "Public Authority",
        "label1_title": "Partnerships/Suppliers/Collaborators",
        "label1_content": "State, Municipality, Federal Government, Ministries, European Union, Universities, Development Agencies, Private sector (e.g. MNOs, ISPs), Financial Institutions.",
        "label2_title": "Other Stakeholders",
        "label2_content": "Society, NGOs, Chamber of Tradesmen,Tourism Organisations",
        "label3_title": "Resources",
        "label3_content": "Financial\nHuman\nMaterial\nEquipment\nIT\nLegislation",
        "label4_title": "Capabilities",
        "label4_content": "Systemic, strategic and entrepreneurial vision\nMultidisciplinary knowledge\nAnalytical and forecast capacity\nAbility to think, develop studies and plans",
        "label5_title": "Proccesses",
        "label5_content": "Strategic Process Management\nElaboration of development plans\nLocal and regional potential mapping\nIdentification of economic potential\nManagement of environmental impacts\nElaboration of policies,plans and programs\nAgreements Management\nPartnerships management and cooperation agreements",
        "label6_title": "Products",
        "label6_content": "Laws and regulatory frameworks for: Internet connectivity\nLaws and regulatory frameworks for: Drones operations (state, regional and sectoral levels)",
        "label7_title": "Customers",
        "label7_content": "Society\nMunicipalities\nCompanies from all the industries (especially tourism)\nUniversities\nThird sector",
        "label9_title": "Outcomes",
        "label9_content": "Universal and democratic access to digital connectivity\nIncrease of per capita income\nIncrease of the State PID\nJob creation\nHigher HDI\nEconomic and societal strengthening of municipalities and strategic regions of the state\nSustainable Tourism\nEnhance citizens� digital literacy\nReduction of social inequality\nReduction of desertification",
        "label10_title": "Public Value",
        "label10_content": "Provide universal access to High-Speed Internet services by means of trustable and credible service, generating a fair and prosperous state that guarantees the well-being of the population as well as the financial sustainability of SMEs."
    },
    {
        "unique_id": 73,
        "sector": "Tourism",
        "service": "Broadband Connectivity (Access)",
        "user_type": "End-User",
        "label1_title": "Key Partners",
        "label1_content": "Internet service providers ISP\nTechnology and Data providers \nApplication providers (data analytics)\nFinancial providers (e.g. banks)\nGovernment and Regulatory bodies",
        "label2_title": "Key Activities",
        "label2_content": "Negotiations and Agreements: Establish contracts with Network Operetors, ISPs and other stakeholders.\nInstall and maintain connectivity infrastructure such as cell towers, real estate and fiber-optic networks in order to meet the connectivity needs of local stakeholders.",
        "label3_title": "Value Propositions",
        "label3_content": "Expand market reach and create new opportunities for local businesses, like agriculture, tourism, eHealth and other SMEs in the community, and individuals.\nHelp optimise operations & reduce costs.",
        "label4_title": "Customer Relationships",
        "label4_content": "Personal assistance.\nCo-creation (tailor made).",
        "label5_title": "Customer Segments",
        "label5_content": "Local businesses\nPublic authorities\nCitizens",
        "label6_title": "Channels",
        "label6_content": "Direct Sales: Engage with local SMEs and citizens.\nPartnerships: Collaborate with government agencies, NGOs, and existing infrastructure providers.",
        "label7_title": "Key Resources",
        "label7_content": "Human resources (e.g. technicians,engineers, IT professionals).\nPhysical resources (e.g. cell towers, real estate and fiber-optic networks etc).",
        "label9_title": "Cost Structure",
        "label9_content": "Infrastructure Costs: Towers, cables, and maintenance.\nOperational Costs: Staff salaries, energy, and ongoing maintenance.\nLegal and Regulatory Compliance Costs.",
        "label10_title": "Revenue Streams",
        "label10_content": "Increased profitability via increased production.\nIncreased profitability via cost reductions.\nSelling of products & services (B2B/B2C)."
    },
    {
        "unique_id": 74,
        "sector": "Tourism",
        "service": "Broadband Connectivity (Access)",
        "user_type": "Internet Service Provider (ISP)",
        "label1_title": "Key Partners",
        "label1_content": "MNOs\nTechnology and Data providers \nNGOs\nAcademic Institutions\nSystem Integrators\nFinancial providers (e.g. banks)\nGovernment and Regulatory bodies\nDrone Manufacturers\nApplication developers (data analytics)",
        "label2_title": "Key Activities",
        "label2_content": "Local Manufacturing & Sourcing\nAlliances & Partnerships formation\nSales & Promotion",
        "label3_title": "Value Propositions",
        "label3_content": "Low cost, Low power & Low maintenance (3L)\nHigh QOS & Coverage\nEnvironment-friendly green-solution\nEnd-to-end IP platform\nCloud server for locllized_content\nHigh Data Rates",
        "label4_title": "Customer Relationships",
        "label4_content": "24/7 Customer Care Centre\nMicro Operators/VLEs\nRegional Sales Executives",
        "label5_title": "Customer Segments",
        "label5_content": "Internet Service Providers (ISPs)\nLocal Communities\nSMEs\nPublic authorities\nNGOs\nResearch & Academia\nCommunity Retail Market",
        "label6_title": "Channels",
        "label6_content": "Micro Operators/ VLEs\nCo-branded outlets\nPartnership Telcos/ISPs\nDirect Sales\nWebsite\nSocial media channels",
        "label7_title": "Key Resources",
        "label7_content": "Physical resources (technologies, machineries)\nFinancial resources (Investors and Venture Funding)\nPassionate Management",
        "label9_title": "Cost Structure",
        "label9_content": "Manufacturing & Sourcing\nWi-Fi hotspot integration, funding, deployment, operation & maintenance\nEmployees & office\nR&D & Training\nSales & Marketing",
        "label10_title": "Revenue Streams",
        "label10_content": "Haas. Rent the set of hardware including deployment of the network (The client would be billed monthly, based on the amount of sensor systems deployed).\nIaas. The client should use his own hardware or purchase a device from us. Charge the client based on the amount of data to be transmitted."
    },
    {
        "unique_id": 75,
        "sector": "Tourism",
        "service": "Broadband Connectivity (Access)",
        "user_type": "Public Authority",
        "label1_title": "Partnerships/Suppliers/Collaborators",
        "label1_content": "State, Municipality, Federal Government, Ministries, European Union, Universities, Development Agencies, Private sector (e.g. MNOs, ISPs), Financial Institutions.",
        "label2_title": "Other Stakeholders",
        "label2_content": "Society, NGOs and Chamber of Tradesmen",
        "label3_title": "Resources",
        "label3_content": "Financial\nHuman\nMaterial\nEquipment\nIT\nLegislation",
        "label4_title": "Capabilities",
        "label4_content": "Systemic, strategic and entrepreneurial vision\nMultidisciplinary knowledge\nAnalytical and forecast capacity\nAbility to think, develop studies and plans",
        "label5_title": "Proccesses",
        "label5_content": "Strategic Process Management.\nElaboration of development plans.\nLocal and regional potential mapping.\nIdentification of economic potential.\nManagement of environmental impacts.\nElaboration of policies,plans and programs.\nAgreements Management.\nPartnerships management and cooperation agreements.",
        "label6_title": "Products",
        "label6_content": "Laws and regulatory frameworks for: Internet connectivity.",
        "label7_title": "Customers",
        "label7_content": "Society\nMunicipalities\nInternet Service Providers (ISPs)\nUniversities\nThird sector",
        "label9_title": "Outcomes",
        "label9_content": "Universal and democratic access to digital connectivity.\nIncrease of per capita income.\nIncrease of the State PID.\nJob creation.\nHigher HDI.\nEconomic and societal strengthening of municipalities and strategic regions of the state.\nEnhance citizens� digital literacy.\nReduction of social inequality.\nReduction of desertification.",
        "label10_title": "Public Value",
        "label10_content": "Provide universal access to High-Speed Internet services by means of trustable and credible service, generating a fair and prosperous state that guarantees the well-being of the population as well as the financial sustainability of SMEs."
    },
    {
        "unique_id": 76,
        "sector": ["Agriculture","Aquaculture"],
        "service": "",
        "user_type": "End-User",
        "label1_title": "Key Partners",
        "label1_content": "Internet service providers (ISPs)\nTechnology and Data providers \nApplication providers (data analytics)\nFinancial providers (e.g. banks)\nGovernment and Regulatory bodies",
        "label2_title": "Key Activities",
        "label2_content": "Real-time & periodic farm monitoring\nReal-time & periodic livestock monitoring\nAutomated area surveillance\nReal-time & periodic crop monitoring\nReal-time & periodic pest detection\nAutonomous Driving (Agricultural sector) \nWater quality monitoring ",
        "label3_title": "Value Propositions",
        "label3_content": "Enhancing the efficiency, competitiveness and sustainability of agricultural and aquacultural practices through improved services, optimized operations, cost reduction, upgraded network quality, and environmental protection.\nPeriodic data transmission of the water quality parameters in the environment.\nHuman resource and operations optimization and cost reduction reduce the overall impact of operations on the environment.",
        "label4_title": "Customer Relationships",
        "label4_content": "Personal assistance\nCo-creation (tailor made)",
        "label5_title": "Customer Segments",
        "label5_content": "Local businesses\nPublic authorities\nAgricultural sector\nAquaculture sector\nVeterinary sector\nEnvironmental agencies\nEquipment operators/users (drones, sensors, etc.)",
        "label6_title": "Channels",
        "label6_content": "Social media channels\nWebsite\nCommunity workshops\nCollaborations with local cooperatives",
        "label7_title": "Key Resources",
        "label7_content": "Human resources (e.g. technicians,engineers, IT professionals)\nPhysical resources (e.g. drones, devices, cameras)",
        "label9_title": "Cost Structure",
        "label9_content": "Fixed costs for infrastructure and maintenance\nOperational costs",
        "label10_title": "Revenue Streams",
        "label10_content": "Increased profitability via increased production\nIncreased profitability via cost reductions\nSelling Agricultural products (B2B/B2C)\nMonetise the use of smart infrastructure for testing/evaluating services & solutions.  (Pay per hour/montly/per project)"
    },
    {
        "unique_id": 77,
        "sector": ["Agriculture","Aquaculture"],
        "service": "",
        "user_type": "Internet Service Provider (ISP)",
        "label1_title": "Key Partners",
        "label1_content": "Other Mobile Network Operators (MNOs) & Internet service providers (ISPs)\nTechnology and Data providers \nNGOs\nAcademic Institutions\nSystem Integrators\nFinancial providers (e.g. banks)\nGovernment and Regulatory bodies\nApplication developers (data analytics)\nTechnical consultants/ advisors",
        "label2_title": "Key Activities",
        "label2_content": "Local Manufacturing & Sourcing\nAlliances & Partnerships formation for more efficient operations and lower inverstment costs\nSales & Promotion\nExplore public funding/grants opportunities",
        "label3_title": "Value Propositions",
        "label3_content": "Low cost, Low power & Low maintenance (3L)\nHigh Quality of Service & Coverage\nEnvironment-friendly green-solution\nEnd-to-end IP platform\nCloud server for localized content\nHigh Data Rates",
        "label4_title": "Customer Relationships",
        "label4_content": "24/7 Customer Care Centre\nRegional Sales Executives",
        "label5_title": "Customer Segments",
        "label5_content": "Local Community\nLocal Businesses\nPublic Authorities\nLocal Retail Market\nResearch & Academia\nEnvironmental engineers",
        "label6_title": "Channels",
        "label6_content": "Co-branded outlets\nPartnership Telcos/ISPs\nDirect Sales\nWebsite\nSocial media channels",
        "label7_title": "Key Resources",
        "label7_content": "Physical resources (technologies, machineries)\nFinancial resources (Investors and Venture Funding)\nPassionate Management",
        "label9_title": "Cost Structure",
        "label9_content": "Manufacturing & Sourcing\nWi-Fi integration, funding, deployment, operation & maintenance\nEmployees & office\nR&D & Training\nSales & Marketing",
        "label10_title": "Revenue Streams",
        "label10_content": "Subscription fee\nSpecialised B2B offerings for rural businesses and cooperatives\nContent/ Application services revenue share\nGovernment subsidies for rural connectivity\nMobile Data offload revenues\nNetwork slice rent (technology, software, mobile and broadband network providers)\nHaas. Rent the set of hardware including deployment of the network (The client would be billed monthly, based on systems deployed).\nIaas. The client should use his own hardware or purchase a device from us. Charge the client based on the amount of data to be transmitted."
    },
    {
        "unique_id": 78,
        "sector": ["Agriculture","Aquaculture"],
        "service": "",
        "user_type": "Public Authority",
        "label1_title": "Partnerships/Suppliers/Collaborators",
        "label1_content": "Municipality, Regional Government, National Government, European Union, Universities, Development Agencies, Private sector (e.g. MNOs, ISPs), Financial Institutions",
        "label2_title": "Other Stakeholders",
        "label2_content": "Society, NGOs, Chamber of Tradesmen, Agricultural Organisations, Aquaculture Organisations and Civil Aviation",
        "label3_title": "Resources",
        "label3_content": "Financial Resources\nHuman Resources (administrative staff, legal staff, software developers advisors, technical support etc.)\nMaterials for construction\nEquipment for infrastructure\nIT (computers, software, etc.)\nLegislative Framework",
        "label4_title": "Capabilities",
        "label4_content": "Systemic, strategic and entrepreneurial vision\nMultidisciplinary knowledge\nAnalytical and forecast capacity\nAbility to think, develop studies and plans",
        "label5_title": "Proccesses",
        "label5_content": "Strategic Process Management\nElaboration of development plans\nLocal and regional potential mapping\nIdentification of economic potential\nManagement of environmental impacts\nElaboration of policies,plans and programs\nPublic Agreements Management\nPrivate Partnerships Management and Cooperation Agreements",
        "label6_title": "Outputs/Products",
        "label6_content": "Laws and regulatory frameworks for: Internet connectivity\nLaws and regulatory frameworks for: Satellite operations (state, regional and sectoral levels)\nLaws and regulatory frameworks for: Drones operations (state, regional and sectoral levels)\nLaws and regulatory frameworks for: Veterinary Livestock consulting (state, regional and sectoral levels)",
        "label7_title": "Beneficiaries",
        "label7_content": "Society\nMunicipalities\nCompanies from all the industries (especially Agriculture, Aquaculture)\nEducational institutions",
        "label9_title": "Outcomes",
        "label9_content": "Universal and democratic access to digital connectivity\nPossible increase of per capita income\nJob creation\nHigher Human Development Index (HDI)\nEconomic and societal strengthening of municipalities and strategic regions of the state\nSustainable Agriculture\nSustainable Aquaculture\nProtection of water\nEnhance citizens’ digital literacy\nReduction of social inequality",
        "label10_title": "Public Value",
        "label10_content": "Provide universal access to High-Speed Internet services by means of trustable and credible service, generating a fair and prosperous state that guarantees the well-being of the population as well as the financial sustainability of SMEs."
    },
    {
        "unique_id": 79,
        "sector": ["Forestry","Aquaculture"],
        "service": "",
        "user_type": "End-User",
        "label1_title": "Key Partners",
        "label1_content": "Internet service providers (ISPs)\nTechnology and Data providers \nApplication providers (data analytics)\nFinancial providers (e.g. banks)\nGovernment and Regulatory bodies",
        "label2_title": "Key Activities",
        "label2_content": "Water quality monitoring \nAutomated area surveillance",
        "label3_title": "Value Propositions",
        "label3_content": "Enhancing the efficiency, competitiveness and sustainability of aquacultural practices through improved services, optimized operations, cost reduction, upgraded network quality, and environmental protection.\nPeriodic data transmission of the water quality parameters in the environment.\nMonitor and assess forest health and fire risk, evaluate illegal logging, track wildlife movement, and prevent losses and damages.",
        "label4_title": "Customer Relationships",
        "label4_content": "Personal assistance\nCo-creation (tailor made)",
        "label5_title": "Customer Segments",
        "label5_content": "Local businesses\nPublic authorities\nAgricultural sector\nEnvironmental agencies\nEquipment operators/users (drones, sensors, etc.)",
        "label6_title": "Channels",
        "label6_content": "Social media channels\nWebsite\nCommunity workshops\nCollaborations with local cooperatives",
        "label7_title": "Key Resources",
        "label7_content": "Human resources (e.g. technicians,engineers, IT professionals)\nPhysical resources (e.g. drones, devices, cameras)",
        "label9_title": "Cost Structure",
        "label9_content": "Fixed costs for infrastructure and maintenance\nOperational costs",
        "label10_title": "Revenue Streams",
        "label10_content": "Increased profitability via increased production\nIncreased profitability via cost reductions\nSelling quaculture products (B2B/B2C)\nMonetise the use of smart infrastructure for testing/evaluating services & solutions.  (Pay per hour/montly/per lesson)"
    },
    {
        "unique_id": 80,
        "sector": ["Forestry","Aquaculture"],
        "service": "",
        "user_type": "Internet Service Provider (ISP)",
        "label1_title": "Key Partners",
        "label1_content": "Other Mobile Network Operators (MNOs) & Internet service providers (ISPs)\nTechnology and Data providers \nNGOs\nAcademic Institutions\nSystem Integrators\nFinancial providers (e.g. banks)\nGovernment and Regulatory bodies\nApplication developers (data analytics)\nTechnical consultants/ advisors",
        "label2_title": "Key Activities",
        "label2_content": "Local Manufacturing & Sourcing\nAlliances & Partnerships formation for more efficient operations and lower inverstment costs\nSales & Promotion\nExplore public funding/grants opportunities",
        "label3_title": "Value Propositions",
        "label3_content": "Low cost, Low power & Low maintenance (3L)\nHigh QOS & Coverage\nEnvironment-friendly green-solution\nEnd-to-end IP platform\nCloud server for localized content\nHigh Data Rates",
        "label4_title": "Customer Relationships",
        "label4_content": "24/7 Customer Care Centre\nMicro Operators/VLEs\nRegional Sales Executives",
        "label5_title": "Customer Segments",
        "label5_content": "Local Community\nLocal Businesses\nPublic Authorities\nLocal Retail Market\nResearch & Academia\nEnvironmental engineers",
        "label6_title": "Channels",
        "label6_content": "Micro Operators/ VLEs\nCo-branded outlets\nPartnership Telcos/ISPs\nDirect Sales\nWebsite\nSocial media channels",
        "label7_title": "Key Resources",
        "label7_content": "Physical resources (technologies, machineries)\nFinancial resources (Investors and Venture Funding)\nPassionate Management",
        "label9_title": "Cost Structure",
        "label9_content": "Manufacturing & Sourcing\nWi-Fi integration, funding, deployment, operation & maintenance\nEmployees & office\nR&D & Training\nSales & Marketing",
        "label10_title": "Revenue Streams",
        "label10_content": "Subscription fee\nSpecialised B2B offerings for rural businesses and cooperatives\nContent/ Application services revenue share\nGovernment subsidies for rural connectivity\nMobile Data offload revenues\nNetwork slice rent (technology, software, mobile and broadband network providers)\nHaas. Rent the set of hardware including deployment of the network (The client would be billed monthly, based on the systems deployed).\nIaas. The client should use his own hardware or purchase a device from us. Charge the client based on the amount of data to be transmitted."
    },
    {
        "unique_id": 81,
        "sector": ["Forestry","Aquaculture"],
        "service": "",
        "user_type": "Public Authority",
        "label1_title": "Partnerships/Suppliers/Collaborators",
        "label1_content": "Municipality, Regional Government, National Government, European Union, Universities, Development Agencies, Private sector (e.g. MNOs, ISPs), Financial Institutions",
        "label2_title": "Other Stakeholders",
        "label2_content": "Society, NGOs, Chamber of Tradesmen, Aquaculture Organisations and Environmental Organisations",
        "label3_title": "Resources",
        "label3_content": "Financial Resources\nHuman Resources (administrative staff, legal staff, software developers advisors, technical support etc.)\nMaterials for construction\nEquipment for infrastructure\nIT (computers, software, etc.)\nLegislative Framework",
        "label4_title": "Capabilities",
        "label4_content": "Systemic, strategic and entrepreneurial vision\nMultidisciplinary knowledge\nAnalytical and forecast capacity\nAbility to think, develop studies and plans",
        "label5_title": "Proccesses",
        "label5_content": "Strategic Process Management\nElaboration of development plans\nLocal and regional potential mapping\nIdentification of economic potential\nManagement of environmental impacts\nElaboration of policies,plans and programs\nPublic Agreements Management\nPrivate Partnerships Management and Cooperation Agreements",
        "label6_title": "Outputs/Products",
        "label6_content": "Laws and regulatory frameworks for: Internet connectivity\nLaws and regulatory frameworks for: Satellite operations (state, regional and sectoral levels)\nLaws and regulatory frameworks for: Drones operations (state, regional and sectoral levels)",
        "label7_title": "Beneficiaries",
        "label7_content": "Society\nMunicipalities\nCompanies from all the industries (especially Forestry, Aquaculture)\nEducational institutions",
        "label9_title": "Outcomes",
        "label9_content": "Universal and democratic access to digital connectivity\nPossible increase of per capita income\nJob creation\nHigher Human Development Index (HDI) (HDI)\nEconomic and societal strengthening of municipalities and strategic regions of the state\nSustainable Aquaculture\nProtection and rational use of soil and water\nProtection of forests\nEnhance citizens’ digital literacy\nReduction of social inequality",
        "label10_title": "Public Value",
        "label10_content": "Provide universal access to High-Speed Internet services by means of trustable and credible service, generating a fair and prosperous state that guarantees the well-being of the population as well as the financial sustainability of SMEs."
    },
    {
        "unique_id": 82,
        "sector": ["Forestry","Agriculture"],
        "service": "",
        "user_type": "End-User",
        "label1_title": "Key Partners",
        "label1_content": "Internet service providers (ISPs)\nTechnology and Data providers \nApplication providers (data analytics)\nFinancial providers (e.g. banks)\nGovernment and Regulatory bodies",
        "label2_title": "Key Activities",
        "label2_content": "Real-time & periodic crop monitoring\nReal-time & periodic pest detection\nReal-time & periodic livestock monitoring\nAutomated area surveillance\nAutonomous Driving (Agricultural sector)",
        "label3_title": "Value Propositions",
        "label3_content": "Enhancing the efficiency and sustainability of agricultural practices through improved services, optimized operations, cost reduction, upgraded network quality, and environmental protection.\nMonitor and assess forest health and fire risk, evaluate illegal logging, track wildlife movement, and prevent losses and damages.",
        "label4_title": "Customer Relationships",
        "label4_content": "Personal assistance\nCo-creation (tailor made)",
        "label5_title": "Customer Segments",
        "label5_content": "Local businesses\nPublic authorities\nAgricultural sector\nAquaculture sector\nVeterinary sector\nEnvironmental agencies\nEquipment operators/users (drones, sensors, etc.)",
        "label6_title": "Channels",
        "label6_content": "Social media channels\nWebsite",
        "label7_title": "Key Resources",
        "label7_content": "Human resources (e.g. technicians,engineers, IT professionals)\nPhysical resources (e.g. drones, devices, cameras)",
        "label9_title": "Cost Structure",
        "label9_content": "Fixed costs for infrastructure and maintenance\nOperational costs",
        "label10_title": "Revenue Streams",
        "label10_content": "Increased profitability via increased production\nIncreased profitability via cost reductions\nSelling Agricultural products (B2B/B2C)\nMonetise the use of smart farming infrastructure for testing/evaluating services & solutions.  (Pay per hour/montly/per project)"
    },
    {
        "unique_id": 83,
        "sector": ["Forestry","Agriculture"],
        "service": "",
        "user_type": "Internet Service Provider (ISP)",
        "label1_title": "Key Partners",
        "label1_content": "Other Mobile Network Operators (MNOs) & Internet service providers (ISPs)\nTechnology and Data providers \nNGOs\nAcademic Institutions\nSystem Integrators\nFinancial providers (e.g. banks)\nGovernment and Regulatory bodies\nApplication developers (data analytics)\nTechnical consultants/ advisors",
        "label2_title": "Key Activities",
        "label2_content": "Local Manufacturing & Sourcing\nAlliances & Partnerships formation for more efficient operations and lower inverstment costs\nSales & Promotion\nExplore public funding/grants opportunities",
        "label3_title": "Value Propositions",
        "label3_content": "Low cost, Low power & Low maintenance (3L)\nHigh QOS & Coverage\nEnvironment-friendly green-solution\nEnd-to-end IP platform\nCloud server for localized content\nHigh Data Rates",
        "label4_title": "Customer Relationships",
        "label4_content": "24/7 Customer Care Centre\nMicro Operators/VLEs\nRegional Sales Executives",
        "label5_title": "Customer Segments",
        "label5_content": "Local Community\nLocal Businesses\nPublic Authorities\nLocal Retail Market\nResearch & Academia\nEnvironmental engineers",
        "label6_title": "Channels",
        "label6_content": "Micro Operators/ VLEs\nCo-branded outlets\nPartnership Telcos/ISPs\nDirect Sales\nWebsite\nSocial media channels",
        "label7_title": "Key Resources",
        "label7_content": "Physical resources (technologies, machineries)\nFinancial resources (Investors and Venture Funding)\nPassionate Management",
        "label9_title": "Cost Structure",
        "label9_content": "Manufacturing & Sourcing\nWi-Fi integration, funding, deployment, operation & maintenance\nEmployees & office\nR&D & Training\nSales & Marketing",
        "label10_title": "Revenue Streams",
        "label10_content": "Subscription fee\nSpecialised B2B offerings for rural businesses and cooperatives\nContent/ Application services revenue share\nGovernment subsidies for rural connectivity\nMobile Data offload revenues\nNetwork slice rent (technology, software, mobile and broadband network providers)\nHaas. Rent the set of hardware including deployment of the network (The client would be billed monthly, based on the systems deployed).\nIaas. The client should use his own hardware or purchase a device from us. Charge the client based on the amount of data to be transmitted."
    },
    {
        "unique_id": 84,
        "sector": ["Forestry","Agriculture"],
        "service": "",
        "user_type": "Public Authority",
        "label1_title": "Partnerships/Suppliers/Collaborators",
        "label1_content": "Municipality, Regional Government, National Government, European Union, Universities, Development Agencies, Private sector (e.g. MNOs, ISPs), Financial Institutions",
        "label2_title": "Other Stakeholders",
        "label2_content": "Society, NGOs, Chamber of Tradesmen, Fire Departments, Civil Aviation, Veterinary Organisations, Forestry Organisations, Agricultural Organisations, Environmental Organisations",
        "label3_title": "Resources",
        "label3_content": "Financial Resources\nHuman Resources (administrative staff, legal staff, software developers advisors, technical support etc.)\nMaterials for construction\nEquipment for infrastructure\nIT (computers, software, etc.)\nLegislative Framework",
        "label4_title": "Capabilities",
        "label4_content": "Systemic, strategic and entrepreneurial vision\nMultidisciplinary knowledge\nAnalytical and forecast capacity\nAbility to think, develop studies and plans",
        "label5_title": "Proccesses",
        "label5_content": "Strategic Process Management\nElaboration of development plans\nLocal and regional potential mapping\nIdentification of economic potential\nManagement of environmental impacts\nElaboration of policies,plans and programs\nPublic Agreements Management\nPrivate Partnerships Management and Cooperation Agreements",
        "label6_title": "Outputs/Products",
        "label6_content": "Laws and regulatory frameworks for: Internet connectivity\nLaws and regulatory frameworks for: Drones operations (state, regional and sectoral levels)\nLaws and regulatory frameworks for: Veterinary operations (state, regional and sectoral levels)",
        "label7_title": "Beneficiaries",
        "label7_content": "Society\nMunicipalities\nCompanies from all the industries (especially Agriculture, Forestry)\nEducational institutions\n",
        "label9_title": "Outcomes",
        "label9_content": "Universal and democratic access to digital connectivity\nPossible increase of per capita income\nJob creation\nHigher Human Development Index (HDI) (HDI)\nEconomic and societal strengthening of municipalities and strategic regions of the state\nSustainable Agriculture\nProtection and rational use of soil and water\nProtection of forests\nEnhance citizens’ digital literacy\nReduction of social inequality",
        "label10_title": "Public Value",
        "label10_content": "Provide universal access to High-Speed Internet services by means of trustable and credible service, generating a fair and prosperous state that guarantees the well-being of the population as well as the financial sustainability of SMEs."
    },
    {
        "unique_id": 85,
        "sector": ["Environmental monitoring","Aquaculture"],
        "service": "",
        "user_type": "End-User",
        "label1_title": "Key Partners",
        "label1_content": "Internet service providers (ISPs)\nTechnology and Data providers \nApplication providers (data analytics)\nFinancial providers (e.g. banks)\nGovernment and Regulatory bodies\nAcademic community",
        "label2_title": "Key Activities",
        "label2_content": "Automated area surveillance\nPeriodic data transmission of the air quality, water quality, noise and biodiversity parameters in the environment ",
        "label3_title": "Value Propositions",
        "label3_content": "Human resource and operations optimization and cost reduction reduce the overall impact of operations on the environment.\nEnhancing the efficiency, competitiveness and sustainability of aquacultural practices through improved services, optimized operations, cost reduction, upgraded network quality, and environmental protection.\nPeriodic data transmission of the water quality parameters in the environment.\nVast area coverage (multiple data sources).",
        "label4_title": "Customer Relationships",
        "label4_content": "Personal assistance\nCo-creation (tailor made)",
        "label5_title": "Customer Segments",
        "label5_content": "Local businesses\nPublic authorities\nAquaculture sector\nForestry sector\nEnvironmental agencies\nEquipment operators/users (drones, sensors, etc.)\nResearch & Academia",
        "label6_title": "Channels",
        "label6_content": "Social media channels\nWebsite\nConferences and exhibitions\nCommunity workshops\nCollaborations with local cooperatives",
        "label7_title": "Key Resources",
        "label7_content": "Human resources (e.g. technicians,engineers, IT professionals)\nPhysical resources (e.g. drones, devices, cameras)",
        "label9_title": "Cost Structure",
        "label9_content": "Fixed costs for infrastructure and maintenance\nOperational costs",
        "label10_title": "Revenue Streams",
        "label10_content": "Increased profitability via increased production\nIncreased profitability via cost reductions\nSelling Aquaculture products (B2B/B2C)\nSelling environmental monitoring services of any kind (B2B/B2Goverment)\nMonetise the use of smart infrastructure for testing/evaluating services & solutions.  (Pay per hour/montly/per project)"
    },
    {
        "unique_id": 86,
        "sector": ["Environmental monitoring","Aquaculture"],
        "service": "",
        "user_type": "Internet Service Provider (ISP)",
        "label1_title": "Key Partners",
        "label1_content": "Other Mobile Network Operators (MNOs) & Internet service providers (ISPs)\nTechnology and Data providers \nNGOs\nAcademic Institutions\nSystem Integrators\nFinancial providers (e.g. banks)\nGovernment and Regulatory bodies\nApplication developers (data analytics)\nTechnical consultants/ advisors",
        "label2_title": "Key Activities",
        "label2_content": "Local Manufacturing & Sourcing\nAlliances & Partnerships formation for more efficient operations and lower inverstment costs\nSales & Promotion\nExplore public funding/grants opportunities",
        "label3_title": "Value Propositions",
        "label3_content": "Low cost, Low power & Low maintenance (3L)\nHigh QOS & Coverage\nEnvironment-friendly green-solution\nEnd-to-end IP platform\nCloud server for localized content\nHigh Data Rates",
        "label4_title": "Customer Relationships",
        "label4_content": "24/7 Customer Care Centre\nMicro Operators/VLEs\nRegional Sales Executives",
        "label5_title": "Customer Segments",
        "label5_content": "Local Community\nLocal Businesses\nPublic Authorities\nLocal Retail Market\nResearch & Academia\nEnvironmental engineers",
        "label6_title": "Channels",
        "label6_content": "Micro Operators/ VLEs\nCo-branded outlets\nPartnership Telcos/ISPs\nDirect Sales\nWebsite\nSocial media channels\nConferences and exhibitions",
        "label7_title": "Key Resources",
        "label7_content": "Physical resources (technologies, machineries)\nFinancial resources (Investors and Venture Funding)\nPassionate Management",
        "label9_title": "Cost Structure",
        "label9_content": "Manufacturing & Sourcing\nWi-Fi integration, funding, deployment, operation & maintenance\nEmployees & office\nR&D & Training\nSales & Marketing",
        "label10_title": "Revenue Streams",
        "label10_content": "Subscription fee\nSpecialised B2B offerings for rural businesses and cooperatives\nContent/ Application services revenue share\nGovernment subsidies for rural connectivity\nMobile Data offload revenues\nNetwork slice rent (technology, software, mobile and broadband network providers)\nHaas. Rent the set of hardware including deployment of the network (The client would be billed monthly, based on the systems deployed).\nIaas. The client should use his own hardware or purchase a device from us. Charge the client based on the amount of data to be transmitted."
    },
    {
        "unique_id": 87,
        "sector": ["Environmental monitoring","Aquaculture"],
        "service": "",
        "user_type": "Public Authority",
        "label1_title": "Partnerships/Suppliers/Collaborators",
        "label1_content": "Municipality, Regional Government, National Government, European Union, Universities, Development Agencies, Private sector (e.g. MNOs, ISPs), Financial Institutions",
        "label2_title": "Other Stakeholders",
        "label2_content": "Society, NGOs, Chamber of Tradesmen, Aquaculture Organisations",
        "label3_title": "Resources",
        "label3_content": "Financial Resources\nHuman Resources (administrative staff, legal staff, software developers advisors, technical support etc.)\nMaterials for construction\nEquipment for infrastructure\nIT (computers, software, etc.)\nLegislative Framework",
        "label4_title": "Capabilities",
        "label4_content": "Systemic, strategic and entrepreneurial vision\nMultidisciplinary knowledge\nAnalytical and forecast capacity\nAbility to think, develop studies and plans",
        "label5_title": "Proccesses",
        "label5_content": "Strategic Process Management\nElaboration of development plans\nLocal and regional potential mapping\nIdentification of economic potential\nManagement of environmental impacts\nElaboration of policies,plans and programs\nPublic Agreements Management\nPrivate Partnerships Management and Cooperation Agreements",
        "label6_title": "Outputs/Products",
        "label6_content": "Laws and regulatory frameworks for: Internet connectivity\nLaws and regulatory frameworks for: Satellite operations (state, regional and sectoral levels)\nLaws and regulatory frameworks for: Drones operations (state, regional and sectoral levels)",
        "label7_title": "Beneficiaries",
        "label7_content": "Society\nMunicipalities\nCompanies from all the industries (especially Environmental monitoring, Aquaculture)\nEducational institutions",
        "label9_title": "Outcomes",
        "label9_content": "Universal and democratic access to digital connectivity\nPossible increase of per capita income\nJob creation\nHigher Human Development Index (HDI) (HDI)\nEconomic and societal strengthening of municipalities and strategic regions of the state\nSustainable Aquaculture\nProtection and rational use of soil and water\nEnhance citizens’ digital literacy\nReduction of social inequality",
        "label10_title": "Public Value",
        "label10_content": "Provide universal access to High-Speed Internet services by means of trustable and credible service, generating a fair and prosperous state that guarantees the well-being of the population as well as the financial sustainability of SMEs."
    },
    {
        "unique_id": 88,
        "sector": ["Environmental monitoring","Agriculture"],
        "service": "",
        "user_type": "End-User",
        "label1_title": "Key Partners",
        "label1_content": "Internet service providers (ISPs)\nTechnology and Data providers \nApplication providers (data analytics)\nFinancial providers (e.g. banks)\nGovernment and Regulatory bodies\nAcademic community",
        "label2_title": "Key Activities",
        "label2_content": "Real-time & periodic crop monitoring\nReal-time & periodic pest detection\nAutomated area surveillance\nAutonomous Driving\nReal-time & periodic livestock monitoring\nAutonomous Driving\nPeriodic data transmission of the air quality, water quality, noise and biodiversity parameters in the environment ",
        "label3_title": "Value Propositions",
        "label3_content": "Enhancing the efficiency and sustainability of agricultural practices through improved services, optimized operations, cost reduction, upgraded network quality, and environmental protection.\nHuman resource and operations optimization and cost reduction reduce the overall impact of operations on the environment.\nVast area coverage (multiple data sources).",
        "label4_title": "Customer Relationships",
        "label4_content": "Personal assistance\nCo-creation (tailor made)",
        "label5_title": "Customer Segments",
        "label5_content": "Local businesses\nPublic authorities\nAgricultural sector\nVeterinary sector\nEnvironmental agencies\nEquipment operators/users (drones, sensors, etc.)\nResearch & Academia",
        "label6_title": "Channels",
        "label6_content": "Social media channels\nWebsite\nConferences and exhibitions",
        "label7_title": "Key Resources",
        "label7_content": "Human resources (e.g. technicians,engineers, IT professionals)\nPhysical resources (e.g. drones, devices, cameras)",
        "label9_title": "Cost Structure",
        "label9_content": "Fixed costs for infrastructure and maintenance\nOperational costs",
        "label10_title": "Revenue Streams",
        "label10_content": "Increased profitability via increased production\nIncreased profitability via cost reductions\nSelling Agricultural products (B2B/B2C)\nSelling environmental monitoring services of any kind (B2B/B2Goverment)\nMonetise the use of smart infrastructure for testing/evaluating services & solutions.  (Pay per hour/montly/per project)"
    },
    {
        "unique_id": 89,
        "sector": ["Environmental monitoring","Agriculture"],
        "service": "",
        "user_type": "Internet Service Provider (ISP)",
        "label1_title": "Key Partners",
        "label1_content": "Other Mobile Network Operators (MNOs) & Internet service providers (ISPs)\nTechnology and Data providers \nNGOs\nAcademic Institutions\nSystem Integrators\nFinancial providers (e.g. banks)\nGovernment and Regulatory bodies\nApplication developers (data analytics)\nTechnical consultants/ advisors",
        "label2_title": "Key Activities",
        "label2_content": "Local Manufacturing & Sourcing\nAlliances & Partnerships formation for more efficient operations and lower inverstment costs\nSales & Promotion\nExplore public funding/grants opportunities",
        "label3_title": "Value Propositions",
        "label3_content": "Low cost, Low power & Low maintenance (3L)\nHigh QOS & Coverage\nEnvironment-friendly green-solution\nEnd-to-end IP platform\nCloud server for localized content\nHigh Data Rates",
        "label4_title": "Customer Relationships",
        "label4_content": "24/7 Customer Care Centre\nMicro Operators/VLEs\nRegional Sales Executives",
        "label5_title": "Customer Segments",
        "label5_content": "Local Community\nLocal Businesses\nPublic Authorities\nLocal Retail Market\nResearch & Academia\nEnvironmental engineers",
        "label6_title": "Channels",
        "label6_content": "Micro Operators/ VLEs\nCo-branded outlets\nPartnership Telcos/ISPs\nDirect Sales\nWebsite\nSocial media channels\nConferences and exhibitions",
        "label7_title": "Key Resources",
        "label7_content": "Physical resources (technologies, machineries)\nFinancial resources (Investors and Venture Funding)\nPassionate Management",
        "label9_title": "Cost Structure",
        "label9_content": "Manufacturing & Sourcing\nWi-Fi integration, funding, deployment, operation & maintenance\nEmployees & office\nR&D & Training\nSales & Marketing",
        "label10_title": "Revenue Streams",
        "label10_content": "Subscription fee\nSpecialised B2B offerings for rural businesses and cooperatives\nContent/ Application services revenue share\nGovernment subsidies for rural connectivity\nMobile Data offload revenues\nNetwork slice rent (technology, software, mobile and broadband network providers)\nHaas. Rent the set of hardware including deployment of the network (The client would be billed monthly, based on the systems deployed).\nIaas. The client should use his own hardware or purchase a device from us. Charge the client based on the amount of data to be transmitted."
    },
    {
        "unique_id": 90,
        "sector": ["Environmental monitoring","Agriculture"],
        "service": "",
        "user_type": "Public Authority",
        "label1_title": "Partnerships/Suppliers/Collaborators",
        "label1_content": "Municipality, Regional Government, National Government, European Union, Universities, Development Agencies, Private sector (e.g. MNOs, ISPs), Financial Institutions",
        "label2_title": "Other Stakeholders",
        "label2_content": "Society, NGOs, Chamber of Tradesmen, Veterinary Organisations, Agricultural Organisations and Civil Aviation",
        "label3_title": "Resources",
        "label3_content": "Financial Resources\nHuman Resources (administrative staff, legal staff, software developers advisors, technical support etc.)\nMaterials for construction\nEquipment for infrastructure\nIT (computers, software, etc.)\nLegislative Framework",
        "label4_title": "Capabilities",
        "label4_content": "Systemic, strategic and entrepreneurial vision\nMultidisciplinary knowledge\nAnalytical and forecast capacity\nAbility to think, develop studies and plans",
        "label5_title": "Proccesses",
        "label5_content": "Strategic Process Management\nElaboration of development plans\nLocal and regional potential mapping\nIdentification of economic potential\nManagement of environmental impacts\nElaboration of policies,plans and programs\nPublic Agreements Management\nPrivate Partnerships Management and Cooperation Agreements",
        "label6_title": "Outputs/Products",
        "label6_content": "Laws and regulatory frameworks for: Internet connectivity\nSatellite operations (state, regional and sectoral levels)\nLaws and regulatory frameworks for: Drones operations (state, regional and sectoral levels)\nLaws and regulatory frameworks for: Veterinary Livestock consulting (state, regional and sectoral levels)",
        "label7_title": "Beneficiaries",
        "label7_content": "Society\nMunicipalities\nCompanies from all the industries (especially Environmental monitoring, Agriculture)\nEducational institutions",
        "label9_title": "Outcomes",
        "label9_content": "Universal and democratic access to digital connectivity\nPossible increase of per capita income\nJob creation\nHigher Human Development Index (HDI)\nEconomic and societal strengthening of municipalities and strategic regions of the state\nSustainable Agriculture\nProtection and rational use of soil and water\nEnhance citizens’ digital literacy\nReduction of social inequality",
        "label10_title": "Public Value",
        "label10_content": "Provide universal access to High-Speed Internet services by means of trustable and credible service, generating a fair and prosperous state that guarantees the well-being of the population as well as the financial sustainability of SMEs."
    },
    {
        "unique_id": 91,
        "sector": ["Environmental monitoring","Forestry"],
        "service": "",
        "user_type": "End-User",
        "label1_title": "Key Partners",
        "label1_content": "Internet service providers (ISPs)\nTechnology and Data providers \nApplication providers (data analytics)\nFinancial providers (e.g. banks)\nGovernment and Regulatory bodies\nAcademic community",
        "label2_title": "Key Activities",
        "label2_content": "Automated area surveillance\nPeriodic data transmission of the air quality, water quality, noise and biodiversity parameters in the environment ",
        "label3_title": "Value Propositions",
        "label3_content": "Human resource and operations optimization and cost reduction reduce the overall impact of operations on the environment.\nMonitor and assess forest health and fire risk, evaluate illegal logging, track wildlife movement, and prevent losses and damages.\nVast area coverage (multiple data sources).",
        "label4_title": "Customer Relationships",
        "label4_content": "Personal assistance\nCo-creation (tailor made)",
        "label5_title": "Customer Segments",
        "label5_content": "Local businesses\nPublic authorities\nForestry sector\nEnvironmental agencies\nEquipment operators/users (drones, sensors, etc.)\nResearch & Academia",
        "label6_title": "Channels",
        "label6_content": "Social media channels\nWebsite\nConferences and exhibitions\nCommunity workshops\nCollaborations with local cooperatives",
        "label7_title": "Key Resources",
        "label7_content": "Human resources (e.g. technicians,engineers, IT professionals)\nPhysical resources (e.g. drones, devices, cameras)",
        "label9_title": "Cost Structure",
        "label9_content": "Fixed costs for Infrastructure and maintenance\nOperational costs",
        "label10_title": "Revenue Streams",
        "label10_content": "Increased profitability via increased production\nIncreased profitability via cost reductions\nSelling environmental monitoring services of any kind (B2B/B2Goverment)"
    },
    {
        "unique_id": 92,
        "sector": ["Environmental monitoring","Forestry"],
        "service": "",
        "user_type": "Internet Service Provider (ISP)",
        "label1_title": "Key Partners",
        "label1_content": "Other Mobile Network Operators (MNOs) & Internet service providers (ISPs)\nTechnology and Data providers \nNGOs\nAcademic Institutions\nSystem Integrators\nFinancial providers (e.g. banks)\nGovernment and Regulatory bodies\nApplication developers (data analytics)\nTechnical consultants/ advisors",
        "label2_title": "Key Activities",
        "label2_content": "Local Manufacturing & Sourcing\nAlliances & Partnerships formation for more efficient operations and lower inverstment costs\nSales & Promotion\nExplore public funding/grants opportunities",
        "label3_title": "Value Propositions",
        "label3_content": "Low cost, Low power & Low maintenance (3L)\nHigh QOS & Coverage\nEnvironment-friendly green-solution\nEnd-to-end IP platform\nCloud server for localized content\nHigh Data Rates",
        "label4_title": "Customer Relationships",
        "label4_content": "24/7 Customer Care Centre\nMicro Operators/VLEs\nRegional Sales Executives",
        "label5_title": "Customer Segments",
        "label5_content": "Local Community\nLocal Businesses\nPublic Authorities\nLocal Retail Market\nResearch & Academia\nEnvironmental engineers",
        "label6_title": "Channels",
        "label6_content": "Micro Operators/ VLEs\nCo-branded outlets\nPartnership Telcos/ISPs\nDirect Sales\nWebsite\nSocial media channels\nConferences and exhibitions",
        "label7_title": "Key Resources",
        "label7_content": "Physical resources (technologies, machineries)\nFinancial resources (Investors and Venture Funding)\nPassionate Management",
        "label9_title": "Cost Structure",
        "label9_content": "Manufacturing & Sourcing\nWi-Fi integration, funding, deployment, operation & maintenance\nEmployees & office\nR&D & Training\nSales & Marketing",
        "label10_title": "Revenue Streams",
        "label10_content": "Subscription fee\nSpecialised B2B offerings for rural businesses and cooperatives\nContent/ Application services revenue share\nGovernment subsidies for rural connectivity\nMobile Data offload revenues\nNetwork slice rent (technology, software, mobile and broadband network providers)\nHaas. Rent the set of hardware including deployment of the network (The client would be billed monthly, based on the systems deployed).\nIaas. The client should use his own hardware or purchase a device from us. Charge the client based on the amount of data to be transmitted."
    },
    {
        "unique_id": 93,
        "sector": ["Environmental monitoring","Forestry"],
        "service": "",
        "user_type": "Public Authority",
        "label1_title": "Partnerships/Suppliers/Collaborators",
        "label1_content": "Municipality, Regional Government, National Government, European Union, Universities, Development Agencies, Private sector (e.g. MNOs, ISPs), Financial Institutions",
        "label2_title": "Other Stakeholders",
        "label2_content": "Society, NGOs, Chamber of Tradesmen, Fire Departments, Civil Aviation, Forestry Organisations, Agricultural Organisations, Environmental Organisations",
        "label3_title": "Resources",
        "label3_content": "Financial Resources\nHuman Resources (administrative staff, legal staff, software developers advisors, technical support etc.)\nMaterials for construction\nEquipment for infrastructure\nIT (computers, software, etc.)\nLegislative Framework",
        "label4_title": "Capabilities",
        "label4_content": "Systemic, strategic and entrepreneurial vision\nMultidisciplinary knowledge\nAnalytical and forecast capacity\nAbility to think, develop studies and plans",
        "label5_title": "Proccesses",
        "label5_content": "Strategic Process Management\nElaboration of development plans\nLocal and regional potential mapping\nIdentification of economic potential\nManagement of environmental impacts\nElaboration of policies,plans and programs\nPublic Agreements Management\nPrivate Partnerships Management and Cooperation Agreements",
        "label6_title": "Outputs/Products",
        "label6_content": "Laws and regulatory frameworks for: Internet connectivity\nLaws and regulatory frameworks for: Satellite operations (state, regional and sectoral levels)",
        "label7_title": "Beneficiaries",
        "label7_content": "Society\nMunicipalities\nCompanies from all the industries (especially Environmental monitoring, Forestry)\nEducational institutions",
        "label9_title": "Outcomes",
        "label9_content": "Universal and democratic access to digital connectivity\nPossible increase of per capita income\nJob creation\nHigher Human Development Index (HDI)\nEconomic and societal strengthening of municipalities and strategic regions of the state\nProtection and rational use of soil and water\nProtection of forests\nEnhance citizens’ digital literacy\nReduction of social inequality",
        "label10_title": "Public Value",
        "label10_content": "Provide universal access to High-Speed Internet services by means of trustable and credible service, generating a fair and prosperous state that guarantees the well-being of the population as well as the financial sustainability of SMEs."
    },
    {
        "unique_id": 94,
        "sector": ["eHealth","Aquaculture"],
        "service": "",
        "user_type": "End-User",
        "label1_title": "Key Partners",
        "label1_content": "Internet service providers (ISPs)\nTechnology and Data providers \nApplication providers (data analytics)\nFinancial providers (e.g. banks)\nGovernment and Regulatory bodies",
        "label2_title": "Key Activities",
        "label2_content": "Periodic data transmission of the water quality parameters in the environment for monitoring\nAutomated area surveillance\nProvide health and wellbeing guidance & interventions for people living in rural areas",
        "label3_title": "Value Propositions",
        "label3_content": "Enhance well-being of elderly people and support their social interactions.\nEnhancing the efficiency, competitiveness and sustainability of aquacultural practices through improved services, optimized operations, cost reduction, upgraded network quality, and environmental protection.\nPeriodic data transmission of the water quality parameters in the environment.",
        "label4_title": "Customer Relationships",
        "label4_content": "Personal assistance\nCo-creation (tailor made)",
        "label5_title": "Customer Segments",
        "label5_content": "Local businesses\nPublic authorities\nAquaculture sector\nEquipment operators/users (drones, sensors, medical devices etc.)\nRetirement Homes",
        "label6_title": "Channels",
        "label6_content": "Social media channels\nWebsite\nConferences and exhibitions\nCommunity workshops\nCollaborations with local cooperatives",
        "label7_title": "Key Resources",
        "label7_content": "Human resources (e.g. technicians,engineers, IT professionals)\nPhysical resources (e.g. drones, devices, cameras)",
        "label9_title": "Cost Structure",
        "label9_content": "Fixed costs for infrastructure and maintenance\nOperational costs",
        "label10_title": "Revenue Streams",
        "label10_content": "Increased profitability via increased production\nIncreased profitability via cost reductions\nSelling Aquaculture products (B2B/B2C)\nMonetise the use of smart infrastructure for testing/evaluating services & solutions. (Pay per hour/monthly/per project)\nMonetize the added value of robot connection in Retirement Homes"
    },
    {
        "unique_id": 95,
        "sector": ["eHealth","Aquaculture"],
        "service": "",
        "user_type": "Internet Service Provider (ISP)",
        "label1_title": "Key Partners",
        "label1_content": "Other Mobile Network Operators (MNOs) & Internet service providers (ISPs)\nTechnology and Data providers \nNGOs\nAcademic Institutions\nSystem Integrators\nFinancial providers (e.g. banks)\nGovernment and Regulatory bodies\nApplication developers (data analytics)\nTechnical consultants/ advisors",
        "label2_title": "Key Activities",
        "label2_content": "Local Manufacturing & Sourcing\nAlliances & Partnerships formation for more efficient operations and lower inverstment costs\nSales & Promotion\nExplore public funding/grants opportunities",
        "label3_title": "Value Propositions",
        "label3_content": "Low cost, Low power & Low maintenance (3L)\nHigh QOS & Coverage\nEnvironment-friendly green-solution\nEnd-to-end IP platform\nCloud server for localized content\nHigh Data Rates",
        "label4_title": "Customer Relationships",
        "label4_content": "24/7 Customer Care Centre\nMicro Operators/VLEs\nRegional Sales Executives",
        "label5_title": "Customer Segments",
        "label5_content": "Local Community\nLocal Businesses\nPublic Authorities\nLocal Retail Market\nResearch & Academia\nEnvironmental engineers",
        "label6_title": "Channels",
        "label6_content": "Micro Operators/ VLEs\nCo-branded outlets\nPartnership Telcos/ISPs\nDirect Sales\nWebsite\nSocial media channels\nConferences and exhibitions",
        "label7_title": "Key Resources",
        "label7_content": "Physical resources (technologies, machineries)\nFinancial resources (Investors and Venture Funding)\nPassionate Management",
        "label9_title": "Cost Structure",
        "label9_content": "Manufacturing & Sourcing\nWi-Fi integration, funding, deployment, operation & maintenance\nEmployees & office\nR&D & Training\nSales & Marketing",
        "label10_title": "Revenue Streams",
        "label10_content": "Subscription fee\nSpecialised B2B offerings for rural businesses and cooperatives\nContent/ Application services revenue share\nGovernment subsidies for rural connectivity\nMobile Data offload revenues\nNetwork slice rent (technology, software, mobile and broadband network providers)\nHaas. Rent the set of hardware including deployment of the network (The client would be billed monthly, based on the systems deployed).\nIaas. The client should use his own hardware or purchase a device from us. Charge the client based on the amount of data to be transmitted."
    },
    {
        "unique_id": 96,
        "sector": ["eHealth","Aquaculture"],
        "service": "",
        "user_type": "Public Authority",
        "label1_title": "Partnerships/Suppliers/Collaborators",
        "label1_content": "Municipality, Regional Government, National Government, European Union, Universities, Development Agencies, Private sector (e.g. MNOs, ISPs), Financial Institutions",
        "label2_title": "Other Stakeholders",
        "label2_content": "Society, NGOs, Chamber of Tradesmen, Aquaculture Organisations, Environmental Organisations, Medical Organisations, Public Retirement Homes",
        "label3_title": "Resources",
        "label3_content": "Financial Resources\nHuman Resources (administrative staff, legal staff, software developers advisors, technical support etc.)\nMaterials for construction\nEquipment for infrastructure\nIT (computers, software, etc.)\nLegislative Framework",
        "label4_title": "Capabilities",
        "label4_content": "Systemic, strategic and entrepreneurial vision\nMultidisciplinary knowledge\nAnalytical and forecast capacity\nAbility to think, develop studies and plans",
        "label5_title": "Proccesses",
        "label5_content": "Strategic Process Management\nElaboration of development plans\nLocal and regional potential mapping\nIdentification of economic potential\nManagement of environmental impacts\nElaboration of policies,plans and programs\nPublic Agreements Management\nPrivate Partnerships Management and Cooperation Agreements",
        "label6_title": "Outputs/Products",
        "label6_content": "Laws and regulatory frameworks for: Internet connectivity\nLaws and regulatory frameworks for: Satellite operations (state, regional and sectoral levels)\nLaws and regulatory frameworks for: Medical consulting (state, regional and sectoral levels)",
        "label7_title": "Beneficiaries",
        "label7_content": "Society\nMunicipalities\nCompanies from all the industries (especially eHealth, Aquaculture)\nEducational institutions",
        "label9_title": "Outcomes",
        "label9_content": "Universal and democratic access to digital connectivity\nPossible increase of per capita income\nJob creation\nHigher Human Development Index (HDI)\nEconomic and societal strengthening of municipalities and strategic regions of the state\nSustainable Aquaculture\nProtection of water\nEnhance citizens’ health and well-being\nEnhance citizens’ digital literacy\nReduction of social inequality",
        "label10_title": "Public Value",
        "label10_content": "Provide universal access to High-Speed Internet services by means of trustable and credible service, generating a fair and prosperous state that guarantees the well-being of the population as well as the financial sustainability of SMEs."
    },
    {
        "unique_id": 97,
        "sector": ["eHealth","Agriculture"],
        "service": "",
        "user_type": "End-User",
        "label1_title": "Key Partners",
        "label1_content": "Internet service providers (ISPs)\nTechnology and Data providers \nApplication providers (data analytics)\nFinancial providers (e.g. banks)\nGovernment and Regulatory bodies",
        "label2_title": "Key Activities",
        "label2_content": "Real-time & periodic farm monitoring\nReal-time & periodic livestock monitoring\nAutomated area surveillance\nReal-time & periodic pest detection\nAutonomous Driving (Agricultural sector)\nProvide health and wellbeing guidance & interventions for people living in rural areas.",
        "label3_title": "Value Propositions",
        "label3_content": "Enhancing the efficiency and sustainability of agricultural practices through improved services, optimized operations, cost reduction, upgraded network quality, and environmental protection.\nEnhance well-being of elderly people and support their social interactions.",
        "label4_title": "Customer Relationships",
        "label4_content": "Personal assistance\nCo-creation (tailor made)",
        "label5_title": "Customer Segments",
        "label5_content": "Local businesses\nPublic authorities\nAgricultural sector\nVeterinary sector\nEquipment operators/users (drones, sensors, medical devices etc.)\nRetirement Homes",
        "label6_title": "Channels",
        "label6_content": "Social media channels\nWebsite",
        "label7_title": "Key Resources",
        "label7_content": "Human resources (e.g. technicians,engineers, IT professionals)\nPhysical resources (e.g. drones, devices, cameras)",
        "label9_title": "Cost Structure",
        "label9_content": "Fixed costs for infrastructure and maintenance\nOperational costs",
        "label10_title": "Revenue Streams",
        "label10_content": "Increased profitability via increased production\nIncreased profitability via cost reductions\nSelling Agricultural products (B2B/B2C)\nMonetise the use of smart infrastructure for testing/evaluating services & solutions.  (Pay per hour/montly/per project)\nMonetize the added value of robot connection in Retirement Homes"
    },
    {
        "unique_id": 98,
        "sector": ["eHealth","Agriculture"],
        "service": "",
        "user_type": "Internet Service Provider (ISP)",
        "label1_title": "Key Partners",
        "label1_content": "Other Mobile Network Operators (MNOs) & Internet service providers (ISPs)\nTechnology and Data providers \nNGOs\nAcademic Institutions\nSystem Integrators\nFinancial providers (e.g. banks)\nGovernment and Regulatory bodies\nApplication developers (data analytics)\nTechnical consultants/ advisors",
        "label2_title": "Key Activities",
        "label2_content": "Local Manufacturing & Sourcing\nAlliances & Partnerships formation for more efficient operations and lower inverstment costs\nSales & Promotion\nExplore public funding/grants opportunities",
        "label3_title": "Value Propositions",
        "label3_content": "Low cost, Low power & Low maintenance (3L)\nHigh QOS & Coverage\nEnvironment-friendly green-solution\nEnd-to-end IP platform\nCloud server for localized content\nHigh Data Rates",
        "label4_title": "Customer Relationships",
        "label4_content": "24/7 Customer Care Centre\nMicro Operators/VLEs\nRegional Sales Executives",
        "label5_title": "Customer Segments",
        "label5_content": "Local Community\nLocal Businesses\nPublic Authorities\nLocal Retail Market\nResearch & Academia\nEnvironmental engineers",
        "label6_title": "Channels",
        "label6_content": "Micro Operators/ VLEs\nCo-branded outlets\nPartnership Telcos/ISPs\nDirect Sales\nWebsite\nSocial media channels",
        "label7_title": "Key Resources",
        "label7_content": "Physical resources (technologies, machineries)\nFinancial resources (Investors and Venture Funding)\nPassionate Management",
        "label9_title": "Cost Structure",
        "label9_content": "Manufacturing & Sourcing\nWi-Fi integration, funding, deployment, operation & maintenance\nEmployees & office\nR&D & Training\nSales & Marketing",
        "label10_title": "Revenue Streams",
        "label10_content": "Subscription fee\nSpecialised B2B offerings for rural businesses and cooperatives\nContent/ Application services revenue share\nGovernment subsidies for rural connectivity\nMobile Data offload revenues\nNetwork slice rent (technology, software, mobile and broadband network providers)\nHaas. Rent the set of hardware including deployment of the network (The client would be billed monthly, based on the systems deployed).\nIaas. The client should use his own hardware or purchase a device from us. Charge the client based on the amount of data to be transmitted."
    },
    {
        "unique_id": 99,
        "sector": ["eHealth","Agriculture"],
        "service": "",
        "user_type": "Public Authority",
        "label1_title": "Partnerships/Suppliers/Collaborators",
        "label1_content": "Municipality, Regional Government, National Government, European Union, Universities, Development Agencies, Private sector (e.g. MNOs, ISPs), Financial Institutions",
        "label2_title": "Other Stakeholders",
        "label2_content": "Society, NGOs, Chamber of Tradesmen, Agricultural Organisations, Civil Aviation, Medical Organisations, Public Retirement Homes",
        "label3_title": "Resources",
        "label3_content": "Financial Resources\nHuman Resources (administrative staff, legal staff, software developers advisors, technical support etc.)\nMaterials for construction\nEquipment for infrastructure\nIT (computers, software, etc.)\nLegislative Framework",
        "label4_title": "Capabilities",
        "label4_content": "Systemic, strategic and entrepreneurial vision\nMultidisciplinary knowledge\nAnalytical and forecast capacity\nAbility to think, develop studies and plans",
        "label5_title": "Proccesses",
        "label5_content": "Strategic Process Management\nElaboration of development plans\nLocal and regional potential mapping\nIdentification of economic potential\nManagement of environmental impacts\nElaboration of policies,plans and programs\nPublic Agreements Management\nPrivate Partnerships Management and Cooperation Agreements",
        "label6_title": "Outputs/Products",
        "label6_content": "Laws and regulatory frameworks for: Internet connectivity\nLaws and regulatory frameworks for: Drones operations (state, regional and sectoral levels)\nLaws and regulatory frameworks for: Veterinary operations (state, regional and sectoral levels)",
        "label7_title": "Beneficiaries",
        "label7_content": "Society\nMunicipalities\nCompanies from all the industries (especially eHealth, Agriculture)\nEducational institutions",
        "label9_title": "Outcomes",
        "label9_content": "Universal and democratic access to digital connectivity\nPossible increase of per capita income\nJob creation\nHigher Human Development Index (HDI)\nEconomic and societal strengthening of municipalities and strategic regions of the state\nSustainable Agriculture\nEnhance citizens’ health and well-being\nEnhance citizens’ digital literacy\nReduction of social inequality",
        "label10_title": "Public Value",
        "label10_content": "Provide universal access to High-Speed Internet services by means of trustable and credible service, generating a fair and prosperous state that guarantees the well-being of the population as well as the financial sustainability of SMEs."
    },
    {
        "unique_id": 100,
        "sector": ["eHealth","Forestry"],
        "service": "",
        "user_type": "End-User",
        "label1_title": "Key Partners",
        "label1_content": "Internet service providers (ISPs)\nTechnology and Data providers \nApplication providers (data analytics)\nFinancial providers (e.g. banks)\nGovernment and Regulatory bodies",
        "label2_title": "Key Activities",
        "label2_content": "Automated area surveillance\nProvide health and wellbeing guidance & interventions for people living in rural areas",
        "label3_title": "Value Propositions",
        "label3_content": "Monitor and assess forest health and fire risk, evaluate illegal logging, track wildlife movement, and prevent losses and damages.\nEnhance well-being of elderly people and support their social interactions.",
        "label4_title": "Customer Relationships",
        "label4_content": "Personal assistance\nCo-creation (tailor made)",
        "label5_title": "Customer Segments",
        "label5_content": "Local businesses\nPublic authorities\nForestry sector\nEnvironmental Agencies\nRetirement Homes",
        "label6_title": "Channels",
        "label6_content": "Social media channels\nWebsite\nCommunity workshops\nCollaborations with local cooperatives",
        "label7_title": "Key Resources",
        "label7_content": "Human resources (e.g. technicians,engineers, IT professionals)\nPhysical resources (e.g. drones, devices, cameras)",
        "label9_title": "Cost Structure",
        "label9_content": "Fixed costs for infrastructure and maintenance\nOperational costs",
        "label10_title": "Revenue Streams",
        "label10_content": "Increased profitability via increased production\nIncreased profitability via cost reductions\nMonetise the use of smart infrastructure for testing/evaluating services & solutions.  (Pay per hour/montly/per project)\nMonetize the added value of robot connection in Retirement Homes"
    },
    {
        "unique_id": 101,
        "sector": ["eHealth","Forestry"],
        "service": "",
        "user_type": "Internet Service Provider (ISP)",
        "label1_title": "Key Partners",
        "label1_content": "Other Mobile Network Operators (MNOs) & Internet service providers (ISPs)\nTechnology and Data providers \nNGOs\nAcademic Institutions\nSystem Integrators\nFinancial providers (e.g. banks)\nGovernment and Regulatory bodies\nApplication developers (data analytics)\nTechnical consultants/ advisors",
        "label2_title": "Key Activities",
        "label2_content": "Local Manufacturing & Sourcing\nAlliances & Partnerships formation for more efficient operations and lower inverstment costs\nSales & Promotion\nExplore public funding/grants opportunities",
        "label3_title": "Value Propositions",
        "label3_content": "Low cost, Low power & Low maintenance (3L)\nHigh QOS & Coverage\nEnvironment-friendly green-solution\nEnd-to-end IP platform\nCloud server for localized content\nHigh Data Rates",
        "label4_title": "Customer Relationships",
        "label4_content": "24/7 Customer Care Centre\nMicro Operators/VLEs\nRegional Sales Executives",
        "label5_title": "Customer Segments",
        "label5_content": "Local Community\nLocal Businesses\nPublic Authorities\nLocal Retail Market\nResearch & Academia\nEnvironmental engineers",
        "label6_title": "Channels",
        "label6_content": "Micro Operators/ VLEs\nCo-branded outlets\nPartnership Telcos/ISPs\nDirect Sales\nWebsite\nSocial media channels",
        "label7_title": "Key Resources",
        "label7_content": "Physical resources (technologies, machineries)\nFinancial resources (Investors and Venture Funding)\nPassionate Management",
        "label9_title": "Cost Structure",
        "label9_content": "Manufacturing & Sourcing\nWi-Fi integration, funding, deployment, operation & maintenance\nEmployees & office\nR&D & Training\nSales & Marketing",
        "label10_title": "Revenue Streams",
        "label10_content": "Subscription fee\nSpecialised B2B offerings for rural businesses and cooperatives\nContent/ Application services revenue share\nGovernment subsidies for rural connectivity\nMobile Data offload revenues\nNetwork slice rent (technology, software, mobile and broadband network providers)\nHaas. Rent the set of hardware including deployment of the network (The client would be billed monthly, based on the systems deployed).\nIaas. The client should use his own hardware or purchase a device from us. Charge the client based on the amount of data to be transmitted."
    },
    {
        "unique_id": 102,
        "sector": ["eHealth","Forestry"],
        "service": "",
        "user_type": "Public Authority",
        "label1_title": "Partnerships/Suppliers/Collaborators",
        "label1_content": "Municipality, Regional Government, National Government, European Union, Universities, Development Agencies, Private sector (e.g. MNOs, ISPs), Financial Institutions",
        "label2_title": "Other Stakeholders",
        "label2_content": "Society, NGOs, Chamber of Tradesmen, Fire Departments, Civil Aviation, Forestry Organisations, Agricultural Organisations, Environmental Organisations, Medical Organisations, Public Retirement Homes",
        "label3_title": "Resources",
        "label3_content": "Financial Resources\nHuman Resources (administrative staff, legal staff, software developers advisors, technical support etc.)\nMaterials for construction\nEquipment for infrastructure\nIT (computers, software, etc.)\nLegislative Framework",
        "label4_title": "Capabilities",
        "label4_content": "Systemic, strategic and entrepreneurial vision\nMultidisciplinary knowledge\nAnalytical and forecast capacity\nAbility to think, develop studies and plans",
        "label5_title": "Proccesses",
        "label5_content": "Strategic Process Management\nElaboration of development plans\nLocal and regional potential mapping\nIdentification of economic potential\nManagement of environmental impacts\nElaboration of policies,plans and programs\nPublic Agreements Management\nPrivate Partnerships Management and Cooperation Agreements",
        "label6_title": "Outputs/Products",
        "label6_content": "Laws and regulatory frameworks for: Internet connectivity\nLaws and regulatory frameworks for: Drones operations (state, regional and sectoral levels)\nLaws and regulatory frameworks for: Medical consulting (state, regional and sectoral levels)",
        "label7_title": "Beneficiaries",
        "label7_content": "Society\nMunicipalities\nCompanies from all the industries (especially eHealth, Forestry)\nEducational institutions",
        "label9_title": "Outcomes",
        "label9_content": "Universal and democratic access to digital connectivity\nPossible increase of per capita income\nJob creation\nHigher Human Development Index (HDI)\nEconomic and societal strengthening of municipalities and strategic regions of the state\nProtection and rational use of soil and water\nProtection of forests\nEnhance citizens’ health and well-being\nEnhance citizens’ digital literacy\nReduction of social inequality",
        "label10_title": "Public Value",
        "label10_content": "Provide universal access to High-Speed Internet services by means of trustable and credible service, generating a fair and prosperous state that guarantees the well-being of the population as well as the financial sustainability of SMEs."
    },
    {
        "unique_id": 103,
        "sector": ["eHealth","Environmental monitoring"],
        "service": "",
        "user_type": "End-User",
        "label1_title": "Key Partners",
        "label1_content": "Internet service providers (ISPs)\nTechnology and Data providers \nApplication providers (data analytics)\nFinancial providers (e.g. banks)\nGovernment and Regulatory bodies\nAcademic community",
        "label2_title": "Key Activities",
        "label2_content": "Automated area surveillance\nPeriodic data transmission of the air quality, water quality, noise and biodiversity parameters in the environment \nProvide health and wellbeing guidance & interventions for people living in rural areas",
        "label3_title": "Value Propositions",
        "label3_content": "Human resource and operations optimization and cost reduction reduce the overall impact of operations on the environment.\nVast area coverage (multiple data sources).\nEnhance well-being of elderly people and support their social interactions.",
        "label4_title": "Customer Relationships",
        "label4_content": "Personal assistance\nCo-creation (tailor made)",
        "label5_title": "Customer Segments",
        "label5_content": "Local businesses\nPublic authorities\nEnvironmental Agencies\nEquipment operators/users (drones, sensors, medical devices etc.)\nRetirement Homes\nResearch & Academia",
        "label6_title": "Channels",
        "label6_content": "Social media channels\nWebsite\nConferences and exhibitions\nCommunity workshops\nCollaborations with local cooperatives",
        "label7_title": "Key Resources",
        "label7_content": "Human resources (e.g. technicians,engineers, IT professionals)\nPhysical resources (e.g. drones, devices, cameras)",
        "label9_title": "Cost Structure",
        "label9_content": "Fixed costs for infrastructure and maintenance\nOperational costs",
        "label10_title": "Revenue Streams",
        "label10_content": "Selling environmental monitoring services of any kind (B2B/B2Goverment)\nMonetise the use of smart infrastructure for testing/evaluating services & solutions.  (Pay per hour/montly/per project)\nMonetize the added value of robot connection in Retirement Homes"
    },
    {
        "unique_id": 104,
        "sector": ["eHealth","Environmental monitoring"],
        "service": "",
        "user_type": "Internet Service Provider (ISP)",
        "label1_title": "Key Partners",
        "label1_content": "Other Mobile Network Operators (MNOs) & Internet service providers (ISPs)\nTechnology and Data providers \nNGOs\nAcademic Institutions\nSystem Integrators\nFinancial providers (e.g. banks)\nGovernment and Regulatory bodies\nApplication developers (data analytics)\nTechnical consultants/ advisors",
        "label2_title": "Key Activities",
        "label2_content": "Local Manufacturing & Sourcing\nAlliances & Partnerships formation for more efficient operations and lower inverstment costs\nSales & Promotion\nExplore public funding/grants opportunities",
        "label3_title": "Value Propositions",
        "label3_content": "Low cost, Low power & Low maintenance (3L)\nHigh QOS & Coverage\nEnvironment-friendly green-solution\nEnd-to-end IP platform\nCloud server for localized content\nHigh Data Rates",
        "label4_title": "Customer Relationships",
        "label4_content": "24/7 Customer Care Centre\nMicro Operators/VLEs\nRegional Sales Executives",
        "label5_title": "Customer Segments",
        "label5_content": "Local Community\nLocal Businesses\nPublic Authorities\nLocal Retail Market\nResearch & Academia\nEnvironmental engineers",
        "label6_title": "Channels",
        "label6_content": "Micro Operators/ VLEs\nCo-branded outlets\nPartnership Telcos/ISPs\nDirect Sales\nWebsite\nSocial media channels\nConferences and exhibitions",
        "label7_title": "Key Resources",
        "label7_content": "Physical resources (technologies, machineries)\nFinancial resources (Investors and Venture Funding)\nPassionate Management",
        "label9_title": "Cost Structure",
        "label9_content": "Manufacturing & Sourcing\nWi-Fi integration, funding, deployment, operation & maintenance\nEmployees & office\nR&D & Training\nSales & Marketing",
        "label10_title": "Revenue Streams",
        "label10_content": "Subscription fee\nSpecialised B2B offerings for rural businesses and cooperatives\nContent/ Application services revenue share\nGovernment subsidies for rural connectivity\nMobile Data offload revenues\nNetwork slice rent (technology, software, mobile and broadband network providers)\nHaas. Rent the set of hardware including deployment of the network (The client would be billed monthly, based on the systems deployed).\nIaas. The client should use his own hardware or purchase a device from us. Charge the client based on the amount of data to be transmitted."
    },
    {
        "unique_id": 105,
        "sector": ["eHealth","Environmental monitoring"],
        "service": "",
        "user_type": "Public Authority",
        "label1_title": "Partnerships/Suppliers/Collaborators",
        "label1_content": "Municipality, Regional Government, National Government, European Union, Universities, Development Agencies, Private sector (e.g. MNOs, ISPs), Financial Institutions",
        "label2_title": "Other Stakeholders",
        "label2_content": "Society, NGOs, Chamber of Tradesmen, Environmental Organisations, Medical Organisations, Public Retirement Homes",
        "label3_title": "Resources",
        "label3_content": "Financial Resources\nHuman Resources (administrative staff, legal staff, software developers advisors, technical support etc.)\nMaterials for construction\nEquipment for infrastructure\nIT (computers, software, etc.)\nLegislative Framework",
        "label4_title": "Capabilities",
        "label4_content": "Systemic, strategic and entrepreneurial vision\nMultidisciplinary knowledge\nAnalytical and forecast capacity\nAbility to think, develop studies and plans",
        "label5_title": "Proccesses",
        "label5_content": "Strategic Process Management\nElaboration of development plans\nLocal and regional potential mapping\nIdentification of economic potential\nManagement of environmental impacts\nElaboration of policies,plans and programs\nPublic Agreements Management\nPrivate Partnerships Management and Cooperation Agreements",
        "label6_title": "Outputs/Products",
        "label6_content": "Laws and regulatory frameworks for: Internet connectivity\nLaws and regulatory frameworks for: Satellite operations (state, regional and sectoral levels)\nLaws and regulatory frameworks for: Medical consulting (state, regional and sectoral levels)",
        "label7_title": "Beneficiaries",
        "label7_content": "Society\nMunicipalities\nCompanies from all the industries (especially eHealth, Environmental monitoring)\nEducational institutions",
        "label9_title": "Outcomes",
        "label9_content": "Universal and democratic access to digital connectivity\nPossible increase of per capita income\nJob creation\nHigher Human Development Index (HDI)\nEconomic and societal strengthening of municipalities and strategic regions of the state\nProtection and rational use of soil and water\nEnhance citizens’ health and well-being\nEnhance citizens’ digital literacy\nReduction of social inequality",
        "label10_title": "Public Value",
        "label10_content": "Provide universal access to High-Speed Internet services by means of trustable and credible service, generating a fair and prosperous state that guarantees the well-being of the population as well as the financial sustainability of SMEs."
    },
    {
        "unique_id": 106,
        "sector": ["eGovernance","Aquaculture"],
        "service": "",
        "user_type": "End-User",
        "label1_title": "Key Partners",
        "label1_content": "Internet service providers (ISPs)\nTechnology and Data providers \nApplication providers (data analytics)\nFinancial providers (e.g. banks)\nGovernment and Regulatory bodies",
        "label2_title": "Key Activities",
        "label2_content": "Periodic data transmission of the water quality parameters in the environment rop monitoring\nAutomated area surveillance\nProvision of various services and information to citizens, businesses, NGOs, and other stakeholders\nEnhanced government transparency, efficiency, and accessibility",
        "label3_title": "Value Propositions",
        "label3_content": "Enhancing the efficiency, competitiveness and sustainability of aquacultural practices through improved services, optimized operations, cost reduction, upgraded network quality, and environmental protection.\nPeriodic data transmission of the water quality parameters in the environment.\nReduce costs, time, and effort while increasing e-literacy, digital skills, access to learning materials, digital platforms, and enhanced communication for citizens, businesses, and stakeholders.",
        "label4_title": "Customer Relationships",
        "label4_content": "Personal assistance\nCo-creation (tailor made)",
        "label5_title": "Customer Segments",
        "label5_content": "Local businesses\nPublic authorities\nAquaculture sector\nEquipment operators/users (drones, sensors, medical devices etc.)\nCitizens",
        "label6_title": "Channels",
        "label6_content": "Social media channels\nWebsite\nConferences and exhibitions\nCommunity workshops\nCollaborations with local cooperatives",
        "label7_title": "Key Resources",
        "label7_content": "Human resources (e.g. technicians,engineers, IT professionals)\nPhysical resources (e.g. drones, devices, cameras)",
        "label9_title": "Cost Structure",
        "label9_content": "Fixed costs for infrastructure and maintenance\nOperational costs",
        "label10_title": "Revenue Streams",
        "label10_content": "Increased profitability via increased production\nIncreased profitability via cost reductions\nSelling Aquaculture products (B2B/B2C)\nMonetise the use of smart infrastructure for testing/evaluating services & solutions.  (Pay per hour/montly/per project)\nReduced time of travel to local authorities\nImprove efficiency of public authorities"
    },
    {
        "unique_id": 107,
        "sector": ["eGovernance","Aquaculture"],
        "service": "",
        "user_type": "Internet Service Provider (ISP)",
        "label1_title": "Key Partners",
        "label1_content": "Other Mobile Network Operators (MNOs) & Internet service providers (ISPs)\nTechnology and Data providers \nNGOs\nAcademic Institutions\nSystem Integrators\nFinancial providers (e.g. banks)\nGovernment and Regulatory bodies\nApplication developers (data analytics)\nTechnical consultants/ advisors",
        "label2_title": "Key Activities",
        "label2_content": "Local Manufacturing & Sourcing\nAlliances & Partnerships formation for more efficient operations and lower inverstment costs\nSales & Promotion\nExplore public funding/grants opportunities",
        "label3_title": "Value Propositions",
        "label3_content": "Low cost, Low power & Low maintenance (3L)\nHigh QOS & Coverage\nEnvironment-friendly green-solution\nEnd-to-end IP platform\nCloud server for localized content\nHigh Data Rates",
        "label4_title": "Customer Relationships",
        "label4_content": "24/7 Customer Care Centre\nMicro Operators/VLEs\nRegional Sales Executives",
        "label5_title": "Customer Segments",
        "label5_content": "Local Community\nLocal Businesses\nPublic Authorities\nLocal Retail Market\nResearch & Academia\nEnvironmental engineers",
        "label6_title": "Channels",
        "label6_content": "Micro Operators/ VLEs\nCo-branded outlets\nPartnership Telcos/ISPs\nDirect Sales\nWebsite\nSocial media channels\nConferences and exhibitions",
        "label7_title": "Key Resources",
        "label7_content": "Physical resources (technologies, machineries)\nFinancial resources (Investors and Venture Funding)\nPassionate Management",
        "label9_title": "Cost Structure",
        "label9_content": "Manufacturing & Sourcing\nWi-Fi integration, funding, deployment, operation & maintenance\nEmployees & office\nR&D & Training\nSales & Marketing",
        "label10_title": "Revenue Streams",
        "label10_content": "Subscription fee\nSpecialised B2B offerings for rural businesses and cooperatives\nContent/ Application services revenue share\nGovernment subsidies for rural connectivity\nMobile Data offload revenues\nNetwork slice rent (technology, software, mobile and broadband network providers)\nHaas. Rent the set of hardware including deployment of the network (The client would be billed monthly, based on the systems deployed).\nIaas. The client should use his own hardware or purchase a device from us. Charge the client based on the amount of data to be transmitted."
    },
    {
        "unique_id": 108,
        "sector": ["eGovernance","Aquaculture"],
        "service": "",
        "user_type": "Public Authority",
        "label1_title": "Partnerships/Suppliers/Collaborators",
        "label1_content": "Municipality, Regional Government, National Government, European Union, Universities, Development Agencies, Private sector (e.g. MNOs, ISPs), Financial Institutions",
        "label2_title": "Other Stakeholders",
        "label2_content": "Society, NGOs, Chamber of Tradesmen, Aquaculture Organisations and Environmental Organisations",
        "label3_title": "Resources",
        "label3_content": "Financial Resources\nHuman Resources (administrative staff, legal staff, software developers advisors, technical support etc.)\nMaterials for construction\nEquipment for infrastructure\nIT (computers, software, etc.)\nLegislative Framework",
        "label4_title": "Capabilities",
        "label4_content": "Systemic, strategic and entrepreneurial vision\nMultidisciplinary knowledge\nAnalytical and forecast capacity\nAbility to think, develop studies and plans",
        "label5_title": "Proccesses",
        "label5_content": "Strategic Process Management\nElaboration of development plans\nLocal and regional potential mapping\nIdentification of economic potential\nManagement of environmental impacts\nElaboration of policies,plans and programs\nPublic Agreements Management\nPrivate Partnerships Management and Cooperation Agreements",
        "label6_title": "Outputs/Products",
        "label6_content": "Laws and regulatory frameworks for: Internet connectivity\nLaws and regulatory frameworks for: Satellite operations (state, regional and sectoral levels)\nLaws and regulatory frameworks for: Drones operations (state, regional and sectoral levels)\nLaws and regulatory frameworks for: Public Authorities documents/communication/transactions etc (state, regional and sectoral levels)",
        "label7_title": "Beneficiaries",
        "label7_content": "Society\nMunicipalities\nCompanies from all the industries (especially eGovernance, Aquaculture)\nEducational institutions",
        "label9_title": "Outcomes",
        "label9_content": "Universal and democratic access to digital connectivity\nPossible increase of per capita income\nJob creation\nHigher Human Development Index (HDI)\nEconomic and societal strengthening of municipalities and strategic regions of the state\nSustainable Aquaculture\nProtection of water\nEnhance citizens’ digital literacy\nReduction of social inequality",
        "label10_title": "Public Value",
        "label10_content": "Provide universal access to High-Speed Internet services by means of trustable and credible service, generating a fair and prosperous state that guarantees the well-being of the population as well as the financial sustainability of SMEs."
    },
    {
        "unique_id": 109,
        "sector": ["eGovernance","Agriculture"],
        "service": "",
        "user_type": "End-User",
        "label1_title": "Key Partners",
        "label1_content": "Internet service providers (ISPs)\nTechnology and Data providers \nApplication providers (data analytics)\nFinancial providers (e.g. banks)\nGovernment and Regulatory bodies",
        "label2_title": "Key Activities",
        "label2_content": "Real-time & periodic crop monitoring\nReal-time  & periodic pest detection\nAutomated area surveillance\nAutonomous Driving (Agricultural sector)\nProvision of various services and information to citizens, businesses, NGOs, and other stakeholders\nEnhanced government transparency, efficiency, and accessibility",
        "label3_title": "Value Propositions",
        "label3_content": "Enhancing the efficiency and sustainability of agricultural practices through improved services, optimized operations, cost reduction, upgraded network quality, and environmental protection.\nReduce costs, time, and effort while increasing e-literacy, digital skills, access to learning materials, digital platforms, and enhanced communication for citizens, businesses, and stakeholders.",
        "label4_title": "Customer Relationships",
        "label4_content": "Personal assistance\nCo-creation (tailor made)",
        "label5_title": "Customer Segments",
        "label5_content": "Local businesses\nPublic authorities\nAgricultural sector\nVeterinary sector\nEquipment operators/users (drones, sensors, etc.)\nCitizens",
        "label6_title": "Channels",
        "label6_content": "Social media channels\nWebsite",
        "label7_title": "Key Resources",
        "label7_content": "Human resources (e.g. technicians,engineers, IT professionals)\nPhysical resources (e.g. drones, devices, cameras)",
        "label9_title": "Cost Structure",
        "label9_content": "Fixed costs for Infrastructure and maintenance\nOperational costs",
        "label10_title": "Revenue Streams",
        "label10_content": "Increased profitability via increased production\nIncreased profitability via cost reductions\nSelling Agricultural products (B2B/B2C)\nMonetise the use of smart infrastructure for testing/evaluating services & solutions.  (Pay per hour/montly/per project)\nReduced time of travel to local authorities\nImprove efficiency of public authorities"
    },
    {
        "unique_id": 110,
        "sector": ["eGovernance","Agriculture"],
        "service": "",
        "user_type": "Internet Service Provider (ISP)",
        "label1_title": "Key Partners",
        "label1_content": "Other Mobile Network Operators (MNOs) & Internet service providers (ISPs)\nTechnology and Data providers \nNGOs\nAcademic Institutions\nSystem Integrators\nFinancial providers (e.g. banks)\nGovernment and Regulatory bodies\nApplication developers (data analytics)\nTechnical consultants/ advisors",
        "label2_title": "Key Activities",
        "label2_content": "Local Manufacturing & Sourcing\nAlliances & Partnerships formation for more efficient operations and lower inverstment costs\nSales & Promotion\nExplore public funding/grants opportunities",
        "label3_title": "Value Propositions",
        "label3_content": "Low cost, Low power & Low maintenance (3L)\nHigh QOS & Coverage\nEnvironment-friendly green-solution\nEnd-to-end IP platform\nCloud server for localized content\nHigh Data Rates",
        "label4_title": "Customer Relationships",
        "label4_content": "24/7 Customer Care Centre\nMicro Operators/VLEs\nRegional Sales Executives",
        "label5_title": "Customer Segments",
        "label5_content": "Local Community\nLocal Businesses\nPublic Authorities\nLocal Retail Market\nResearch & Academia\nEnvironmental engineers",
        "label6_title": "Channels",
        "label6_content": "Micro Operators/ VLEs\nCo-branded outlets\nPartnership Telcos/ISPs\nDirect Sales\nWebsite\nSocial media channels",
        "label7_title": "Key Resources",
        "label7_content": "Physical resources (technologies, machineries)\nFinancial resources (Investors and Venture Funding)\nPassionate Management",
        "label9_title": "Cost Structure",
        "label9_content": "Manufacturing & Sourcing\nWi-Fi integration, funding, deployment, operation & maintenance\nEmployees & office\nR&D & Training\nSales & Marketing",
        "label10_title": "Revenue Streams",
        "label10_content": "Subscription fee\nSpecialised B2B offerings for rural businesses and cooperatives\nContent/ Application services revenue share\nGovernment subsidies for rural connectivity\nMobile Data offload revenues\nNetwork slice rent (technology, software, mobile and broadband network providers)\nHaas. Rent the set of hardware including deployment of the network (The client would be billed monthly, based on the systems deployed).\nIaas. The client should use his own hardware or purchase a device from us. Charge the client based on the amount of data to be transmitted."
    },
    {
        "unique_id": 111,
        "sector": ["eGovernance","Agriculture"],
        "service": "",
        "user_type": "Public Authority",
        "label1_title": "Partnerships/Suppliers/Collaborators",
        "label1_content": "Municipality, Regional Government, National Government, European Union, Universities, Development Agencies, Private sector (e.g. MNOs, ISPs), Financial Institutions",
        "label2_title": "Other Stakeholders",
        "label2_content": "Society, NGOs, Chamber of Tradesmen, Agricultural Organisations, Environmental Organisations and Civil Aviation",
        "label3_title": "Resources",
        "label3_content": "Financial Resources\nHuman Resources (administrative staff, legal staff, software developers advisors, technical support etc.)\nMaterials for construction\nEquipment for infrastructure\nIT (computers, software, etc.)\nLegislative Framework",
        "label4_title": "Capabilities",
        "label4_content": "Systemic, strategic and entrepreneurial vision\nMultidisciplinary knowledge\nAnalytical and forecast capacity\nAbility to think, develop studies and plans",
        "label5_title": "Proccesses",
        "label5_content": "Strategic Process Management\nElaboration of development plans\nLocal and regional potential mapping\nIdentification of economic potential\nManagement of environmental impacts\nElaboration of policies,plans and programs\nPublic Agreements Management\nPrivate Partnerships Management and Cooperation Agreements",
        "label6_title": "Outputs/Products",
        "label6_content": "Laws and regulatory frameworks for: Internet connectivity\nLaws and regulatory frameworks for: Drones operations (state, regional and sectoral levels)\nLaws and regulatory frameworks for: Veterinary operations (state, regional and sectoral levels)\nLaws and regulatory frameworks for: Public Authorities Documents/communication/transactions etc (state, regional and sectoral levels)",
        "label7_title": "Beneficiaries",
        "label7_content": "Society\nMunicipalities\nCompanies from all the industries (especially eGovernance, Agriculture)\nEducational institutions",
        "label9_title": "Outcomes",
        "label9_content": "Universal and democratic access to digital connectivity\nPossible increase of per capita income\nJob creation\nHigher Human Development Index (HDI)\nEconomic and societal strengthening of municipalities and strategic regions of the state\nSustainable Agriculture\nEnhance citizens’ digital literacy\nReduction of social inequality",
        "label10_title": "Public Value",
        "label10_content": "Provide universal access to High-Speed Internet services by means of trustable and credible service, generating a fair and prosperous state that guarantees the well-being of the population as well as the financial sustainability of SMEs."
    },
    {
        "unique_id": 112,
        "sector": ["eGovernance","Forestry"],
        "service": "",
        "user_type": "End-User",
        "label1_title": "Key Partners",
        "label1_content": "Internet service providers (ISPs)\nTechnology and Data providers \nApplication providers (data analytics)\nFinancial providers (e.g. banks)\nGovernment and Regulatory bodies",
        "label2_title": "Key Activities",
        "label2_content": "Automated area surveillance\nProvision of various services and information to citizens, businesses, NGOs, and other stakeholders\nEnhanced government transparency, efficiency, and accessibility",
        "label3_title": "Value Propositions",
        "label3_content": "Monitor and assess forest health and fire risk, evaluate illegal logging, track wildlife movement, and prevent losses and damages.\nReduce costs, time, and effort while increasing e-literacy, digital skills, access to learning materials, digital platforms, and enhanced communication for citizens, businesses, and stakeholders.",
        "label4_title": "Customer Relationships",
        "label4_content": "Personal assistance\nCo-creation (tailor made)",
        "label5_title": "Customer Segments",
        "label5_content": "Local businesses\nPublic authorities\nEnvironmental Agencies\nEquipment operators/users (drones, sensors, etc.)\nCitizens",
        "label6_title": "Channels",
        "label6_content": "Social media channels\nWebsite\nCommunity workshops\nCollaborations with local cooperatives",
        "label7_title": "Key Resources",
        "label7_content": "Human resources (e.g. technicians,engineers, IT professionals)\nPhysical resources (e.g. drones, devices, cameras)",
        "label9_title": "Cost Structure",
        "label9_content": "Fixed costs for infrastructure and maintenance\nOperational costs",
        "label10_title": "Revenue Streams",
        "label10_content": "Increased profitability via increased production\nIncreased profitability via cost reductions\nMonetise the use of smart infrastructure for testing/evaluating services & solutions.  (Pay per hour/montly/per project)\nReduced time of travel to local authorities\nImprove efficiency of public authorities"
    },
    {
        "unique_id": 113,
        "sector": ["eGovernance","Forestry"],
        "service": "",
        "user_type": "Internet Service Provider (ISP)",
        "label1_title": "Key Partners",
        "label1_content": "Other Mobile Network Operators (MNOs) & Internet service providers (ISPs)\nTechnology and Data providers \nNGOs\nAcademic Institutions\nSystem Integrators\nFinancial providers (e.g. banks)\nGovernment and Regulatory bodies\nApplication developers (data analytics)\nTechnical consultants/ advisors",
        "label2_title": "Key Activities",
        "label2_content": "Local Manufacturing & Sourcing\nAlliances & Partnerships formation for more efficient operations and lower inverstment costs\nSales & Promotion\nExplore public funding/grants opportunities",
        "label3_title": "Value Propositions",
        "label3_content": "Low cost, Low power & Low maintenance (3L)\nHigh QOS & Coverage\nEnvironment-friendly green-solution\nEnd-to-end IP platform\nCloud server for localized content\nHigh Data Rates",
        "label4_title": "Customer Relationships",
        "label4_content": "24/7 Customer Care Centre\nMicro Operators/VLEs\nRegional Sales Executives",
        "label5_title": "Customer Segments",
        "label5_content": "Local Community\nLocal Businesses\nPublic Authorities\nLocal Retail Market\nResearch & Academia\nEnvironmental engineers",
        "label6_title": "Channels",
        "label6_content": "Micro Operators/ VLEs\nCo-branded outlets\nPartnership Telcos/ISPs\nDirect Sales\nWebsite\nSocial media channels",
        "label7_title": "Key Resources",
        "label7_content": "Physical resources (technologies, machineries)\nFinancial resources (Investors and Venture Funding)\nPassionate Management",
        "label9_title": "Cost Structure",
        "label9_content": "Manufacturing & Sourcing\nWi-Fi integration, funding, deployment, operation & maintenance\nEmployees & office\nR&D & Training\nSales & Marketing",
        "label10_title": "Revenue Streams",
        "label10_content": "Subscription fee\nContent/ Application services revenue share\nGovernment subsidies for rural connectivity\nMobile Data offload revenues\nNetwork slice rent (technology, software, mobile and broadband network providers)\nHaas. Rent the set of hardware including deployment of the network (The client would be billed monthly, based on the systems deployed).\nIaas. The client should use his own hardware or purchase a device from us. Charge the client based on the amount of data to be transmitted."
    },
    {
        "unique_id": 114,
        "sector": ["eGovernance","Forestry"],
        "service": "",
        "user_type": "Public Authority",
        "label1_title": "Partnerships/Suppliers/Collaborators",
        "label1_content": "Municipality, Regional Government, National Government, European Union, Universities, Development Agencies, Private sector (e.g. MNOs, ISPs), Financial Institutions",
        "label2_title": "Other Stakeholders",
        "label2_content": "Society, NGOs, Chamber of Tradesmen, Fire Departments, Civil Aviation, Forestry Organisations, Agricultural Organisations, Environmental Organisations",
        "label3_title": "Resources",
        "label3_content": "Financial Resources\nHuman Resources (administrative staff, legal staff, software developers advisors, technical support etc.)\nMaterials for construction\nEquipment for infrastructure\nIT (computers, software, etc.)\nLegislative Framework",
        "label4_title": "Capabilities",
        "label4_content": "Systemic, strategic and entrepreneurial vision\nMultidisciplinary knowledge\nAnalytical and forecast capacity\nAbility to think, develop studies and plans",
        "label5_title": "Proccesses",
        "label5_content": "Strategic Process Management\nElaboration of development plans\nLocal and regional potential mapping\nIdentification of economic potential\nManagement of environmental impacts\nElaboration of policies,plans and programs\nPublic Agreements Management\nPrivate Partnerships Management and Cooperation Agreements",
        "label6_title": "Outputs/Products",
        "label6_content": "Laws and regulatory frameworks for: Internet connectivity\nLaws and regulatory frameworks for: Drones operations (state, regional and sectoral levels)",
        "label7_title": "Beneficiaries",
        "label7_content": "Society\nMunicipalities\nCompanies from all the industries (especially eGovernance, Forestry)\nEducational institutions",
        "label9_title": "Outcomes",
        "label9_content": "Universal and democratic access to digital connectivity\nPossible increase of per capita income\nJob creation\nHigher Human Development Index (HDI)\nEconomic and societal strengthening of municipalities and strategic regions of the state\nProtection and rational use of soil and water\nProtection of forests\nEnhance citizens’ digital literacy\nReduction of social inequality",
        "label10_title": "Public Value",
        "label10_content": "Provide universal access to High-Speed Internet services by means of trustable and credible service, generating a fair and prosperous state that guarantees the well-being of the population as well as the financial sustainability of SMEs."
    },
    {
        "unique_id": 115,
        "sector": ["eGovernance","Environmental monitoring"],
        "service": "",
        "user_type": "End-User",
        "label1_title": "Key Partners",
        "label1_content": "Internet service providers (ISPs)\nTechnology and Data providers \nApplication providers (data analytics)\nFinancial providers (e.g. banks)\nGovernment and Regulatory bodies\nAcademic community",
        "label2_title": "Key Activities",
        "label2_content": "Provision of various services and information to citizens, businesses, NGOs, and other stakeholders\nEnhanced government transparency, efficiency, and accessibility\nPeriodic data transmission of the air quality, water quality, noise and biodiversity parameters in the environment ",
        "label3_title": "Value Propositions",
        "label3_content": "Reduce costs, time, and effort while increasing e-literacy, digital skills, access to learning materials, digital platforms, and enhanced communication for citizens, businesses, and stakeholders.\nHuman resource and operations optimization and cost reduction reduce the overall impact of operations on the environment.\nVast area coverage (multiple data sources).",
        "label4_title": "Customer Relationships",
        "label4_content": "Personal assistance\nCo-creation (tailor made)",
        "label5_title": "Customer Segments",
        "label5_content": "Local businesses\nPublic authorities\nEnvironmental Agencies\nEquipment operators/users (drones, sensors, etc.)\nCitizens\nResearch & Academia",
        "label6_title": "Channels",
        "label6_content": "Social media channels\nWebsite\nConferences and exhibitions\nCommunity workshops\nCollaborations with local cooperatives",
        "label7_title": "Key Resources",
        "label7_content": "Human resources (e.g. technicians,engineers, IT professionals)\nPhysical resources (e.g. drones, devices, cameras)",
        "label9_title": "Cost Structure",
        "label9_content": "Fixed costs for infrastructure and maintenance\nOperational costs",
        "label10_title": "Revenue Streams",
        "label10_content": "Selling environmental monitoring services of any kind (B2B/B2Goverment)\nMonetise the use of smart infrastructure for testing/evaluating services & solutions.  (Pay per hour/montly/per project)\nReduced time of travel to local authorities\nImprove efficiency of public authorities"
    },
    {
        "unique_id": 116,
        "sector": ["eGovernance","Environmental monitoring"],
        "service": "",
        "user_type": "Internet Service Provider (ISP)",
        "label1_title": "Key Partners",
        "label1_content": "Other Mobile Network Operators (MNOs) & Internet service providers (ISPs)\nTechnology and Data providers \nNGOs\nAcademic Institutions\nSystem Integrators\nFinancial providers (e.g. banks)\nGovernment and Regulatory bodies\nApplication developers (data analytics)\nTechnical consultants/ advisors",
        "label2_title": "Key Activities",
        "label2_content": "Local Manufacturing & Sourcing\nAlliances & Partnerships formation for more efficient operations and lower inverstment costs\nSales & Promotion\nExplore public funding/grants opportunities",
        "label3_title": "Value Propositions",
        "label3_content": "Low cost, Low power & Low maintenance (3L)\nHigh QOS & Coverage\nEnvironment-friendly green-solution\nEnd-to-end IP platform\nCloud server for localized content\nHigh Data Rates",
        "label4_title": "Customer Relationships",
        "label4_content": "24/7 Customer Care Centre\nMicro Operators/VLEs\nRegional Sales Executives",
        "label5_title": "Customer Segments",
        "label5_content": "Local Community\nLocal Businesses\nPublic Authorities\nLocal Retail Market\nResearch & Academia\nEnvironmental engineers",
        "label6_title": "Channels",
        "label6_content": "Micro Operators/ VLEs\nCo-branded outlets\nPartnership Telcos/ISPs\nDirect Sales\nWebsite\nSocial media channels\nConferences and exhibitions",
        "label7_title": "Key Resources",
        "label7_content": "Physical resources (technologies, machineries)\nFinancial resources (Investors and Venture Funding)\nPassionate Management",
        "label9_title": "Cost Structure",
        "label9_content": "Manufacturing & Sourcing\nWi-Fi integration, funding, deployment, operation & maintenance\nEmployees & office\nR&D & Training\nSales & Marketing",
        "label10_title": "Revenue Streams",
        "label10_content": "Subscription fee\nSpecialised B2B offerings for rural businesses and cooperatives\nContent/ Application services revenue share\nGovernment subsidies for rural connectivity\nMobile Data offload revenues\nNetwork slice rent (technology, software, mobile and broadband network providers)\nHaas. Rent the set of hardware including deployment of the network (The client would be billed monthly, based on the systems deployed).\nIaas. The client should use his own hardware or purchase a device from us. Charge the client based on the amount of data to be transmitted."
    },
    {
        "unique_id": 117,
        "sector": ["eGovernance","Environmental monitoring"],
        "service": "",
        "user_type": "Public Authority",
        "label1_title": "Partnerships/Suppliers/Collaborators",
        "label1_content": "Municipality, Regional Government, National Government, European Union, Universities, Development Agencies, Private sector (e.g. MNOs, ISPs), Financial Institutions",
        "label2_title": "Other Stakeholders",
        "label2_content": "Society, NGOs, Chamber of Tradesmen, Environmental Organisations",
        "label3_title": "Resources",
        "label3_content": "Financial Resources\nHuman Resources (administrative staff, legal staff, software developers advisors, technical support etc.)\nMaterials for construction\nEquipment for infrastructure\nIT (computers, software, etc.)\nLegislative Framework",
        "label4_title": "Capabilities",
        "label4_content": "Systemic, strategic and entrepreneurial vision\nMultidisciplinary knowledge\nAnalytical and forecast capacity\nAbility to think, develop studies and plans",
        "label5_title": "Proccesses",
        "label5_content": "Strategic Process Management\nElaboration of development plans\nLocal and regional potential mapping\nIdentification of economic potential\nManagement of environmental impacts\nElaboration of policies,plans and programs\nPublic Agreements Management\nPrivate Partnerships Management and Cooperation Agreements",
        "label6_title": "Outputs/Products",
        "label6_content": "Laws and regulatory frameworks for: Internet connectivity\nLaws and regulatory frameworks for: Satellite operations (state, regional and sectoral levels)\nLaws and regulatory frameworks for: Public Authorities Documents/communication/transactions etc (state, regional and sectoral levels)",
        "label7_title": "Beneficiaries",
        "label7_content": "Society\nMunicipalities\nCompanies from all the industries (especially eGovernance, Environmental monitoring)\nEducational institutions",
        "label9_title": "Outcomes",
        "label9_content": "Universal and democratic access to digital connectivity\nPossible increase of per capita income\nJob creation\nHigher Human Development Index (HDI)\nEconomic and societal strengthening of municipalities and strategic regions of the state\nProtection and rational use of soil and water\nEnhance citizens’ digital literacy\nReduction of social inequality",
        "label10_title": "Public Value",
        "label10_content": "Provide universal access to High-Speed Internet services by means of trustable and credible service, generating a fair and prosperous state that guarantees the well-being of the population as well as the financial sustainability of SMEs."
    },
    {
        "unique_id": 118,
        "sector": ["eGovernance","eHealth"],
        "service": "",
        "user_type": "End-User",
        "label1_title": "Key Partners",
        "label1_content": "Internet service providers (ISPs)\nTechnology and Data providers \nApplication providers (data analytics)\nFinancial providers (e.g. banks)\nGovernment and Regulatory bodies",
        "label2_title": "Key Activities",
        "label2_content": "Provision of various services and information to citizens, businesses, NGOs, and other stakeholders\nEnhanced government transparency, efficiency, and accessibility\nProvide health and wellbeing guidance & interventions for people living in rural areas ",
        "label3_title": "Value Propositions",
        "label3_content": "Reduce costs, time, and effort while increasing e-literacy, digital skills, access to learning materials, digital platforms, and enhanced communication for citizens, businesses, and stakeholders.\nEnhance well-being of elderly people and support their social interactions.",
        "label4_title": "Customer Relationships",
        "label4_content": "Personal assistance\nCo-creation (tailor made)",
        "label5_title": "Customer Segments",
        "label5_content": "Local businesses\nPublic authorities\nEquipment operators/users (drones, sensors, etc.)\nRetirement Homes\nCitizens",
        "label6_title": "Channels",
        "label6_content": "Social media channels\nWebsite\nCommunity workshops\nCollaborations with local cooperatives",
        "label7_title": "Key Resources",
        "label7_content": "Human resources (e.g. technicians,engineers, IT professionals)\nPhysical resources (e.g. drones, devices, cameras)",
        "label9_title": "Cost Structure",
        "label9_content": "Fixed costs for infrastructure and maintenance\nOperational costs",
        "label10_title": "Revenue Streams",
        "label10_content": "Reduced time of travel to local authorities\nImprove efficiency of public authorities\nMonetize the added value of robot connection in elderly homes"
    },
    {
        "unique_id": 119,
        "sector": ["eGovernance","eHealth"],
        "service": "",
        "user_type": "Internet Service Provider (ISP)",
        "label1_title": "Key Partners",
        "label1_content": "Other Mobile Network Operators (MNOs) & Internet service providers (ISPs)\nTechnology and Data providers \nNGOs\nAcademic Institutions\nSystem Integrators\nFinancial providers (e.g. banks)\nGovernment and Regulatory bodies\nApplication developers (data analytics)\nTechnical consultants/ advisors",
        "label2_title": "Key Activities",
        "label2_content": "Local Manufacturing & Sourcing\nAlliances & Partnerships formation for more efficient operations and lower inverstment costs\nSales & Promotion\nExplore public funding/grants opportunities",
        "label3_title": "Value Propositions",
        "label3_content": "Low cost, Low power & Low maintenance (3L)\nHigh QOS & Coverage\nEnvironment-friendly green-solution\nEnd-to-end IP platform\nCloud server for localized content\nHigh Data Rates",
        "label4_title": "Customer Relationships",
        "label4_content": "24/7 Customer Care Centre\nMicro Operators/VLEs\nRegional Sales Executives",
        "label5_title": "Customer Segments",
        "label5_content": "Local Community\nLocal Businesses\nPublic Authorities\nLocal Retail Market\nResearch & Academia\nEnvironmental engineers",
        "label6_title": "Channels",
        "label6_content": "Micro Operators/ VLEs\nCo-branded outlets\nPartnership Telcos/ISPs\nDirect Sales\nWebsite\nSocial media channels",
        "label7_title": "Key Resources",
        "label7_content": "Physical resources (technologies, machineries)\nFinancial resources (Investors and Venture Funding)\nPassionate Management",
        "label9_title": "Cost Structure",
        "label9_content": "Manufacturing & Sourcing\nWi-Fi integration, funding, deployment, operation & maintenance\nEmployees & office\nR&D & Training\nSales & Marketing",
        "label10_title": "Revenue Streams",
        "label10_content": "Subscription fee\nSpecialised B2B offerings for rural businesses and cooperatives\nContent/ Application services revenue share\nGovernment subsidies for rural connectivity\nMobile Data offload revenues\nNetwork slice rent (technology, software, mobile and broadband network providers)\nHaas. Rent the set of hardware including deployment of the network (The client would be billed monthly, based on the systems deployed).\nIaas. The client should use his own hardware or purchase a device from us. Charge the client based on the amount of data to be transmitted."
    },
    {
        "unique_id": 120,
        "sector": ["eGovernance","eHealth"],
        "service": "",
        "user_type": "Public Authority",
        "label1_title": "Partnerships/Suppliers/Collaborators",
        "label1_content": "Municipality, Regional Government, National Government, European Union, Universities, Development Agencies, Private sector (e.g. MNOs, ISPs), Financial Institutions",
        "label2_title": "Other Stakeholders",
        "label2_content": "Society, NGOs, Chamber of Tradesmen, Medical Organisations, Public Retirement Homes",
        "label3_title": "Resources",
        "label3_content": "Financial Resources\nHuman Resources (administrative staff, legal staff, software developers advisors, technical support etc.)\nMaterials for construction\nEquipment for infrastructure\nIT (computers, software, etc.)\nLegislative Framework",
        "label4_title": "Capabilities",
        "label4_content": "Systemic, strategic and entrepreneurial vision\nMultidisciplinary knowledge\nAnalytical and forecast capacity\nAbility to think, develop studies and plans",
        "label5_title": "Proccesses",
        "label5_content": "Strategic Process Management\nElaboration of development plans\nLocal and regional potential mapping\nIdentification of economic potential\nManagement of environmental impacts\nElaboration of policies,plans and programs\nPublic Agreements Management\nPrivate Partnerships Management and Cooperation Agreements",
        "label6_title": "Outputs/Products",
        "label6_content": "Laws and regulatory frameworks for: Internet connectivity\nLaws and regulatory frameworks for: Public Authorities documents/communication/transactions etc (state, regional and sectoral levels)\nLaws and regulatory frameworks for: Medical consulting (state, regional and sectoral levels)",
        "label7_title": "Beneficiaries",
        "label7_content": "Society\nMunicipalities\nCompanies from all the industries (especially eGovernance, eHealth)\nEducational institutions",
        "label9_title": "Outcomes",
        "label9_content": "Universal and democratic access to digital connectivity\nPossible increase of per capita income\nJob creation\nHigher Human Development Index (HDI)\nEconomic and societal strengthening of municipalities and strategic regions of the state\nEnhance citizens’ health and well-being\nEnhance citizens’ digital literacy\nReduction of social inequality",
        "label10_title": "Public Value",
        "label10_content": "Provide universal access to High-Speed Internet services by means of trustable and credible service, generating a fair and prosperous state that guarantees the well-being of the population as well as the financial sustainability of SMEs."
    },
    {
        "unique_id": 121,
        "sector": ["Tourism","Aquaculture"],
        "service": "",
        "user_type": "End-User",
        "label1_title": "Key Partners",
        "label1_content": "Internet service providers (ISPs)\nTechnology and Data providers \nApplication providers (data analytics)\nFinancial providers (e.g. banks)\nGovernment and Regulatory bodies\nLocal/Regional Tourism Agency",
        "label2_title": "Key Activities",
        "label2_content": "Periodic data transmission of the water quality parameters in the environment rop monitoring\nAutomated area surveillance\nDigital Marketing and E-commerce\nSeamless Information Access for Travellers\nEnhanced Customer Service\nSupport for Remote Work and Extended Stays\nAR/VR for tourism sector",
        "label3_title": "Value Propositions",
        "label3_content": "Enhancing the efficiency, competitiveness and sustainability of aquacultural practices through improved services, optimized operations, cost reduction, upgraded network quality, and environmental protection.\nPeriodic data transmission of the water quality parameters in the environment.\nReduce costs, time, and effort while increasing e-literacy, digital skills, access to learning materials, digital platforms, and enhanced communication for citizens, businesses, and stakeholders.\nExtend the service offerings of local SMEs and enhance visitor experience in the tourism sector by allowing seamless information access to travellers, online promotion of hotels and and tourist attractions, and the use of AR/VR for virtual tours.",
        "label4_title": "Customer Relationships",
        "label4_content": "Personal assistance\nCo-creation (tailor made)",
        "label5_title": "Customer Segments",
        "label5_content": "Local businesses\nAgrotourism SMEs\nPublic authorities\nEquipment operators/users (drones, sensors, etc.)\nEnvironmental agencies\nLocal education institutions\nTourism and Hotel industry",
        "label6_title": "Channels",
        "label6_content": "Social media channels\nWebsite\nConferences and exhibitions\nCommunity workshops\nCollaborations with local cooperatives",
        "label7_title": "Key Resources",
        "label7_content": "Human resources (e.g. technicians,engineers, IT professionals)\nPhysical resources (e.g. drones, devices, cameras)",
        "label9_title": "Cost Structure",
        "label9_content": "Fixed costs for infrastructure and maintenance\nOperational costs",
        "label10_title": "Revenue Streams",
        "label10_content": "Increased profitability via increased production\nIncreased profitability via cost reductions\nSelling Aquaculture products (B2B/B2C)\nMonetise the use of smart infrastructure for testing/evaluating services & solutions.  (Pay per hour/montly/per project)\nSelling Touristic services (B2C)"
    },
    {
        "unique_id": 122,
        "sector": ["Tourism","Aquaculture"],
        "service": "",
        "user_type": "Internet Service Provider (ISP)",
        "label1_title": "Key Partners",
        "label1_content": "Other Mobile Network Operators (MNOs) & Internet service providers (ISPs)\nTechnology and Data providers \nNGOs\nAcademic Institutions\nSystem Integrators\nFinancial providers (e.g. banks)\nGovernment and Regulatory bodies\nApplication developers (data analytics)\nTechnical consultants/ advisors",
        "label2_title": "Key Activities",
        "label2_content": "Local Manufacturing & Sourcing\nAlliances & Partnerships formation for more efficient operations and lower inverstment costs\nSales & Promotion\nExplore public funding/grants opportunities",
        "label3_title": "Value Propositions",
        "label3_content": "Low cost, Low power & Low maintenance (3L)\nHigh QOS & Coverage\nEnvironment-friendly green-solution\nEnd-to-end IP platform\nCloud server for localized content\nHigh Data Rates",
        "label4_title": "Customer Relationships",
        "label4_content": "24/7 Customer Care Centre\nMicro Operators/VLEs\nRegional Sales Executives",
        "label5_title": "Customer Segments",
        "label5_content": "Local Community\nLocal Businesses\nPublic Authorities\nLocal Retail Market\nResearch & Academia\nEnvironmental engineers",
        "label6_title": "Channels",
        "label6_content": "Micro Operators/ VLEs\nCo-branded outlets\nPartnership Telcos/ISPs\nDirect Sales\nWebsite\nSocial media channels\nConferences and exhibitions",
        "label7_title": "Key Resources",
        "label7_content": "Physical resources (technologies, machineries)\nFinancial resources (Investors and Venture Funding)\nPassionate Management",
        "label9_title": "Cost Structure",
        "label9_content": "Manufacturing & Sourcing\nWi-Fi integration, funding, deployment, operation & maintenance\nEmployees & office\nR&D & Training\nSales & Marketing",
        "label10_title": "Revenue Streams",
        "label10_content": "Subscription fee\nSpecialised B2B offerings for rural businesses and cooperatives\nContent/ Application services revenue share\nGovernment subsidies for rural connectivity\nMobile Data offload revenues\nNetwork slice rent (technology, software, mobile and broadband network providers)\nHaas. Rent the set of hardware including deployment of the network (The client would be billed monthly, based on the systems deployed).\nIaas. The client should use his own hardware or purchase a device from us. Charge the client based on the amount of data to be transmitted."
    },
    {
        "unique_id": 123,
        "sector": ["Tourism","Aquaculture"],
        "service": "",
        "user_type": "Public Authority",
        "label1_title": "Partnerships/Suppliers/Collaborators",
        "label1_content": "Municipality, Regional Government, National Government, European Union, Universities, Development Agencies, Private sector (e.g. MNOs, ISPs), Financial Institutions",
        "label2_title": "Other Stakeholders",
        "label2_content": "Society, NGOs, Chamber of Tradesmen, Aquaculture Organisations, Environmental Organisations, Tourism Organisations",
        "label3_title": "Resources",
        "label3_content": "Financial Resources\nHuman Resources (administrative staff, legal staff, software developers advisors, technical support etc.)\nMaterials for construction\nEquipment for infrastructure\nIT (computers, software, etc.)\nLegislative Framework",
        "label4_title": "Capabilities",
        "label4_content": "Systemic, strategic and entrepreneurial vision\nMultidisciplinary knowledge\nAnalytical and forecast capacity\nAbility to think, develop studies and plans",
        "label5_title": "Proccesses",
        "label5_content": "Strategic Process Management\nElaboration of development plans\nLocal and regional potential mapping\nIdentification of economic potential\nManagement of environmental impacts\nElaboration of policies,plans and programs\nPublic Agreements Management\nPrivate Partnerships Management and Cooperation Agreements",
        "label6_title": "Outputs/Products",
        "label6_content": "Laws and regulatory frameworks for: Internet connectivity\nLaws and regulatory frameworks for: Satellite operations (state, regional and sectoral levels)\nLaws and regulatory frameworks for: Drones operations (state, regional and sectoral levels)",
        "label7_title": "Beneficiaries",
        "label7_content": "Society\nMunicipalities\nCompanies from all the industries (especially Tourism, Aquaculture)\nEducational institutions",
        "label9_title": "Outcomes",
        "label9_content": "Universal and democratic access to digital connectivity\nPossible increase of per capita income\nJob creation\nHigher Human Development Index (HDI)\nEconomic and societal strengthening of municipalities and strategic regions of the state\nSustainable Aquaculture\nProtection of water\nEnhance citizens’ digital literacy\nReduction of social inequality",
        "label10_title": "Public Value",
        "label10_content": "Provide universal access to High-Speed Internet services by means of trustable and credible service, generating a fair and prosperous state that guarantees the well-being of the population as well as the financial sustainability of SMEs."
    },
    {
        "unique_id": 124,
        "sector": ["Tourism","Agriculture"],
        "service": "",
        "user_type": "End-User",
        "label1_title": "Key Partners",
        "label1_content": "Internet service providers (ISPs)\nTechnology and Data providers \nApplication providers (data analytics)\nFinancial providers (e.g. banks)\nGovernment and Regulatory bodies\nLocal/Regional Tourism Agency",
        "label2_title": "Key Activities",
        "label2_content": "Real-time & periodic crop monitoring\nReal-time & periodic pest detection\nAutomated area surveillance\nAutonomous Driving (Agricultural sector)\nDigital Marketing and E-commerce\nSeamless Information Access for Travellers\nEnhanced Customer Service\nSupport for Remote Work and Extended Stays\nAR/VR for tourism sector",
        "label3_title": "Value Propositions",
        "label3_content": "Enhancing the efficiency and sustainability of agricultural practices through improved services, optimized operations, cost reduction, upgraded network quality, and environmental protection.\nExtend the service offerings of local SMEs and enhance visitor experience in the tourism sector by allowing seamless information access to travellers, online promotion of hotels and and tourist attractions, and the use of AR/VR for virtual tours.",
        "label4_title": "Customer Relationships",
        "label4_content": "Personal assistance\nCo-creation (tailor made)",
        "label5_title": "Customer Segments",
        "label5_content": "Local businesses\nAgrotourism SMEs\nPublic authorities\nEquipment operators/users (drones, sensors, etc.)\nLocal education institutions\nTourism and Hotel industry",
        "label6_title": "Channels",
        "label6_content": "Social media channels\nWebsite",
        "label7_title": "Key Resources",
        "label7_content": "Human resources (e.g. technicians,engineers, IT professionals)\nPhysical resources (e.g. drones, devices, cameras)",
        "label9_title": "Cost Structure",
        "label9_content": "Fixed costs for infrastructure and maintenance\nOperational costs",
        "label10_title": "Revenue Streams",
        "label10_content": "Increased profitability via increased production\nIncreased profitability via cost reductions\nSelling Agricultural products (B2B/B2C)\nMonetise the use of smart infrastructure for testing/evaluating services & solutions.  (Pay per hour/montly/per project)\nSelling Touristic services (B2C)"
    },
    {
        "unique_id": 125,
        "sector": ["Tourism","Agriculture"],
        "service": "",
        "user_type": "Internet Service Provider (ISP)",
        "label1_title": "Key Partners",
        "label1_content": "Other Mobile Network Operators (MNOs) & Internet service providers (ISPs)\nTechnology and Data providers \nNGOs\nAcademic Institutions\nSystem Integrators\nFinancial providers (e.g. banks)\nGovernment and Regulatory bodies\nApplication developers (data analytics)\nTechnical consultants/ advisors",
        "label2_title": "Key Activities",
        "label2_content": "Local Manufacturing & Sourcing\nAlliances & Partnerships formation for more efficient operations and lower inverstment costs\nSales & Promotion\nExplore public funding/grants opportunities",
        "label3_title": "Value Propositions",
        "label3_content": "Low cost, Low power & Low maintenance (3L)\nHigh QOS & Coverage\nEnvironment-friendly green-solution\nEnd-to-end IP platform\nCloud server for localized content\nHigh Data Rates",
        "label4_title": "Customer Relationships",
        "label4_content": "24/7 Customer Care Centre\nMicro Operators/VLEs\nRegional Sales Executives",
        "label5_title": "Customer Segments",
        "label5_content": "Local Community\nLocal Businesses\nPublic Authorities\nLocal Retail Market\nResearch & Academia\nEnvironmental engineers",
        "label6_title": "Channels",
        "label6_content": "Micro Operators/ VLEs\nCo-branded outlets\nPartnership Telcos/ISPs\nDirect Sales\nWebsite\nSocial media channels",
        "label7_title": "Key Resources",
        "label7_content": "Physical resources (technologies, machineries)\nFinancial resources (Investors and Venture Funding)\nPassionate Management",
        "label9_title": "Cost Structure",
        "label9_content": "Manufacturing & Sourcing\nWi-Fi integration, funding, deployment, operation & maintenance\nEmployees & office\nR&D & Training\nSales & Marketing",
        "label10_title": "Revenue Streams",
        "label10_content": "Subscription fee\nSpecialised B2B offerings for rural businesses and cooperatives\nContent/ Application services revenue share\nGovernment subsidies for rural connectivity\nMobile Data offload revenues\nNetwork slice rent (technology, software, mobile and broadband network providers)\nHaas. Rent the set of hardware including deployment of the network (The client would be billed monthly, based on the systems deployed).\nIaas. The client should use his own hardware or purchase a device from us. Charge the client based on the amount of data to be transmitted."
    },
    {
        "unique_id": 126,
        "sector": ["Tourism","Agriculture"],
        "service": "",
        "user_type": "Public Authority",
        "label1_title": "Partnerships/Suppliers/Collaborators",
        "label1_content": "Municipality, Regional Government, National Government, European Union, Universities, Development Agencies, Private sector (e.g. MNOs, ISPs), Financial Institutions",
        "label2_title": "Other Stakeholders",
        "label2_content": "Society, NGOs, Chamber of Tradesmen, Agricultural Organisations, Civil Aviation, Tourism Organisations",
        "label3_title": "Resources",
        "label3_content": "Financial Resources\nHuman Resources (administrative staff, legal staff, software developers advisors, technical support etc.)\nMaterials for construction\nEquipment for infrastructure\nIT (computers, software, etc.)\nLegislative Framework",
        "label4_title": "Capabilities",
        "label4_content": "Systemic, strategic and entrepreneurial vision\nMultidisciplinary knowledge\nAnalytical and forecast capacity\nAbility to think, develop studies and plans",
        "label5_title": "Proccesses",
        "label5_content": "Strategic Process Management\nElaboration of development plans\nLocal and regional potential mapping\nIdentification of economic potential\nManagement of environmental impacts\nElaboration of policies,plans and programs\nPublic Agreements Management\nPrivate Partnerships Management and Cooperation Agreements",
        "label6_title": "Outputs/Products",
        "label6_content": "Laws and regulatory frameworks for: Internet connectivity\nLaws and regulatory frameworks for: Drones operations (state, regional and sectoral levels)\nLaws and regulatory frameworks for: Veterinary Livestock consulting (state, regional and sectoral levels)",
        "label7_title": "Beneficiaries",
        "label7_content": "Society\nMunicipalities\nCompanies from all the industries (especially Tourism, Agriculture)\nEducational institutions",
        "label9_title": "Outcomes",
        "label9_content": "Universal and democratic access to digital connectivity\nPossible increase of per capita income\nJob creation\nHigher Human Development Index (HDI)\nEconomic and societal strengthening of municipalities and strategic regions of the state\nSustainable Agriculture\nEnhance citizens’ digital literacy\nReduction of social inequality",
        "label10_title": "Public Value",
        "label10_content": "Provide universal access to High-Speed Internet services by means of trustable and credible service, generating a fair and prosperous state that guarantees the well-being of the population as well as the financial sustainability of SMEs."
    },
    {
        "unique_id": 127,
        "sector": ["Tourism","Forestry"],
        "service": "",
        "user_type": "End-User",
        "label1_title": "Key Partners",
        "label1_content": "Internet service providers (ISPs)\nTechnology and Data providers \nApplication providers (data analytics)\nFinancial providers (e.g. banks)\nGovernment and Regulatory bodies\nLocal/Regional Tourism Agency",
        "label2_title": "Key Activities",
        "label2_content": "Automated area surveillance\nDigital Marketing and E-commerce\nSeamless Information Access for Travellers\nEnhanced Customer Service\nSupport for Remote Work and Extended Stays\nAR/VR for tourism sector",
        "label3_title": "Value Propositions",
        "label3_content": "Monitor and assess forest health and fire risk, evaluate illegal logging, track wildlife movement, and prevent losses and damages.\nEExtend the service offerings of local SMEs and enhance visitor experience in the tourism sector by allowing seamless information access to travellers, online promotion of hotels and and tourist attractions, and the use of AR/VR for virtual tours.",
        "label4_title": "Customer Relationships",
        "label4_content": "Personal assistance\nCo-creation (tailor made)",
        "label5_title": "Customer Segments",
        "label5_content": "Local businesses\nAgrotourism SMEs\nPublic authorities\nEquipment operators/users (drones, sensors, etc.)\nEnvironmental agencies\nLocal education institutions\nTourism and Hotel industry",
        "label6_title": "Channels",
        "label6_content": "Social media channels\nWebsite\nCommunity workshops\nCollaborations with local cooperatives",
        "label7_title": "Key Resources",
        "label7_content": "Human resources (e.g. technicians,engineers, IT professionals)\nPhysical resources (e.g. drones, devices, cameras)",
        "label9_title": "Cost Structure",
        "label9_content": "Fixed costs for infrastructure and maintenance\nOperational costs",
        "label10_title": "Revenue Streams",
        "label10_content": "Increased profitability via increased production\nIncreased profitability via cost reductions\nMonetise the use of smart infrastructure for testing/evaluating services & solutions.  (Pay per hour/montly/per project)\nSelling Touristic services (B2C)"
    },
    {
        "unique_id": 128,
        "sector": ["Tourism","Forestry"],
        "service": "",
        "user_type": "Internet Service Provider (ISP)",
        "label1_title": "Key Partners",
        "label1_content": "Other Mobile Network Operators (MNOs) & Internet service providers (ISPs)\nTechnology and Data providers \nNGOs\nAcademic Institutions\nSystem Integrators\nFinancial providers (e.g. banks)\nGovernment and Regulatory bodies\nApplication developers (data analytics)\nTechnical consultants/ advisors",
        "label2_title": "Key Activities",
        "label2_content": "Local Manufacturing & Sourcing\nAlliances & Partnerships formation for more efficient operations and lower inverstment costs\nSales & Promotion\nExplore public funding/grants opportunities",
        "label3_title": "Value Propositions",
        "label3_content": "Low cost, Low power & Low maintenance (3L)\nHigh QOS & Coverage\nEnvironment-friendly green-solution\nEnd-to-end IP platform\nCloud server for localized content\nHigh Data Rates",
        "label4_title": "Customer Relationships",
        "label4_content": "24/7 Customer Care Centre\nMicro Operators/VLEs\nRegional Sales Executives",
        "label5_title": "Customer Segments",
        "label5_content": "Local Community\nLocal Businesses\nPublic Authorities\nLocal Retail Market\nResearch & Academia\nEnvironmental engineers",
        "label6_title": "Channels",
        "label6_content": "Micro Operators/ VLEs\nCo-branded outlets\nPartnership Telcos/ISPs\nDirect Sales\nWebsite\nSocial media channels",
        "label7_title": "Key Resources",
        "label7_content": "Physical resources (technologies, machineries)\nFinancial resources (Investors and Venture Funding)\nPassionate Management",
        "label9_title": "Cost Structure",
        "label9_content": "Manufacturing & Sourcing\nWi-Fi integration, funding, deployment, operation & maintenance\nEmployees & office\nR&D & Training\nSales & Marketing",
        "label10_title": "Revenue Streams",
        "label10_content": "Subscription fee\nSpecialised B2B offerings for rural businesses and cooperatives\nContent/ Application services revenue share\nGovernment subsidies for rural connectivity\nMobile Data offload revenues\nNetwork slice rent (technology, software, mobile and broadband network providers)\nHaas. Rent the set of hardware including deployment of the network (The client would be billed monthly, based on the systems deployed).\nIaas. The client should use his own hardware or purchase a device from us. Charge the client based on the amount of data to be transmitted."
    },
    {
        "unique_id": 129,
        "sector": ["Tourism","Forestry"],
        "service": "",
        "user_type": "Public Authority",
        "label1_title": "Partnerships/Suppliers/Collaborators",
        "label1_content": "Municipality, Regional Government, National Government, European Union, Universities, Development Agencies, Private sector (e.g. MNOs, ISPs), Financial Institutions",
        "label2_title": "Other Stakeholders",
        "label2_content": "Society, NGOs, Chamber of Tradesmen, Fire Departments, Civil Aviation, Forestry Organisations, Agricultural Organisations, Environmental Organisations, Tourism Organisations",
        "label3_title": "Resources",
        "label3_content": "Financial Resources\nHuman Resources (administrative staff, legal staff, software developers advisors, technical support etc.)\nMaterials for construction\nEquipment for infrastructure\nIT (computers, software, etc.)\nLegislative Framework",
        "label4_title": "Capabilities",
        "label4_content": "Systemic, strategic and entrepreneurial vision\nMultidisciplinary knowledge\nAnalytical and forecast capacity\nAbility to think, develop studies and plans",
        "label5_title": "Proccesses",
        "label5_content": "Strategic Process Management\nElaboration of development plans\nLocal and regional potential mapping\nIdentification of economic potential\nManagement of environmental impacts\nElaboration of policies,plans and programs\nPublic Agreements Management\nPrivate Partnerships Management and Cooperation Agreements",
        "label6_title": "Outputs/Products",
        "label6_content": "Laws and regulatory frameworks for: Internet connectivity\nLaws and regulatory frameworks for: Drones operations (state, regional and sectoral levels)",
        "label7_title": "Beneficiaries",
        "label7_content": "Society\nMunicipalities\nCompanies from all the industries (especially Tourism, Forestry)\nEducational institutions",
        "label9_title": "Outcomes",
        "label9_content": "Universal and democratic access to digital connectivity\nPossible increase of per capita income\nJob creation\nHigher Human Development Index (HDI)\nEconomic and societal strengthening of municipalities and strategic regions of the state\nProtection and rational use of soil and water\nProtection of forests\nEnhance citizens’ digital literacy\nReduction of social inequality",
        "label10_title": "Public Value",
        "label10_content": "Provide universal access to High-Speed Internet services by means of trustable and credible service, generating a fair and prosperous state that guarantees the well-being of the population as well as the financial sustainability of SMEs."
    },
    {
        "unique_id": 130,
        "sector": ["Tourism","Environmental monitoring"],
        "service": "",
        "user_type": "End-User",
        "label1_title": "Key Partners",
        "label1_content": "Internet service providers (ISPs)\nTechnology and Data providers \nApplication providers (data analytics)\nFinancial providers (e.g. banks)\nGovernment and Regulatory bodies\nAcademic community\nLocal/Regional Tourism Agency",
        "label2_title": "Key Activities",
        "label2_content": "Periodic data transmission of the air quality, water quality, noise and biodiversity parameters in the environment \nDigital Marketing and E-commerce\nSeamless Information Access for Travellers\nEnhanced Customer Service\nSupport for Remote Work and Extended Stays\nAR/VR for tourism sector",
        "label3_title": "Value Propositions",
        "label3_content": "Human resource and operations optimization and cost reduction reduce the overall impact of operations on the environment.\nVast area coverage (multiple data sources).\nExtend the service offerings of local SMEs and enhance visitor experience in the tourism sector by allowing seamless information access to travellers, online promotion of hotels and and tourist attractions, and the use of AR/VR for virtual tours.",
        "label4_title": "Customer Relationships",
        "label4_content": "Personal assistance\nCo-creation (tailor made)",
        "label5_title": "Customer Segments",
        "label5_content": "Local businesses\nAgrotourism SMEs\nPublic authorities\nEquipment operators/users (drones, sensors, etc.)\nEnvironmental agencies\nLocal education institutions\nTourism and Hotel industry\nResearch & Academia",
        "label6_title": "Channels",
        "label6_content": "Social media channels\nWebsite\nConferences and exhibitions\nCommunity workshops\nCollaborations with local cooperatives",
        "label7_title": "Key Resources",
        "label7_content": "Human resources (e.g. technicians,engineers, IT professionals)\nPhysical resources (e.g. drones, devices, cameras)",
        "label9_title": "Cost Structure",
        "label9_content": "Fixed costs for Infrastructure and maintenance\nOperational costs",
        "label10_title": "Revenue Streams",
        "label10_content": "Selling environmental monitoring services of any kind (B2B/B2Goverment)\nIncreased profitability via cost reductions\nMonetise the use of smart infrastructure for testing/evaluating services & solutions.  (Pay per hour/montly/per project)\nSelling Touristic services (B2C)"
    },
    {
        "unique_id": 131,
        "sector": ["Tourism","Environmental monitoring"],
        "service": "",
        "user_type": "Internet Service Provider (ISP)",
        "label1_title": "Key Partners",
        "label1_content": "Other Mobile Network Operators (MNOs) & Internet service providers (ISPs)\nTechnology and Data providers \nNGOs\nAcademic Institutions\nSystem Integrators\nFinancial providers (e.g. banks)\nGovernment and Regulatory bodies\nApplication developers (data analytics)\nTechnical consultants/ advisors",
        "label2_title": "Key Activities",
        "label2_content": "Local Manufacturing & Sourcing\nAlliances & Partnerships formation for more efficient operations and lower inverstment costs\nSales & Promotion\nExplore public funding/grants opportunities",
        "label3_title": "Value Propositions",
        "label3_content": "Low cost, Low power & Low maintenance (3L)\nHigh QOS & Coverage\nEnvironment-friendly green-solution\nEnd-to-end IP platform\nCloud server for localized content\nHigh Data Rates",
        "label4_title": "Customer Relationships",
        "label4_content": "24/7 Customer Care Centre\nMicro Operators/VLEs\nRegional Sales Executives",
        "label5_title": "Customer Segments",
        "label5_content": "Local Community\nLocal Businesses\nPublic Authorities\nLocal Retail Market\nResearch & Academia\nEnvironmental engineers",
        "label6_title": "Channels",
        "label6_content": "Micro Operators/ VLEs\nCo-branded outlets\nPartnership Telcos/ISPs\nDirect Sales\nWebsite\nSocial media channels\nConferences and exhibitions",
        "label7_title": "Key Resources",
        "label7_content": "Physical resources (technologies, machineries)\nFinancial resources (Investors and Venture Funding)\nPassionate Management",
        "label9_title": "Cost Structure",
        "label9_content": "Manufacturing & Sourcing\nWi-Fi integration, funding, deployment, operation & maintenance\nEmployees & office\nR&D & Training\nSales & Marketing",
        "label10_title": "Revenue Streams",
        "label10_content": "Subscription fee\nSpecialised B2B offerings for rural businesses and cooperatives\nContent/ Application services revenue share\nGovernment subsidies for rural connectivity\nMobile Data offload revenues\nNetwork slice rent (technology, software, mobile and broadband network providers)\nHaas. Rent the set of hardware including deployment of the network (The client would be billed monthly, based on the systems deployed).\nIaas. The client should use his own hardware or purchase a device from us. Charge the client based on the amount of data to be transmitted."
    },
    {
        "unique_id": 132,
        "sector": ["Tourism","Environmental monitoring"],
        "service": "",
        "user_type": "Public Authority",
        "label1_title": "Partnerships/Suppliers/Collaborators",
        "label1_content": "Municipality, Regional Government, National Government, European Union, Universities, Development Agencies, Private sector (e.g. MNOs, ISPs), Financial Institutions",
        "label2_title": "Other Stakeholders",
        "label2_content": "Society, NGOs, Chamber of Tradesmen, Environmental Organisations, Tourism Organisations",
        "label3_title": "Resources",
        "label3_content": "Financial Resources\nHuman Resources (administrative staff, legal staff, software developers advisors, technical support etc.)\nMaterials for construction\nEquipment for infrastructure\nIT (computers, software, etc.)\nLegislative Framework",
        "label4_title": "Capabilities",
        "label4_content": "Systemic, strategic and entrepreneurial vision\nMultidisciplinary knowledge\nAnalytical and forecast capacity\nAbility to think, develop studies and plans",
        "label5_title": "Proccesses",
        "label5_content": "Strategic Process Management\nElaboration of development plans\nLocal and regional potential mapping\nIdentification of economic potential\nManagement of environmental impacts\nElaboration of policies,plans and programs\nPublic Agreements Management\nPrivate Partnerships Management and Cooperation Agreements",
        "label6_title": "Outputs/Products",
        "label6_content": "Laws and regulatory frameworks for: Internet connectivity\nLaws and regulatory frameworks for: Satellite operations (state, regional and sectoral levels)\nLaws and regulatory frameworks for: Drones operations (state, regional and sectoral levels)",
        "label7_title": "Beneficiaries",
        "label7_content": "Society\nMunicipalities\nCompanies from all the industries (especially Tourism, Environmental monitoring)\nEducational institutions",
        "label9_title": "Outcomes",
        "label9_content": "Universal and democratic access to digital connectivity\nPossible increase of per capita income\nJob creation\nHigher Human Development Index (HDI)\nEconomic and societal strengthening of municipalities and strategic regions of the state\nProtection and rational use of soil and water\nEnhance citizens’ digital literacy\nReduction of social inequality",
        "label10_title": "Public Value",
        "label10_content": "Provide universal access to High-Speed Internet services by means of trustable and credible service, generating a fair and prosperous state that guarantees the well-being of the population as well as the financial sustainability of SMEs."
    },
    {
        "unique_id": 133,
        "sector": ["Tourism","eHealth"],
        "service": "",
        "user_type": "End-User",
        "label1_title": "Key Partners",
        "label1_content": "Internet service providers (ISPs)\nTechnology and Data providers \nApplication providers (data analytics)\nFinancial providers (e.g. banks)\nGovernment and Regulatory bodies\nLocal/Regional Tourism Agency",
        "label2_title": "Key Activities",
        "label2_content": "Provide health and wellbeing guidance & interventions for people living in rural areas \nDigital Marketing and E-commerce\nSeamless Information Access for Travellers\nEnhanced Customer Service\nSupport for Remote Work and Extended Stays\nAR/VR for tourism sector",
        "label3_title": "Value Propositions",
        "label3_content": "Enhance well-being of elderly people and support their social interactions.\nExtend the service offerings of local SMEs and enhance visitor experience in the tourism sector by allowing seamless information access to travellers, online promotion of hotels and and tourist attractions, and the use of AR/VR for virtual tours.",
        "label4_title": "Customer Relationships",
        "label4_content": "Personal assistance\nCo-creation (tailor made)",
        "label5_title": "Customer Segments",
        "label5_content": "Local businesses\nAgrotourism SMEs\nPublic authorities\nEnvironmental agencies\nLocal education institutions\nTourism and Hotel industry\nRetirement Homes\nCitizens",
        "label6_title": "Channels",
        "label6_content": "Social media channels\nWebsite\nCommunity workshops\nCollaborations with local cooperatives",
        "label7_title": "Key Resources",
        "label7_content": "Human resources (e.g. technicians,engineers, IT professionals)\nPhysical resources (e.g. drones, devices, cameras)",
        "label9_title": "Cost Structure",
        "label9_content": "Fixed costs for infrastructure and maintenance\nOperational costs",
        "label10_title": "Revenue Streams",
        "label10_content": "Monetize the added value of robot connection in elderly homes\nMonetise the use of smart infrastructure for testing/evaluating services & solutions.  (Pay per hour/montly/per project)\nSelling Touristic services (B2C)"
    },
    {
        "unique_id": 134,
        "sector": ["Tourism","eHealth"],
        "service": "",
        "user_type": "Internet Service Provider (ISP)",
        "label1_title": "Key Partners",
        "label1_content": "Other Mobile Network Operators (MNOs) & Internet service providers (ISPs)\nTechnology and Data providers \nNGOs\nAcademic Institutions\nSystem Integrators\nFinancial providers (e.g. banks)\nGovernment and Regulatory bodies\nApplication developers (data analytics)\nTechnical consultants/ advisors",
        "label2_title": "Key Activities",
        "label2_content": "Local Manufacturing & Sourcing\nAlliances & Partnerships formation for more efficient operations and lower inverstment costs\nSales & Promotion\nExplore public funding/grants opportunities",
        "label3_title": "Value Propositions",
        "label3_content": "Low cost, Low power & Low maintenance (3L)\nHigh QOS & Coverage\nEnvironment-friendly green-solution\nEnd-to-end IP platform\nCloud server for localized content\nHigh Data Rates",
        "label4_title": "Customer Relationships",
        "label4_content": "24/7 Customer Care Centre\nMicro Operators/VLEs\nRegional Sales Executives",
        "label5_title": "Customer Segments",
        "label5_content": "Local Community\nLocal Businesses\nPublic Authorities\nLocal Retail Market\nResearch & Academia\nEnvironmental engineers",
        "label6_title": "Channels",
        "label6_content": "Micro Operators/ VLEs\nCo-branded outlets\nPartnership Telcos/ISPs\nDirect Sales\nWebsite\nSocial media channels",
        "label7_title": "Key Resources",
        "label7_content": "Physical resources (technologies, machineries)\nFinancial resources (Investors and Venture Funding)\nPassionate Management",
        "label9_title": "Cost Structure",
        "label9_content": "Manufacturing & Sourcing\nWi-Fi integration, funding, deployment, operation & maintenance\nEmployees & office\nR&D & Training\nSales & Marketing",
        "label10_title": "Revenue Streams",
        "label10_content": "Subscription fee\nSpecialised B2B offerings for rural businesses and cooperatives\nContent/ Application services revenue share\nGovernment subsidies for rural connectivity\nMobile Data offload revenues\nNetwork slice rent (technology, software, mobile and broadband network providers)\nHaas. Rent the set of hardware including deployment of the network (The client would be billed monthly, based on the systems deployed).\nIaas. The client should use his own hardware or purchase a device from us. Charge the client based on the amount of data to be transmitted."
    },
    {
        "unique_id": 135,
        "sector": ["Tourism","eHealth"],
        "service": "",
        "user_type": "Public Authority",
        "label1_title": "Partnerships/Suppliers/Collaborators",
        "label1_content": "Municipality, Regional Government, National Government, European Union, Universities, Development Agencies, Private sector (e.g. MNOs, ISPs), Financial Institutions",
        "label2_title": "Other Stakeholders",
        "label2_content": "Society, NGOs, Chamber of Tradesmen, Medical Organisations, Public Retirement Homes, Tourism Organisations",
        "label3_title": "Resources",
        "label3_content": "Financial Resources\nHuman Resources (administrative staff, legal staff, software developers advisors, technical support etc.)\nMaterials for construction\nEquipment for infrastructure\nIT (computers, software, etc.)\nLegislative Framework",
        "label4_title": "Capabilities",
        "label4_content": "Systemic, strategic and entrepreneurial vision\nMultidisciplinary knowledge\nAnalytical and forecast capacity\nAbility to think, develop studies and plans",
        "label5_title": "Proccesses",
        "label5_content": "Strategic Process Management\nElaboration of development plans\nLocal and regional potential mapping\nIdentification of economic potential\nManagement of environmental impacts\nElaboration of policies,plans and programs\nPublic Agreements Management\nPrivate Partnerships Management and Cooperation Agreements",
        "label6_title": "Outputs/Products",
        "label6_content": "Laws and regulatory frameworks for: Internet connectivity\nLaws and regulatory frameworks for: Drones operations (state, regional and sectoral levels)\nLaws and regulatory frameworks for: Medical consulting (state, regional and sectoral levels)",
        "label7_title": "Beneficiaries",
        "label7_content": "Society\nMunicipalities\nCompanies from all the industries (especially Tourism, eHealth)\nEducational institutions",
        "label9_title": "Outcomes",
        "label9_content": "Universal and democratic access to digital connectivity\nPossible increase of per capita income\nJob creation\nHigher Human Development Index (HDI)\nEconomic and societal strengthening of municipalities and strategic regions of the state\nEnhance citizens’ health and well-being\nEnhance citizens’ digital literacy\nReduction of social inequality",
        "label10_title": "Public Value",
        "label10_content": "Provide universal access to High-Speed Internet services by means of trustable and credible service, generating a fair and prosperous state that guarantees the well-being of the population as well as the financial sustainability of SMEs."
    },
    {
        "unique_id": 136,
        "sector": ["Tourism","eGovernance"],
        "service": "",
        "user_type": "End-User",
        "label1_title": "Key Partners",
        "label1_content": "Internet service providers (ISPs)\nTechnology and Data providers \nApplication providers (data analytics)\nFinancial providers (e.g. banks)\nGovernment and Regulatory bodies\nLocal/Regional Tourism Agency",
        "label2_title": "Key Activities",
        "label2_content": "Provision of various services and information to citizens, businesses, NGOs, and other stakeholders\nEnhanced government transparency, efficiency, and accessibility\nDigital Marketing and E-commerce\nSeamless Information Access for Travellers\nEnhanced Customer Service\nSupport for Remote Work and Extended Stays\nAR/VR for tourism sector",
        "label3_title": "Value Propositions",
        "label3_content": "Reduce costs, time, and effort while increasing e-literacy, digital skills, access to learning materials, digital platforms, and enhanced communication for citizens, businesses, and stakeholders.\nExtend the service offerings of local SMEs and enhance visitor experience in the tourism sector by allowing seamless information access to travellers, online promotion of hotels and and tourist attractions, and the use of AR/VR for virtual tours.",
        "label4_title": "Customer Relationships",
        "label4_content": "Personal assistance\nCo-creation (tailor made)",
        "label5_title": "Customer Segments",
        "label5_content": "Local businesses\nAgrotourism SMEs\nPublic authorities\nLocal education institutions\nTourism and Hotel industry\nCitizens",
        "label6_title": "Channels",
        "label6_content": "Social media channels\nWebsite\nCommunity workshops\nCollaborations with local cooperatives",
        "label7_title": "Key Resources",
        "label7_content": "Human resources (e.g. technicians,engineers, IT professionals)\nPhysical resources (e.g. drones, devices, cameras)",
        "label9_title": "Cost Structure",
        "label9_content": "Fixed costs for infrastructure and maintenance\nOperational costs",
        "label10_title": "Revenue Streams",
        "label10_content": "Reduced time of travel to local authorities\nImprove efficiency of public authorities\nMonetise the use of smart infrastructure for testing/evaluating services & solutions.  (Pay per hour/montly/per project)\nSelling Touristic services (B2C)"
    },
    {
        "unique_id": 137,
        "sector": ["Tourism","eGovernance"],
        "service": "",
        "user_type": "Internet Service Provider (ISP)",
        "label1_title": "Key Partners",
        "label1_content": "Other Mobile Network Operators (MNOs) & Internet service providers (ISPs)\nTechnology and Data providers \nNGOs\nAcademic Institutions\nSystem Integrators\nFinancial providers (e.g. banks)\nGovernment and Regulatory bodies\nApplication developers (data analytics)\nTechnical consultants/ advisors",
        "label2_title": "Key Activities",
        "label2_content": "Local Manufacturing & Sourcing\nAlliances & Partnerships formation for more efficient operations and lower inverstment costs\nSales & Promotion\nExplore public funding/grants opportunities",
        "label3_title": "Value Propositions",
        "label3_content": "Low cost, Low power & Low maintenance (3L)\nHigh QOS & Coverage\nEnvironment-friendly green-solution\nEnd-to-end IP platform\nCloud server for localized content\nHigh Data Rates",
        "label4_title": "Customer Relationships",
        "label4_content": "24/7 Customer Care Centre\nMicro Operators/VLEs\nRegional Sales Executives",
        "label5_title": "Customer Segments",
        "label5_content": "Local Community\nLocal Businesses\nPublic Authorities\nLocal Retail Market\nResearch & Academia\nEnvironmental engineers",
        "label6_title": "Channels",
        "label6_content": "Micro Operators/ VLEs\nCo-branded outlets\nPartnership Telcos/ISPs\nDirect Sales\nWebsite\nSocial media channels",
        "label7_title": "Key Resources",
        "label7_content": "Physical resources (technologies, machineries)\nFinancial resources (Investors and Venture Funding)\nPassionate Management",
        "label9_title": "Cost Structure",
        "label9_content": "Manufacturing & Sourcing\nWi-Fi integration, funding, deployment, operation & maintenance\nEmployees & office\nR&D & Training\nSales & Marketing",
        "label10_title": "Revenue Streams",
        "label10_content": "Subscription fee\nSpecialised B2B offerings for rural businesses and cooperatives\nContent/ Application services revenue share\nGovernment subsidies for rural connectivity\nMobile Data offload revenues\nNetwork slice rent (technology, software, mobile and broadband network providers)\nHaas. Rent the set of hardware including deployment of the network (The client would be billed monthly, based on the systems deployed).\nIaas. The client should use his own hardware or purchase a device from us. Charge the client based on the amount of data to be transmitted."
    },
    {
        "unique_id": 138,
        "sector": ["Tourism","eGovernance"],
        "service": "",
        "user_type": "Public Authority",
        "label1_title": "Partnerships/Suppliers/Collaborators",
        "label1_content": "Municipality, Regional Government, National Government, European Union, Universities, Development Agencies, Private sector (e.g. MNOs, ISPs), Financial Institutions",
        "label2_title": "Other Stakeholders",
        "label2_content": "Society, NGOs, Chamber of Tradesmen, Tourism Organisations",
        "label3_title": "Resources",
        "label3_content": "Financial Resources\nHuman Resources (administrative staff, legal staff, software developers advisors, technical support etc.)\nMaterials for construction\nEquipment for infrastructure\nIT (computers, software, etc.)\nLegislative Framework",
        "label4_title": "Capabilities",
        "label4_content": "Systemic, strategic and entrepreneurial vision\nMultidisciplinary knowledge\nAnalytical and forecast capacity\nAbility to think, develop studies and plans",
        "label5_title": "Proccesses",
        "label5_content": "Strategic Process Management\nElaboration of development plans\nLocal and regional potential mapping\nIdentification of economic potential\nManagement of environmental impacts\nElaboration of policies,plans and programs\nPublic Agreements Management\nPrivate Partnerships Management and Cooperation Agreements",
        "label6_title": "Outputs/Products",
        "label6_content": "Laws and regulatory frameworks for: Internet connectivity\nLaws and regulatory frameworks for: Public Authorities documents/communication/transactions etc (state, regional and sectoral levels)\nLaws and regulatory frameworks for: Drones operations (state, regional and sectoral levels)",
        "label7_title": "Beneficiaries",
        "label7_content": "Society\nMunicipalities\nCompanies from all the industries (especially Tourism, eGovernance)\nEducational institutions",
        "label9_title": "Outcomes",
        "label9_content": "Universal and democratic access to digital connectivity\nPossible increase of per capita income\nJob creation\nHigher Human Development Index (HDI)\nEconomic and societal strengthening of municipalities and strategic regions of the state\nEnhance citizens’ digital literacy\nReduction of social inequality",
        "label10_title": "Public Value",
        "label10_content": "Provide universal access to High-Speed Internet services by means of trustable and credible service, generating a fair and prosperous state that guarantees the well-being of the population as well as the financial sustainability of SMEs."
    },
    {
        "unique_id": 139,
        "sector": ["Education","Aquaculture"],
        "service": "",
        "user_type": "End-User",
        "label1_title": "Key Partners",
        "label1_content": "Internet service providers (ISPs)\nTechnology and Data providers \nApplication providers (data analytics)\nFinancial providers (e.g. banks)\nGovernment and Regulatory bodies",
        "label2_title": "Key Activities",
        "label2_content": "Provision of various services and information to citizens, businesses, and other stakeholders\nAccess to learning material\nAccess to e-learning platforms\nPeriodic data transmission of the water quality parameters in the environment\nAutomated area surveillance",
        "label3_title": "Value Propositions",
        "label3_content": "Accessible, customizable, and cost-effective education with global reach, offering synchronous and asynchronous learning, via digital platforms, enhanced communication, real-time Q&A, and 24/7 availability for continuous learning.\nEnhancing the efficiency, competitiveness and sustainability of aquacultural practices through improved services, optimized operations, cost reduction, upgraded network quality, and environmental protection.\nPeriodic data transmission of the water quality parameters in the environment.",
        "label4_title": "Customer Relationships",
        "label4_content": "Personal assistance\nCo-creation (tailor made)",
        "label5_title": "Customer Segments",
        "label5_content": "Local businesses\nPublic authorities\nAquaculture sector\nEnvironmental agencies\nEquipment operators/users (drones, sensors, etc.)\nResearch & Academia\nCitizens",
        "label6_title": "Channels",
        "label6_content": "Social media channels\nWebsite\nConferences and exhibitions\nCommunity workshops\nCollaborations with local cooperatives",
        "label7_title": "Key Resources",
        "label7_content": "Human resources (e.g. technicians,engineers, IT professionals)\nPhysical resources (e.g. drones, devices, cameras)",
        "label9_title": "Cost Structure",
        "label9_content": "Fixed costs for infrastructure and maintenance\nOperational costs",
        "label10_title": "Revenue Streams",
        "label10_content": "Reduced cost of travel expenses (for rural citizen & educators)\nReduced time of travel (for rural citizen & educators)\nIncreased Productivity for businesses\ne-Learning platform revenues for businesses\nIncreased profitability via increased production\nIncreased profitability via cost reductions\nSelling Aquaculture products (B2B/B2C)\nMonetise the use of smart infrastructure for testing/evaluating services & solutions.  (Pay per hour/montly/per project)"
    },
    {
        "unique_id": 140,
        "sector": ["Education","Aquaculture"],
        "service": "",
        "user_type": "Internet Service Provider (ISP)",
        "label1_title": "Key Partners",
        "label1_content": "Other Mobile Network Operators (MNOs) & Internet service providers (ISPs)\nTechnology and Data providers \nNGOs\nAcademic Institutions\nSystem Integrators\nFinancial providers (e.g. banks)\nGovernment and Regulatory bodies\nApplication developers (data analytics)\nTechnical consultants/ advisors",
        "label2_title": "Key Activities",
        "label2_content": "Local Manufacturing & Sourcing\nAlliances & Partnerships formation for more efficient operations and lower inverstment costs\nSales & Promotion\nExplore public funding/grants opportunities",
        "label3_title": "Value Propositions",
        "label3_content": "Low cost, Low power & Low maintenance (3L)\nHigh QOS & Coverage\nEnvironment-friendly green-solution\nEnd-to-end IP platform\nCloud server for localized content\nHigh Data Rates",
        "label4_title": "Customer Relationships",
        "label4_content": "24/7 Customer Care Centre\nMicro Operators/VLEs\nRegional Sales Executives",
        "label5_title": "Customer Segments",
        "label5_content": "Local Community\nLocal Businesses\nPublic Authorities\nLocal Retail Market\nResearch & Academia\nEnvironmental engineers",
        "label6_title": "Channels",
        "label6_content": "Micro Operators/ VLEs\nCo-branded outlets\nPartnership Telcos/ISPs\nDirect Sales\nWebsite\nSocial media channels\nConferences and exhibitions",
        "label7_title": "Key Resources",
        "label7_content": "Physical resources (technologies, machineries)\nFinancial resources (Investors and Venture Funding)\nPassionate Management",
        "label9_title": "Cost Structure",
        "label9_content": "Manufacturing & Sourcing\nWi-Fi integration, funding, deployment, operation & maintenance\nEmployees & office\nR&D & Training\nSales & Marketing",
        "label10_title": "Revenue Streams",
        "label10_content": "Subscription fee\nSpecialised B2B offerings for rural businesses and cooperatives\nContent/ Application services revenue share\nGovernment subsidies for rural connectivity\nMobile Data offload revenues\nNetwork slice rent (technology, software, mobile and broadband network providers)\nHaas. Rent the set of hardware including deployment of the network (The client would be billed monthly, based on the systems deployed).\nIaas. The client should use his own hardware or purchase a device from us. Charge the client based on the amount of data to be transmitted."
    },
    {
        "unique_id": 141,
        "sector": ["Education","Aquaculture"],
        "service": "",
        "user_type": "Public Authority",
        "label1_title": "Partnerships/Suppliers/Collaborators",
        "label1_content": "Municipality, Regional Government, National Government, European Union, Universities, Development Agencies, Private sector (e.g. MNOs, ISPs), Financial Institutions",
        "label2_title": "Other Stakeholders",
        "label2_content": "Society, NGOs, Chamber of Tradesmen, Aquaculture Organisations, Environmental Organisations",
        "label3_title": "Resources",
        "label3_content": "Financial Resources\nHuman Resources (administrative staff, legal staff, software developers advisors, technical support etc.)\nMaterials for construction\nEquipment for infrastructure\nIT (computers, software, etc.)\nLegislative Framework",
        "label4_title": "Capabilities",
        "label4_content": "Systemic, strategic and entrepreneurial vision\nMultidisciplinary knowledge\nAnalytical and forecast capacity\nAbility to think, develop studies and plans",
        "label5_title": "Proccesses",
        "label5_content": "Strategic Process Management\nElaboration of development plans\nLocal and regional potential mapping\nIdentification of economic potential\nManagement of environmental impacts\nElaboration of policies,plans and programs\nPublic Agreements Management\nPrivate Partnerships Management and Cooperation Agreements",
        "label6_title": "Outputs/Products",
        "label6_content": "Laws and regulatory frameworks for: Internet connectivity\nLaws and regulatory frameworks for: Satellite operations (state, regional and sectoral levels)\nLaws and regulatory frameworks for: Drones operations (state, regional and sectoral levels)\nLaws and regulatory frameworks for: Universities, Institutes etc (state, regional and sectoral levels)",
        "label7_title": "Beneficiaries",
        "label7_content": "Society\nMunicipalities\nCompanies from all the industries (especially Education, Aquaculture)\nEducational institutions",
        "label9_title": "Outcomes",
        "label9_content": "Universal and democratic access to digital connectivity\nPossible increase of per capita income\nJob creation\nHigher Human Development Index (HDI)\nEconomic and societal strengthening of municipalities and strategic regions of the state\nSustainable Aquaculture\nProtection of water\nEnhance citizens’ digital literacy\nReduction of social inequality",
        "label10_title": "Public Value",
        "label10_content": "Provide universal access to High-Speed Internet services by means of trustable and credible service, generating a fair and prosperous state that guarantees the well-being of the population as well as the financial sustainability of SMEs."
    },
    {
        "unique_id": 142,
        "sector": ["Education","Agriculture"],
        "service": "",
        "user_type": "End-User",
        "label1_title": "Key Partners",
        "label1_content": "Internet service providers (ISPs)\nTechnology and Data providers \nApplication providers (data analytics)\nFinancial providers (e.g. banks)\nGovernment and Regulatory bodies",
        "label2_title": "Key Activities",
        "label2_content": "Provision of various services and information to citizens, businesses, and other stakeholders\nAccess to learning material\nAccess to e-learning platforms\nReal-time & periodic crop monitoring\nReal-time & periodic pest detection\nAutomated area surveillance\nAutonomous Driving (Agricultural sector)\nProvide health and wellbeing guidance & interventions for Livestock health",
        "label3_title": "Value Propositions",
        "label3_content": "Accessible, customizable, and cost-effective education with global reach, offering synchronous and asynchronous learning,  via digital platforms, enhanced communication, real-time Q&A, and 24/7 availability for continuous learning.\nEnhancing the efficiency, competitiveness and sustainability of agricultural practices through improved services, optimized operations, cost reduction, upgraded network quality, and environmental protection.",
        "label4_title": "Customer Relationships",
        "label4_content": "Personal assistance\nCo-creation (tailor made)",
        "label5_title": "Customer Segments",
        "label5_content": "Local businesses\nPublic authorities\nAgricultural sector\nVeterinary sector\nEquipment operators/users (drones, sensors, etc.)\nResearch & Academia\nCitizens",
        "label6_title": "Channels",
        "label6_content": "Social media channels\nWebsite",
        "label7_title": "Key Resources",
        "label7_content": "Human resources (e.g. technicians,engineers, IT professionals)\nPhysical resources (e.g. drones, devices, cameras)",
        "label9_title": "Cost Structure",
        "label9_content": "Fixed costs for infrastructure and maintenance\nOperational costs",
        "label10_title": "Revenue Streams",
        "label10_content": "Reduced cost of travel expenses (for rural citizen & educators)\nReduced time of travel (for rural citizen & educators)\nIncreased Productivity for businesses\ne-Learning platform revenues for businesses\nIncreased profitability via increased production\nIncreased profitability via cost reductions\nSelling Agricultural products (B2B/B2C)\nMonetise the use of smart farming infrastructure for testing/evaluating services & solutions.  (Pay per hour/montly/per project)"
    },
    {
        "unique_id": 143,
        "sector": ["Education","Agriculture"],
        "service": "",
        "user_type": "Internet Service Provider (ISP)",
        "label1_title": "Key Partners",
        "label1_content": "Other Mobile Network Operators (MNOs) & Internet service providers (ISPs)\nTechnology and Data providers \nNGOs\nAcademic Institutions\nSystem Integrators\nFinancial providers (e.g. banks)\nGovernment and Regulatory bodies\nApplication developers (data analytics)\nTechnical consultants/ advisors",
        "label2_title": "Key Activities",
        "label2_content": "Local Manufacturing & Sourcing\nAlliances & Partnerships formation for more efficient operations and lower inverstment costs\nSales & Promotion\nExplore public funding/grants opportunities",
        "label3_title": "Value Propositions",
        "label3_content": "Low cost, Low power & Low maintenance (3L)\nHigh QOS & Coverage\nEnvironment-friendly green-solution\nEnd-to-end IP platform\nCloud server for localized content\nHigh Data Rates",
        "label4_title": "Customer Relationships",
        "label4_content": "24/7 Customer Care Centre\nMicro Operators/VLEs\nRegional Sales Executives",
        "label5_title": "Customer Segments",
        "label5_content": "Local Community\nLocal Businesses\nPublic Authorities\nLocal Retail Market\nResearch & Academia\nEnvironmental engineers",
        "label6_title": "Channels",
        "label6_content": "Micro Operators/ VLEs\nCo-branded outlets\nPartnership Telcos/ISPs\nDirect Sales\nWebsite\nSocial media channels",
        "label7_title": "Key Resources",
        "label7_content": "Physical resources (technologies, machineries)\nFinancial resources (Investors and Venture Funding)\nPassionate Management",
        "label9_title": "Cost Structure",
        "label9_content": "Manufacturing & Sourcing\nWi-Fi integration, funding, deployment, operation & maintenance\nEmployees & office\nR&D & Training\nSales & Marketing",
        "label10_title": "Revenue Streams",
        "label10_content": "Subscription fee\nSpecialised B2B offerings for rural businesses and cooperatives\nSpecialised B2B offerings for rural businesses and cooperatives\nContent/ Application services revenue share\nGovernment subsidies for rural connectivity\nMobile Data offload revenues\nNetwork slice rent (technology, software, mobile and broadband network providers)\nHaas. Rent the set of hardware including deployment of the network (The client would be billed monthly, based on the systems deployed).\nIaas. The client should use his own hardware or purchase a device from us. Charge the client based on the amount of data to be transmitted."
    },
    {
        "unique_id": 144,
        "sector": ["Education","Agriculture"],
        "service": "",
        "user_type": "Public Authority",
        "label1_title": "Partnerships/Suppliers/Collaborators",
        "label1_content": "Municipality, Regional Government, National Government, European Union, Universities, Development Agencies, Private sector (e.g. MNOs, ISPs), Financial Institutions",
        "label2_title": "Other Stakeholders",
        "label2_content": "Society, NGOs, Chamber of Tradesmen, Agricultural Organisations, Civil Aviation",
        "label3_title": "Resources",
        "label3_content": "Financial Resources\nHuman Resources (administrative staff, legal staff, software developers advisors, technical support etc.)\nMaterials for construction\nEquipment for infrastructure\nIT (computers, software, etc.)\nLegislative Framework",
        "label4_title": "Capabilities",
        "label4_content": "Systemic, strategic and entrepreneurial vision\nMultidisciplinary knowledge\nAnalytical and forecast capacity\nAbility to think, develop studies and plans",
        "label5_title": "Proccesses",
        "label5_content": "Strategic Process Management\nElaboration of development plans\nLocal and regional potential mapping\nIdentification of economic potential\nManagement of environmental impacts\nElaboration of policies,plans and programs\nPublic Agreements Management\nPrivate Partnerships Management and Cooperation Agreements",
        "label6_title": "Outputs/Products",
        "label6_content": "Laws and regulatory frameworks for: Internet connectivity\nLaws and regulatory frameworks for: Drones operations (state, regional and sectoral levels)\nLaws and regulatory frameworks for: Veterinary operations (state, regional and sectoral levels)\nLaws and regulatory frameworks for: Universities, Institutes etc (state, regional and sectoral levels)",
        "label7_title": "Beneficiaries",
        "label7_content": "Society\nMunicipalities\nCompanies from all the industries (especially Education,  Agriculture)\nEducational institutions",
        "label9_title": "Outcomes",
        "label9_content": "Universal and democratic access to digital connectivity\nPossible increase of per capita income\nJob creation\nHigher Human Development Index (HDI)\nEconomic and societal strengthening of municipalities and strategic regions of the state\nSustainable Agriculture\nEnhance citizens’ digital literacy\nReduction of social inequality",
        "label10_title": "Public Value",
        "label10_content": "Provide universal access to High-Speed Internet services by means of trustable and credible service, generating a fair and prosperous state that guarantees the well-being of the population as well as the financial sustainability of SMEs."
    },
    {
        "unique_id": 145,
        "sector": ["Education","Forestry"],
        "service": "",
        "user_type": "End-User",
        "label1_title": "Key Partners",
        "label1_content": "Internet service providers (ISPs)\nTechnology and Data providers \nApplication providers (data analytics)\nFinancial providers (e.g. banks)\nGovernment and Regulatory bodies",
        "label2_title": "Key Activities",
        "label2_content": "Provision of various services and information to citizens, businesses, and other stakeholders\nAccess to learning material\nAccess to e-learning platforms\nAutomated area surveillance",
        "label3_title": "Value Propositions",
        "label3_content": "Accessible, customizable, and cost-effective education with global reach, offering synchronous and asynchronous learning, via digital platforms, enhanced communication, real-time Q&A, and 24/7 availability for continuous learning.\nMonitor and assess forest health and fire risk, evaluate illegal logging, track wildlife movement, and prevent losses and damages.",
        "label4_title": "Customer Relationships",
        "label4_content": "Personal assistance\nCo-creation (tailor made)",
        "label5_title": "Customer Segments",
        "label5_content": "Local businesses\nPublic authorities\nForestry sector\nEnvironmental agencies\nEquipment operators/users (drones, sensors, etc.)\nResearch & Academia\nCitizens",
        "label6_title": "Channels",
        "label6_content": "Social media channels\nWebsite\nCommunity workshops\nCollaborations with local cooperatives",
        "label7_title": "Key Resources",
        "label7_content": "Human resources (e.g. technicians,engineers, IT professionals)\nPhysical resources (e.g. drones, devices, cameras)",
        "label9_title": "Cost Structure",
        "label9_content": "Fixed costs for infrastructure and maintenance\nOperational costs",
        "label10_title": "Revenue Streams",
        "label10_content": "Reduced cost of travel expenses (for rural citizen & educators)\nReduced time of travel (for rural citizen & educators)\nIncreased Productivity for businesses\ne-Learning platform revenues for businesses\nIncreased profitability via increased production\nIncreased profitability via cost reductions"
    },
    {
        "unique_id": 146,
        "sector": ["Education","Forestry"],
        "service": "",
        "user_type": "Internet Service Provider (ISP)",
        "label1_title": "Key Partners",
        "label1_content": "Other Mobile Network Operators (MNOs) & Internet service providers (ISPs)\nTechnology and Data providers \nNGOs\nAcademic Institutions\nSystem Integrators\nFinancial providers (e.g. banks)\nGovernment and Regulatory bodies\nApplication developers (data analytics)\nTechnical consultants/ advisors",
        "label2_title": "Key Activities",
        "label2_content": "Local Manufacturing & Sourcing\nAlliances & Partnerships formation for more efficient operations and lower inverstment costs\nSales & Promotion\nExplore public funding/grants opportunities",
        "label3_title": "Value Propositions",
        "label3_content": "Low cost, Low power & Low maintenance (3L)\nHigh QOS & Coverage\nEnvironment-friendly green-solution\nEnd-to-end IP platform\nCloud server for localized content\nHigh Data Rates",
        "label4_title": "Customer Relationships",
        "label4_content": "24/7 Customer Care Centre\nMicro Operators/VLEs\nRegional Sales Executives",
        "label5_title": "Customer Segments",
        "label5_content": "Local Community\nLocal Businesses\nPublic Authorities\nLocal Retail Market\nResearch & Academia\nEnvironmental engineers",
        "label6_title": "Channels",
        "label6_content": "Micro Operators/ VLEs\nCo-branded outlets\nPartnership Telcos/ISPs\nDirect Sales\nWebsite\nSocial media channels",
        "label7_title": "Key Resources",
        "label7_content": "Physical resources (technologies, machineries)\nFinancial resources (Investors and Venture Funding)\nPassionate Management",
        "label9_title": "Cost Structure",
        "label9_content": "Manufacturing & Sourcing\nWi-Fi integration, funding, deployment, operation & maintenance\nEmployees & office\nR&D & Training\nSales & Marketing",
        "label10_title": "Revenue Streams",
        "label10_content": "Subscription fee\nSpecialised B2B offerings for rural businesses and cooperatives\nContent/ Application services revenue share\nGovernment subsidies for rural connectivity\nMobile Data offload revenues\nNetwork slice rent (technology, software, mobile and broadband network providers)\nHaas. Rent the set of hardware including deployment of the network (The client would be billed monthly, based on the systems deployed).\nIaas. The client should use his own hardware or purchase a device from us. Charge the client based on the amount of data to be transmitted."
    },
    {
        "unique_id": 147,
        "sector": ["Education","Forestry"],
        "service": "",
        "user_type": "Public Authority",
        "label1_title": "Partnerships/Suppliers/Collaborators",
        "label1_content": "Municipality, Regional Government, National Government, European Union, Universities, Development Agencies, Private sector (e.g. MNOs, ISPs), Financial Institutions",
        "label2_title": "Other Stakeholders",
        "label2_content": "Society, NGOs, Chamber of Tradesmen, Fire Departments, Civil Aviation, Forestry Organisations, Agricultural Organisations, Environmental Organisations",
        "label3_title": "Resources",
        "label3_content": "Financial Resources\nHuman Resources (administrative staff, legal staff, software developers advisors, technical support etc.)\nMaterials for construction\nEquipment for infrastructure\nIT (computers, software, etc.)\nLegislative Framework",
        "label4_title": "Capabilities",
        "label4_content": "Systemic, strategic and entrepreneurial vision\nMultidisciplinary knowledge\nAnalytical and forecast capacity\nAbility to think, develop studies and plans",
        "label5_title": "Proccesses",
        "label5_content": "Strategic Process Management\nElaboration of development plans\nLocal and regional potential mapping\nIdentification of economic potential\nManagement of environmental impacts\nElaboration of policies,plans and programs\nPublic Agreements Management\nPrivate Partnerships Management and Cooperation Agreements",
        "label6_title": "Outputs/Products",
        "label6_content": "Laws and regulatory frameworks for: Internet connectivity\nLaws and regulatory frameworks for: Drones operations (state, regional and sectoral levels)\nLaws and regulatory frameworks for: Universities, Institutes etc (state, regional and sectoral levels)",
        "label7_title": "Beneficiaries",
        "label7_content": "Society\nMunicipalities\nCompanies from all the industries (especially Education, Forestry)\nEducational institutions",
        "label9_title": "Outcomes",
        "label9_content": "Universal and democratic access to digital connectivity\nPossible increase of per capita income\nJob creation\nHigher Human Development Index (HDI)\nEconomic and societal strengthening of municipalities and strategic regions of the state\nProtection and rational use of soil and water\nProtection of forests\nEnhance citizens’ digital literacy\nReduction of social inequality",
        "label10_title": "Public Value",
        "label10_content": "Provide universal access to High-Speed Internet services by means of trustable and credible service, generating a fair and prosperous state that guarantees the well-being of the population as well as the financial sustainability of SMEs."
    },
    {
        "unique_id": 148,
        "sector": ["Education","Environmental monitoring"],
        "service": "",
        "user_type": "End-User",
        "label1_title": "Key Partners",
        "label1_content": "Internet service providers (ISPs)\nTechnology and Data providers \nApplication providers (data analytics)\nFinancial providers (e.g. banks)\nGovernment and Regulatory bodies\nAcademic community",
        "label2_title": "Key Activities",
        "label2_content": "Provision of various services and information to citizens, businesses, and other stakeholders\nAccess to learning material\nAccess to e-learning platforms\nPeriodic data transmission of the air quality, water quality, noise and biodiversity parameters in the environment ",
        "label3_title": "Value Propositions",
        "label3_content": "Accessible, customizable, and cost-effective education with global reach, offering synchronous and asynchronous learning, via digital platforms, enhanced communication, real-time Q&A, and 24/7 availability for continuous learning.\nHuman resource and operations optimization and cost reduction reduce the overall impact of operations on the environment.\nVast area coverage (multiple data sources).",
        "label4_title": "Customer Relationships",
        "label4_content": "Personal assistance\nCo-creation (tailor made)",
        "label5_title": "Customer Segments",
        "label5_content": "Local businesses\nPublic authorities\nEnvironmental agencies\nEquipment operators/users (drones, sensors, etc.)\nResearch & Academia\nCitizens",
        "label6_title": "Channels",
        "label6_content": "Social media channels\nWebsite\nConferences and exhibitions\nCommunity workshops\nCollaborations with local cooperatives",
        "label7_title": "Key Resources",
        "label7_content": "Human resources (e.g. technicians,engineers, IT professionals)\nPhysical resources (e.g. drones, devices, cameras)",
        "label9_title": "Cost Structure",
        "label9_content": "Fixed costs for infrastructure and maintenance\nOperational costs",
        "label10_title": "Revenue Streams",
        "label10_content": "Reduced cost of travel expenses (for rural citizen & educators)\nReduced time of travel (for rural citizen & educators)\nIncreased Productivity for businesses\ne-Learning platform revenues for businesses\nSelling environmental monitoring services of any kind (B2B/B2Goverment)\nBetter quality services due to better connectivity"
    },
    {
        "unique_id": 149,
        "sector": ["Education","Environmental monitoring"],
        "service": "",
        "user_type": "Internet Service Provider (ISP)",
        "label1_title": "Key Partners",
        "label1_content": "Other Mobile Network Operators (MNOs) & Internet service providers (ISPs)\nTechnology and Data providers \nNGOs\nAcademic Institutions\nSystem Integrators\nFinancial providers (e.g. banks)\nGovernment and Regulatory bodies\nApplication developers (data analytics)\nTechnical consultants/ advisors",
        "label2_title": "Key Activities",
        "label2_content": "Local Manufacturing & Sourcing\nAlliances & Partnerships formation for more efficient operations and lower inverstment costs\nSales & Promotion\nExplore public funding/grants opportunities",
        "label3_title": "Value Propositions",
        "label3_content": "Low cost, Low power & Low maintenance (3L)\nHigh QOS & Coverage\nEnvironment-friendly green-solution\nEnd-to-end IP platform\nCloud server for localized content\nHigh Data Rates",
        "label4_title": "Customer Relationships",
        "label4_content": "24/7 Customer Care Centre\nMicro Operators/VLEs\nRegional Sales Executives",
        "label5_title": "Customer Segments",
        "label5_content": "Local Community\nLocal Businesses\nPublic Authorities\nLocal Retail Market\nResearch & Academia\nEnvironmental engineers",
        "label6_title": "Channels",
        "label6_content": "Micro Operators/ VLEs\nCo-branded outlets\nPartnership Telcos/ISPs\nDirect Sales\nWebsite\nSocial media channels\nConferences and exhibitions",
        "label7_title": "Key Resources",
        "label7_content": "Physical resources (technologies, machineries)\nFinancial resources (Investors and Venture Funding)\nPassionate Management",
        "label9_title": "Cost Structure",
        "label9_content": "Manufacturing & Sourcing\nWi-Fi integration, funding, deployment, operation & maintenance\nEmployees & office\nR&D & Training\nSales & Marketing",
        "label10_title": "Revenue Streams",
        "label10_content": "Subscription fee\nSpecialised B2B offerings for rural businesses and cooperatives\nContent/ Application services revenue share\nGovernment subsidies for rural connectivity\nMobile Data offload revenues\nNetwork slice rent (technology, software, mobile and broadband network providers)\nHaas. Rent the set of hardware including deployment of the network (The client would be billed monthly, based on the systems deployed).\nIaas. The client should use his own hardware or purchase a device from us. Charge the client based on the amount of data to be transmitted."
    },
    {
        "unique_id": 150,
        "sector": ["Education","Environmental monitoring"],
        "service": "",
        "user_type": "Public Authority",
        "label1_title": "Partnerships/Suppliers/Collaborators",
        "label1_content": "Municipality, Regional Government, National Government, European Union, Universities, Development Agencies, Private sector (e.g. MNOs, ISPs), Financial Institutions",
        "label2_title": "Other Stakeholders",
        "label2_content": "Society, NGOs, Chamber of Tradesmen, Environmental Organisations",
        "label3_title": "Resources",
        "label3_content": "Financial Resources\nHuman Resources (administrative staff, legal staff, software developers advisors, technical support etc.)\nMaterials for construction\nEquipment for infrastructure\nIT (computers, software, etc.)\nLegislative Framework",
        "label4_title": "Capabilities",
        "label4_content": "Systemic, strategic and entrepreneurial vision\nMultidisciplinary knowledge\nAnalytical and forecast capacity\nAbility to think, develop studies and plans",
        "label5_title": "Proccesses",
        "label5_content": "Strategic Process Management\nElaboration of development plans\nLocal and regional potential mapping\nIdentification of economic potential\nManagement of environmental impacts\nElaboration of policies,plans and programs\nPublic Agreements Management\nPrivate Partnerships Management and Cooperation Agreements",
        "label6_title": "Outputs/Products",
        "label6_content": "Laws and regulatory frameworks for: Internet connectivity\nLaws and regulatory frameworks for: Satellite operations (state, regional and sectoral levels)\nLaws and regulatory frameworks for: Universities, Institutes etc (state, regional and sectoral levels)",
        "label7_title": "Beneficiaries",
        "label7_content": "Society\nMunicipalities\nCompanies from all the industries (especially Education, Environmental monitoring)\nEducational institutions",
        "label9_title": "Outcomes",
        "label9_content": "Universal and democratic access to digital connectivity\nPossible increase of per capita income\nJob creation\nHigher HDI\nEconomic and societal strengthening of municipalities and strategic regions of the state\nProtection and rational use of soil and water\nEnhance citizens’ digital literacy\nReduction of social inequality",
        "label10_title": "Public Value",
        "label10_content": "Provide universal access to High-Speed Internet services by means of trustable and credible service, generating a fair and prosperous state that guarantees the well-being of the population as well as the financial sustainability of SMEs."
    },
    {
        "unique_id": 151,
        "sector": ["Education","eHealth"],
        "service": "",
        "user_type": "End-User",
        "label1_title": "Key Partners",
        "label1_content": "Internet service providers (ISPs)\nTechnology and Data providers \nApplication providers (data analytics)\nFinancial providers (e.g. banks)\nGovernment and Regulatory bodies",
        "label2_title": "Key Activities",
        "label2_content": "Provision of various services and information to citizens, businesses, and other stakeholders\nAccess to learning material\nAccess to e-learning platforms\nProvide health and wellbeing guidance & interventions for people living in rural areas ",
        "label3_title": "Value Propositions",
        "label3_content": "Accessible, customizable, and cost-effective education with global reach, offering synchronous and asynchronous learning, via digital platforms, enhanced communication, real-time Q&A, and 24/7 availability for continuous learning.\nEnhance well-being of elderly people and support their social interactions.",
        "label4_title": "Customer Relationships",
        "label4_content": "Personal assistance\nCo-creation (tailor made)",
        "label5_title": "Customer Segments",
        "label5_content": "Local businesses\nPublic authorities\nRetirement Homes\nResearch & Academia\nCitizens",
        "label6_title": "Channels",
        "label6_content": "Social media channels\nWebsite\nCommunity workshops\nCollaborations with local cooperatives",
        "label7_title": "Key Resources",
        "label7_content": "Human resources (e.g. technicians,engineers, IT professionals)\nPhysical resources (e.g. drones, devices, cameras)",
        "label9_title": "Cost Structure",
        "label9_content": "Fixed costs for infrastructure and maintenance\nOperational costs",
        "label10_title": "Revenue Streams",
        "label10_content": "Reduced cost of travel expenses (for rural citizen & educators)\nReduced time of travel (for rural citizen & educators)\nIncreased Productivity for businesses\ne-Learning platform revenues for businesses\nMonetize the added value of robot connection in elderly homes"
    },
    {
        "unique_id": 152,
        "sector": ["Education","eHealth"],
        "service": "",
        "user_type": "Internet Service Provider (ISP)",
        "label1_title": "Key Partners",
        "label1_content": "Other Mobile Network Operators (MNOs) & Internet service providers (ISPs)\nTechnology and Data providers \nNGOs\nAcademic Institutions\nSystem Integrators\nFinancial providers (e.g. banks)\nGovernment and Regulatory bodies\nApplication developers (data analytics)\nTechnical consultants/ advisors",
        "label2_title": "Key Activities",
        "label2_content": "Local Manufacturing & Sourcing\nAlliances & Partnerships formation for more efficient operations and lower inverstment costs\nSales & Promotion\nExplore public funding/grants opportunities",
        "label3_title": "Value Propositions",
        "label3_content": "Low cost, Low power & Low maintenance (3L)\nHigh QOS & Coverage\nEnvironment-friendly green-solution\nEnd-to-end IP platform\nCloud server for localized content\nHigh Data Rates",
        "label4_title": "Customer Relationships",
        "label4_content": "24/7 Customer Care Centre\nMicro Operators/VLEs\nRegional Sales Executives",
        "label5_title": "Customer Segments",
        "label5_content": "Local Community\nLocal Businesses\nPublic Authorities\nLocal Retail Market\nResearch & Academia\nEnvironmental engineers",
        "label6_title": "Channels",
        "label6_content": "Micro Operators/ VLEs\nCo-branded outlets\nPartnership Telcos/ISPs\nDirect Sales\nWebsite\nSocial media channels",
        "label7_title": "Key Resources",
        "label7_content": "Physical resources (technologies, machineries)\nFinancial resources (Investors and Venture Funding)\nPassionate Management",
        "label9_title": "Cost Structure",
        "label9_content": "Manufacturing & Sourcing\nWi-Fi integration, funding, deployment, operation & maintenance\nEmployees & office\nR&D & Training\nSales & Marketing",
        "label10_title": "Revenue Streams",
        "label10_content": "Subscription fee\nSpecialised B2B offerings for rural businesses and cooperatives\nContent/ Application services revenue share\nGovernment subsidies for rural connectivity\nMobile Data offload revenues\nNetwork slice rent (technology, software, mobile and broadband network providers)\nHaas. Rent the set of hardware including deployment of the network (The client would be billed monthly, based on the systems deployed).\nIaas. The client should use his own hardware or purchase a device from us. Charge the client based on the amount of data to be transmitted."
    },
    {
        "unique_id": 153,
        "sector": ["Education","eHealth"],
        "service": "",
        "user_type": "Public Authority",
        "label1_title": "Partnerships/Suppliers/Collaborators",
        "label1_content": "Municipality, Regional Government, National Government, European Union, Universities, Development Agencies, Private sector (e.g. MNOs, ISPs), Financial Institutions",
        "label2_title": "Other Stakeholders",
        "label2_content": "Society, NGOs, Chamber of Tradesmen, Medical Organisations, Public Retirement Homes",
        "label3_title": "Resources",
        "label3_content": "Financial Resources\nHuman Resources (administrative staff, legal staff, software developers advisors, technical support etc.)\nMaterials for construction\nEquipment for infrastructure\nIT (computers, software, etc.)\nLegislative Framework",
        "label4_title": "Capabilities",
        "label4_content": "Systemic, strategic and entrepreneurial vision\nMultidisciplinary knowledge\nAnalytical and forecast capacity\nAbility to think, develop studies and plans",
        "label5_title": "Proccesses",
        "label5_content": "Strategic Process Management\nElaboration of development plans\nLocal and regional potential mapping\nIdentification of economic potential\nManagement of environmental impacts\nElaboration of policies,plans and programs\nPublic Agreements Management\nPrivate Partnerships Management and Cooperation Agreements",
        "label6_title": "Outputs/Products",
        "label6_content": "Laws and regulatory frameworks for: Internet connectivity\nLaws and regulatory frameworks for: Medical consulting (state, regional and sectoral levels)\nLaws and regulatory frameworks for: Universities, Institutes etc (state, regional and sectoral levels)",
        "label7_title": "Beneficiaries",
        "label7_content": "Society\nMunicipalities\nCompanies from all the industries (especially Education, eHealth)\nEducational institutions",
        "label9_title": "Outcomes",
        "label9_content": "Universal and democratic access to digital connectivity\nPossible increase of per capita income\nJob creation\nHigher Human Development Index (HDI)\nEconomic and societal strengthening of municipalities and strategic regions of the state\nEnhance citizens’ health and well-being\nEnhance citizens’ digital literacy\nReduction of social inequality",
        "label10_title": "Public Value",
        "label10_content": "Provide universal access to High-Speed Internet services by means of trustable and credible service, generating a fair and prosperous state that guarantees the well-being of the population as well as the financial sustainability of SMEs."
    },
    {
        "unique_id": 154,
        "sector": ["Education","eGovernance"],
        "service": "",
        "user_type": "End-User",
        "label1_title": "Key Partners",
        "label1_content": "Internet service providers (ISPs)\nTechnology and Data providers \nApplication providers (data analytics)\nFinancial providers (e.g. banks)\nGovernment and Regulatory bodies",
        "label2_title": "Key Activities",
        "label2_content": "Provision of various services and information to citizens, businesses, and other stakeholders\nAccess to learning material\nAccess to e-learning platforms\nProvision of various services and information to citizens, businesses, NGOs, and other stakeholders\nEnhanced government transparency, efficiency, and accessibility",
        "label3_title": "Value Propositions",
        "label3_content": "Accessible, customizable, and cost-effective education with global reach, offering synchronous and asynchronous learning, via digital platforms, enhanced communication, real-time Q&A, and 24/7 availability for continuous learning.\nReduce costs, time, and effort while increasing e-literacy, digital skills, access to learning materials, digital platforms, and enhanced communication for citizens, businesses, and stakeholders.",
        "label4_title": "Customer Relationships",
        "label4_content": "Personal assistance\nCo-creation (tailor made)",
        "label5_title": "Customer Segments",
        "label5_content": "Local businesses\nPublic authorities\nResearch & Academia\nTourism and Hotel industry\nCitizens",
        "label6_title": "Channels",
        "label6_content": "Social media channels\nWebsite\nCommunity workshops\nCollaborations with local cooperatives",
        "label7_title": "Key Resources",
        "label7_content": "Human resources (e.g. technicians,engineers, IT professionals)\nPhysical resources (e.g. drones, devices, cameras)",
        "label9_title": "Cost Structure",
        "label9_content": "Fixed costs for infrastructure and maintenance\nOperational costs",
        "label10_title": "Revenue Streams",
        "label10_content": "Reduced cost of travel expenses (for rural citizen & educators)\nReduced time of travel (for rural citizen & educators)\nIncreased Productivity for businesses\ne-Learning platform revenues for businesses\nReduced time of travel to local authorities\nImprove efficiency of public authorities"
    },
    {
        "unique_id": 155,
        "sector": ["Education","eGovernance"],
        "service": "",
        "user_type": "Internet Service Provider (ISP)",
        "label1_title": "Key Partners",
        "label1_content": "Other Mobile Network Operators (MNOs) & Internet service providers (ISPs)\nTechnology and Data providers \nNGOs\nAcademic Institutions\nSystem Integrators\nFinancial providers (e.g. banks)\nGovernment and Regulatory bodies\nApplication developers (data analytics)\nTechnical consultants/ advisors",
        "label2_title": "Key Activities",
        "label2_content": "Local Manufacturing & Sourcing\nAlliances & Partnerships formation for more efficient operations and lower inverstment costs\nSales & Promotion\nExplore public funding/grants opportunities",
        "label3_title": "Value Propositions",
        "label3_content": "Low cost, Low power & Low maintenance (3L)\nHigh QOS & Coverage\nEnvironment-friendly green-solution\nEnd-to-end IP platform\nCloud server for localized content\nHigh Data Rates",
        "label4_title": "Customer Relationships",
        "label4_content": "24/7 Customer Care Centre\nMicro Operators/VLEs\nRegional Sales Executives",
        "label5_title": "Customer Segments",
        "label5_content": "Local Community\nLocal Businesses\nPublic Authorities\nLocal Retail Market\nResearch & Academia\nEnvironmental engineers",
        "label6_title": "Channels",
        "label6_content": "Micro Operators/ VLEs\nCo-branded outlets\nPartnership Telcos/ISPs\nDirect Sales\nWebsite\nSocial media channels",
        "label7_title": "Key Resources",
        "label7_content": "Physical resources (technologies, machineries)\nFinancial resources (Investors and Venture Funding)\nPassionate Management",
        "label9_title": "Cost Structure",
        "label9_content": "Manufacturing & Sourcing\nWi-Fi integration, funding, deployment, operation & maintenance\nEmployees & office\nR&D & Training\nSales & Marketing",
        "label10_title": "Revenue Streams",
        "label10_content": "Subscription fee\nSpecialised B2B offerings for rural businesses and cooperatives\nContent/ Application services revenue share\nGovernment subsidies for rural connectivity\nMobile Data offload revenues\nNetwork slice rent (technology, software, mobile and broadband network providers)\nHaas. Rent the set of hardware including deployment of the network (The client would be billed monthly, based on the systems deployed).\nIaas. The client should use his own hardware or purchase a device from us. Charge the client based on the amount of data to be transmitted."
    },
    {
        "unique_id": 156,
        "sector": ["Education","eGovernance"],
        "service": "",
        "user_type": "Public Authority",
        "label1_title": "Partnerships/Suppliers/Collaborators",
        "label1_content": "Municipality, Regional Government, National Government, European Union, Universities, Development Agencies, Private sector (e.g. MNOs, ISPs), Financial Institutions",
        "label2_title": "Other Stakeholders",
        "label2_content": "Society, NGOs, Chamber of Tradesmen",
        "label3_title": "Resources",
        "label3_content": "Financial Resources\nHuman Resources (administrative staff, legal staff, software developers advisors, technical support etc.)\nMaterials for construction\nEquipment for infrastructure\nIT (computers, software, etc.)\nLegislative Framework",
        "label4_title": "Capabilities",
        "label4_content": "Systemic, strategic and entrepreneurial vision\nMultidisciplinary knowledge\nAnalytical and forecast capacity\nAbility to think, develop studies and plans",
        "label5_title": "Proccesses",
        "label5_content": "Strategic Process Management\nElaboration of development plans\nLocal and regional potential mapping\nIdentification of economic potential\nManagement of environmental impacts\nElaboration of policies,plans and programs\nPublic Agreements Management\nPrivate Partnerships Management and Cooperation Agreements",
        "label6_title": "Outputs/Products",
        "label6_content": "Laws and regulatory frameworks for: Internet connectivity\nLaws and regulatory frameworks for: Public Authorities Documents/communication/transactions etc (state, regional and sectoral levels)\nLaws and regulatory frameworks for: Internet connectivity\nLaws and regulatory frameworks for: Universities, Institutes etc (state, regional and sectoral levels)",
        "label7_title": "Beneficiaries",
        "label7_content": "Society\nMunicipalities\nCompanies from all the industries (especially Education, eGovernance)\nEducational institutions",
        "label9_title": "Outcomes",
        "label9_content": "Universal and democratic access to digital connectivity\nPossible increase of per capita income\nJob creation\nHigher Human Development Index (HDI)\nEconomic and societal strengthening of municipalities and strategic regions of the state\nEnhance citizens’ digital literacy\nReduction of social inequality",
        "label10_title": "Public Value",
        "label10_content": "Provide universal access to High-Speed Internet services by means of trustable and credible service, generating a fair and prosperous state that guarantees the well-being of the population as well as the financial sustainability of SMEs."
    },
    {
        "unique_id": 157,
        "sector": ["Education","Tourism"],
        "service": "",
        "user_type": "End-User",
        "label1_title": "Key Partners",
        "label1_content": "Internet service providers (ISPs)\nTechnology and Data providers \nApplication providers (data analytics)\nFinancial providers (e.g. banks)\nGovernment and Regulatory bodies\nLocal/Regional Tourism Agency",
        "label2_title": "Key Activities",
        "label2_content": "Provision of various services and information to citizens, businesses, and other stakeholders\nAccess to learning material\nAccess to e-learning platforms\nDigital Marketing and E-commerce\nSeamless Information Access for Travellers\nEnhanced Customer Service\nSupport for Remote Work and Extended Stays\nAR/VR for tourism sector\n",
        "label3_title": "Value Propositions",
        "label3_content": "Accessible, customizable, and cost-effective education with global reach, offering synchronous and asynchronous learning, via digital platforms, enhanced communication, real-time Q&A, and 24/7 availability for continuous learning.\nExtend the service offerings of local SMEs and enhance visitor experience in the tourism sector by allowing seamless information access to travellers, online promotion of hotels and and tourist attractions, and the use of AR/VR for virtual tours.",
        "label4_title": "Customer Relationships",
        "label4_content": "Personal assistance\nCo-creation (tailor made)",
        "label5_title": "Customer Segments",
        "label5_content": "Local businesses\nAgrotourism SMEs\nPublic authorities\nResearch & Academia\nCitizens",
        "label6_title": "Channels",
        "label6_content": "Social media channels\nWebsite\nCommunity workshops\nCollaborations with local cooperatives",
        "label7_title": "Key Resources",
        "label7_content": "Human resources (e.g. technicians,engineers, IT professionals)\nPhysical resources (e.g. drones, devices, cameras)",
        "label9_title": "Cost Structure",
        "label9_content": "Fixed costs for infrastructure and maintenance\nOperational costs",
        "label10_title": "Revenue Streams",
        "label10_content": "Reduced cost of travel expenses (for rural citizen & educators)\nReduced time of travel (for rural citizen & educators)\nIncreased Productivity for businesses\ne-Learning platform revenues for businesses\nMonetise the use of smart infrastructure for testing/evaluating services & solutions.  (Pay per hour/montly/per project)\nSelling Touristic services (B2C)"
    },
    {
        "unique_id": 158,
        "sector": ["Education","Tourism"],
        "service": "",
        "user_type": "Internet Service Provider (ISP)",
        "label1_title": "Key Partners",
        "label1_content": "Other Mobile Network Operators (MNOs) & Internet service providers (ISPs)\nTechnology and Data providers \nNGOs\nAcademic Institutions\nSystem Integrators\nFinancial providers (e.g. banks)\nGovernment and Regulatory bodies\nApplication developers (data analytics)\nTechnical consultants/ advisors",
        "label2_title": "Key Activities",
        "label2_content": "Local Manufacturing & Sourcing\nAlliances & Partnerships formation for more efficient operations and lower inverstment costs\nSales & Promotion\nExplore public funding/grants opportunities",
        "label3_title": "Value Propositions",
        "label3_content": "Low cost, Low power & Low maintenance (3L)\nHigh QOS & Coverage\nEnvironment-friendly green-solution\nEnd-to-end IP platform\nCloud server for localized content\nHigh Data Rates",
        "label4_title": "Customer Relationships",
        "label4_content": "24/7 Customer Care Centre\nMicro Operators/VLEs\nRegional Sales Executives",
        "label5_title": "Customer Segments",
        "label5_content": "Local Community\nLocal Businesses\nPublic Authorities\nLocal Retail Market\nResearch & Academia\nEnvironmental engineers",
        "label6_title": "Channels",
        "label6_content": "Micro Operators/ VLEs\nCo-branded outlets\nPartnership Telcos/ISPs\nDirect Sales\nWebsite\nSocial media channels",
        "label7_title": "Key Resources",
        "label7_content": "Physical resources (technologies, machineries)\nFinancial resources (Investors and Venture Funding)\nPassionate Management",
        "label9_title": "Cost Structure",
        "label9_content": "Manufacturing & Sourcing\nWi-Fi integration, funding, deployment, operation & maintenance\nEmployees & office\nR&D & Training\nSales & Marketing",
        "label10_title": "Revenue Streams",
        "label10_content": "Subscription fee\nSpecialised B2B offerings for rural businesses and cooperatives\nContent/ Application services revenue share\nGovernment subsidies for rural connectivity\nMobile Data offload revenues\nNetwork slice rent (technology, software, mobile and broadband network providers)\nHaas. Rent the set of hardware including deployment of the network (The client would be billed monthly, based on the systems deployed).\nIaas. The client should use his own hardware or purchase a device from us. Charge the client based on the amount of data to be transmitted."
    },
    {
        "unique_id": 159,
        "sector": ["Education","Tourism"],
        "service": "",
        "user_type": "Public Authority",
        "label1_title": "Partnerships/Suppliers/Collaborators",
        "label1_content": "Municipality, Regional Government, National Government, European Union, Universities, Development Agencies, Private sector (e.g. MNOs, ISPs), Financial Institutions",
        "label2_title": "Other Stakeholders",
        "label2_content": "Society, NGOs, Chamber of Tradesmen",
        "label3_title": "Resources",
        "label3_content": "Financial Resources\nHuman Resources (administrative staff, legal staff, software developers advisors, technical support etc.)\nMaterials for construction\nEquipment for infrastructure\nIT (computers, software, etc.)\nLegislative Framework",
        "label4_title": "Capabilities",
        "label4_content": "Systemic, strategic and entrepreneurial vision\nMultidisciplinary knowledge\nAnalytical and forecast capacity\nAbility to think, develop studies and plans",
        "label5_title": "Proccesses",
        "label5_content": "Strategic Process Management\nElaboration of development plans\nLocal and regional potential mapping\nIdentification of economic potential\nManagement of environmental impacts\nElaboration of policies,plans and programs\nPublic Agreements Management\nPrivate Partnerships Management and Cooperation Agreements",
        "label6_title": "Outputs/Products",
        "label6_content": "Laws and regulatory frameworks for: Internet connectivity\nLaws and regulatory frameworks for: Drones operations (state, regional and sectoral levels)\nLaws and regulatory frameworks for: Universities, Institutes etc (state, regional and sectoral levels)",
        "label7_title": "Beneficiaries",
        "label7_content": "Society\nMunicipalities\nCompanies from all the industries (especially Education, Tourism)\nEducational institutions",
        "label9_title": "Outcomes",
        "label9_content": "Universal and democratic access to digital connectivity\nPossible increase of per capita income\nJob creation\nHigher Human Development Index (HDI)\nEconomic and societal strengthening of municipalities and strategic regions of the state\nEnhance citizens’ digital literacy\nReduction of social inequality",
        "label10_title": "Public Value",
        "label10_content": "Provide universal access to High-Speed Internet services by means of trustable and credible service, generating a fair and prosperous state that guarantees the well-being of the population as well as the financial sustainability of SMEs."
    },
]